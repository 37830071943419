.popup-promo {
  max-width: 800px;
}
.popup-image {
  max-width: 100%;
}
@media screen and (max-width: 425px) {
  .hide-on-mobile {
    display: none;
  }
  .popup-image {
    max-width: 90%;
  }
  .popup-promo {
    text-align: center;
    max-width: 95%;
  }
}

@media screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }
}
