.arrow img {
  max-width: 50px;
}

.arrow-left {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .promopopup {
    width: auto;
    height: auto;
    max-width: 650px;
    max-height: 450px;
  }
}

/* @media screen and (min-width: 1024px) and (max-width: 1024px) {
  .promopopup {
    width: auto;
    height: auto;
    max-width: 800px;
  }
} */
