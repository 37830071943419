/*color 466566*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 731px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 375px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px;
    margin-top: 20px;
  }
}
/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 414px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 230px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 411px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 230px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 823px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 812*/

@media only screen and (width: 812px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 768*/

@media only screen and (width: 768px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 220px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1024*/

@media only screen and (width: 1024px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1366*/

@media only screen and (width: 1366px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 885*/

@media only screen and (width: 885px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-right: 35px !important;
    right: 0;
    margin-top: 20px;
  }
}

/*--------not screen----------*/

.labelTypeVilla180913 {
  width: 100%;
  font-size: 14px;
  color: #3a6a6d;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
}

.labelCityName180913 {
  width: 100%;
  font-size: 14px;
  position: relative;
  color: black;
}

.iconLocationVilla180913 {
  height: 12px;
  width: 12px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.labelAddress180914 {
  float: left;
  margin-top: 3px;
}
.boxKonten180913 {
  padding-top: 7px !important;
}
.kolomharga {
  padding-top: 0px !important;
}
.labelNameVilla180914 {
  color: black !important;
  font-size: 16px !important;
}
.section2 {
  background-color: #fff !important;
}
div.box.boxheight {
  border: solid 0.5px #e9ecec;
  padding-bottom: 0px !important;
  height: auto !important;
  padding-bottom: 10px !important;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1) !important;
  border-radius: 21px !important;
}
.DateRangePicker_picker,
.guestHilangListAll,
.guestHilangList {
  z-index: 10 !important;
}
