.circle-akses {
  height: 23px;
  width: 23px;
  background-color: #bbb;
  border-radius: 50%;
	font-size: 16px;
  display: inline-block;
	text-align: center;
}
.imageAkses{
  margin-top: -10%;
}
.text-akses{
	font-size: 14px;
}
.text-aksesModal{
  font-size: 13px;
}
.mobile-akses{
  display: none;
}
.desktop-akses{
  display: block;
}
.box-akses{
	width: 100%;
	height: auto !important;
	margin-top: 10px;
	font-size: 14px;
}
@media screen and (max-width: 800px){
.desktop-akses{
  display: none;
}
.boxAkses{
  margin-top: -100px;
}
.mobile-akses{
  display: block;
}
}
