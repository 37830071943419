
/*color 466566*/
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 180px;
	    margin-top: 20px;
	}
*/
}


/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}
*/
}


/*-----------------------------------------------------------------------------------------*/


/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/


}


/*-----------------------------------------------------------------------------------------*/


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/


}


/*-----------------------------------------------------------------------------------------*/


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/


}


/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 823px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}


/*--------------------------------------*/

/* other screen 812*/

@media only screen and (width: 812px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}


/*--------------------------------------*/

/* other screen 1024*/

@media only screen and (width: 1024px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}


/*--------------------------------------*/

/* other screen 1366*/

@media only screen and (width: 1366px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}

/*--------------------------------------*/

/* other screen 885*/

@media only screen and (width: 885px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}





/*--------not screen----------*/

.labelTypeVilla180913{
	width: 100%;
	font-size: 14px;
	color: #3A6A6D;
	font-weight: bold;
	position: relative;
	margin-bottom: 10px;
}

.labelCityName180913{
	width: 100%;
	font-size: 14px;
	position: relative;
	color: black;
}

.iconLocationVilla180913{
	height: 15px;
    width: 15px;
}

.labelNameVillaHeader180913{
	font-size: 20px;
	font-weight: bold;
	color: black;
}
.labelAddressHeaderVilla180913{
	font-size: 12px;
	color: black;
}
.boxKonten180913{
	padding-top: 7px !important;
}
.kontenDescDetail180914{
	margin-top: 5px !important;
}