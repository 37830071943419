.listFacilities img{  
	width: 20px;
	height: 25px;
	margin-bottom: -5px;
	margin-left: 10px;
	margin-right: 10px;
}
.kilometermap{
	margin-left: 40px;
	color: #A9A7A7;
	padding-bottom: 20px;
}
.facilitiesmap label{
	font-weight: bold;
	margin-left: 30px;
}
.facilitiesmap{
	padding-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100% !important;
}
.listFacilities{
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	font-size: 14px;
	width: 100%;
}
.iconSelectFacilities input{
	position: absolute;
	display: none;
}
.iconSelectFacilities .selectFacilites{
	width: 20px;
	height: 20px;
	position: absolute;
	border:1px solid #ccc;
}
.iconSelectFacilities input:checked ~ .selectFacilites{
	background-image: url('/icon/ceklis.png');
	background-size: 15px 15px;
	background-position: center;
	background-repeat: no-repeat;
	border:1px solid #f16726;
}
.batasJarak{
	width: 100%;
	height: 5px;
	background-color: transparent;
}