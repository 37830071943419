
.showAll{
	clear: both;
}
/*color 466566*/
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 148px;
	    margin-top: 13px;
	}
	.kolomRespon{
		margin-top: 30px !important;
	}
}


/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 180px;
	    margin-top: 20px;
	}

	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 160px;
	    margin-top: 20px;
	}

}


/*-----------------------------------------------------------------------------------------*/


/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 220px;
	    margin-top: 20px;
	}


}


/*-----------------------------------------------------------------------------------------*/


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
	.pembatas{
	display: none;
	}

}


/*-----------------------------------------------------------------------------------------*/


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}
	.showAllTab{
		margin-top: -10px;
	}

}



.payAtHomeBackup{
	margin-top: 10px !important;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (width: 1024px){
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 100px;
	    margin-top: 20px;
	}

}

/* other screen 320*/

@media only screen and (width: 731px) {
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 210px;
	    margin-top: 20px;
	}
}

/* other screen 320*/

@media only screen and (width: 768px) {
	.payAtHomeBackup{
		margin-top: 20px !important;
	}
	.showAllTab{
		margin-top: -40px
	}
}

/*-------------------------------------------------*/

/* other screen 320*/

@media only screen and (width: 320px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 108px;
	    margin-top: 13px;
	}
}

/* other screen 411*/

@media only screen and (width: 411px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 200px;
	    margin-top: 13px;
	}
}

/* other screen 414*/

@media only screen and (width: 414px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 200px;
	    margin-top: 13px;
	}
}
@media only screen and (width: 375px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 160px;
	    margin-top: 13px;
	}
}
/* other screen tablet*/
@media only screen and (width: 768px) {
.kolomResponheadD{
	margin-top: 10px !important;
}
.kolomResponheadDTag{
	margin-top: -60px !important;
}

}




/*--------not screen----------*/
.showAll{
	margin-top: 0px
}
.labelTypeVilla180913{
	width: 100%;
	font-size: 14px;
	color: #3A6A6D;
	font-weight: bold;
	position: relative;
	margin-bottom: 10px;
}
.labelCityEvent{
	font-size: 14px;
	position: relative;
	color: black;
}


.labelCityName180913{
	width: 100%;
	font-size: 14px;
	position: relative;
	color: black;
}
.labelEventName180913{
	width: 100%;
	font-size: 12px;
	position: relative;
	color: black;
}
.labelEventKalneder180913{
	width: 100%;
	font-size: 14px;
	position: relative;
	color: black;
}
.labelAddressKalender180914{
	margin-top: 1px;
}

.iconLocationVilla180913{
	height: 12px;
    width: 12px;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
}
.iconKalender180913{
		height: 15px;
    width: 15px;
    float: left;
    margin-right: 5px;
    margin-top: 2px;
}
.labelAddress180914{
	float: left;
	margin-top: 3px;
}
.labelEventAddress180914{
	float: left;
	margin-top: 6px;
}
.boxKonten180913{
	padding-top: 7px !important;
}
.kolomharg{
	padding-top: 0px !important;
}
.labelNameVilla180914{
	color: black !important;
	font-size: 16px !important;
}
div.box.boxheight{
	border:solid 0.5px #E9ECEC;
	padding-bottom: 0px !important;
	height: auto !important;
    padding-bottom: 10px !important;
}
.pembatas{
	clear: both;
	height: 50px;
	width: 100%;
}
.kolomResponhead{
	margin-top: 70px !important;
	padding: 0px !important;
}
.kolomResponbody{
	margin-top: -60px !important;
}
.kolomResponheadD{
	margin-top: 60px ;
}
.kolomResponheadTag{
	margin-top: -60px;
}
.kolomResponbodyM{
	margin-top: 5px !important;
}
