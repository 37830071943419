.boxDate div.DateRangePicker{
	display: block !important;
}
.DateRangePickerInput_clearDates{
	top: 40% !important;
}
.DateRangePickerInput_clearDates:hover{
	background-color: transparent !important;
}
.hotelListBooking > div.DateRangePicker{
	display: block !important;
}
.control-Guest{
	height: 60px !important;
	margin-top: 10px !important;
	margin-left: 3.5% !important;
}
.control-Guest2{
	height: 60px !important;
	margin-left: 3.5% !important;
}
.control-Guest .select-option,.control-Guest2 .select-option{
	width: 95% !important;
	border-radius: 4px !important;
}
.boKPopUp{
	padding: 10px 10px !important;
}
.hotelListBooking div div.DateRangePickerInput{
	border-radius: 4px !important;
	width: 100.5% !important;
	margin-left: 3% !important;
}
.labelBokPopUp{
	margin-left: 4% !important;
  font-size: 14px !important;
}
.labelBok{
	padding-left: 7% !important;
}
.bokNameVilla{
	margin-left: 3.5% !important;
}
.labelGuestDetail {
	width: 93%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 4px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-left: 3.5%;
}
.labelGuestDetailA2{
  width: 355px;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 4px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-left: 3.5%;
}
.guestHilangDetailRes{
  width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;
}
.guestHilangDetailNotZero{
	width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;
}
.guestHilangDetailNotZeroRes{
	width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;	
}
.garisBooking{
  background-color: #ccc;
  height: 1px !important;
  padding: 0px !important;
  margin-left: 22px;
  margin-bottom: 20px;
  margin-top: 5px;
  width: 355px !important;
}
.labelBookHeader{
  margin-top: 20px;
  padding-right: 25px;
}
.labelBookHeaderB{
  padding-right: 0px !important;
}
.labelPrice{
  font-size: 12px !important;
}
.labelTotalBox{
  padding-left: 25px;
}
.ttlPrice{
  font-size: 14px !important;
  font-weight: bold;
  color: #383635 !important;
}
.labelJml{
  padding-right: 15px;
}
.boxDetail{
  margin-top: -20px;
}
.garisBookingTotal{
  background-color: #ccc;
  height: 1px !important;
  padding: 0px !important;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 35px;
  width: 86% !important;
  margin-left: 7%;
  margin-top: 20px !important;
}
.btnBooking{
  width: 93.5% !important;
  border-radius: 5px !important;
}
.labelBookingZero{
  margin-top: -5px;
  margin-bottom: -50px;
}
.labelZeroBook{
  padding-left: 25px !important;
  margin-top: 20px !important;
  width: 60% !important;
}
.labelPayAtZero{
  width: 40% !important;
}
.labeZeroBook{
  color: black !important;
  font-size: 14px !important;
}
.garisBookingTotalZero{
  background-color: #ccc;
  height: 1px !important;
  padding: 0px !important;
  margin-left: 23px;
  margin-bottom: 30px;
  margin-top: -15px;
  margin-bottom: 20px;
  width: 87% !important;
  margin-top: 5px !important;
}
.boxGuestDetail{
  width: 100%;
  padding: 9.5px 0px;
  color: black;
  margin-left: 5px;
  margin-top: 5px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  /*cursor: pointer;*/
  position: absolute;
  z-index: 9999 !important;
  position: absolute;
}
.price-responsive{
  font-size: 14px !important;
  color: black !important;
}
.payatvillaResZero{
  margin-left: 50px;
  margin-top: 0px;
}
.payAllRes{
  margin-top: -30px !important;
}
.labelPayAt{
  margin-top: 0px !important; 
  width: 35% !important;
  text-align: right !important;
}
.bookNameVilla{
  width: 60% !important;
}
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .paylist{
    margin-top: 0px !important;
  }
  .column-price{
    margin-top: 7px !important;
  }
  .labelGuestDetailA2{
    width: 93% !important;
  }
}

@media only screen and (max-width: 375px) {
  .labelGuestDetailA2{
    width: 94% !important;
  }
  .garisBooking {
    background-color: #ccc;
    height: 1px !important;
    padding: 0px !important;
    margin-left: 22px;
    margin-bottom: 20px;
    margin-top: 5px;
    width: 87% !important;
  }
  .hotelListBooking div div.DateRangePickerInput{
    border-radius: 4px !important;
    width: 102% !important;
    margin-left: 3% !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .paylist{
    margin-top: 0px !important;
  }
  .boxModal{
    max-height: 450px !important;
    margin-top: 150px !important;
    padding-top: 80px !important;
  }
  .hotelListBooking div div.DateRangePickerInput{
    width: 94% !important;
  }
  .labelBookHeaderB{
    padding-right: 0px !important;
    margin-top: 25px !important;
  }
  .hotelListBookingB div div.DateRangePickerInput{
    width: 100% !important;
  }
  .boxDetailA{
    margin-top: -70px !important;
  }
  .hotelListBookingA2 div div.DateRangePickerInput{
    width: 355px !important;
  }
  .labelPayAtA2{
    margin-right: 0px !important;
  }
  .labelGuestDetailA2{
    width: 93% !important;
  }
}

  /* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (width: 768px) {
  .paylist{
    margin-top: 25px !important;
  }
  .boxModal{
    max-height: 470px !important;
    margin-top: -150px !important;
    padding-top: 80px !important;
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 850px){
   .labelNameVillaA{
    width: 60% !important;
  }
  .labelPatAtVillaA{
    width: 40% !important;
  }
  .hotelListBookingA div div.DateRangePickerInput{
    width: 100% !important;
  }
  .payAtVillaNoResA{
    margin-right: -15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
  .paylist{
    margin-top: 25px !important;
  }
  .payAtVillaNoResA{
    margin-top: 5px !important;
    margin-right: -15px !important;
  }
  .labelNameVillaA{
    width: 60% !important;
  }
  .labelPatAtVillaA{
    width: 35% !important;
  }
  .labelPatAtVillB{
    margin-top: 0px !important;
    float: right;
  }
  .hotelListBookingA div div.DateRangePickerInput{
    width: 100% !important;
  }
  .hotelListBookingB div div.DateRangePickerInput{
    width: 100% !important;
  }
  #BoxDetailBooking{
    width: 361px !important;
  }
}


/* new style */
.boxHeaderModal{
  width: 100% !important;
  margin-top: 5px !important;
}
.boxHeaderModalA{
  width: 100% !important;
  margin-top: 5px !important;
}
.bokModalBooking{
  overflow: visible;
  padding-left: 30px;
  padding-right: 5px;
  padding-top: 20px !important;
  padding-bottom: 30px !important;
  height: auto;
  max-height: 600px;
}
.labelNamaVilla{
  width: 60% !important;
  float: left;
  height: auto;
  padding: 0px !important;
}
.labelPayAtModal{
  width: 40% !important;
  overflow: hidden;
  height: auto;
  padding: 0px !important;
  float: left;
  left: 0;
  padding-top: 5px !important;
}
.batasGarisModalAtas{
  width: 100% !important;
  background-color: #ccc;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 1px !important;
}
.batasGarisModalAtasA1{
    width: 93% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 10px;
}
.payAtVillaModalA2{
  margin-top: 0px !important;
}
.labelNameModalA2{
  padding-left: 0px !important;
  padding-right: 30px !important;
}
.labelNameModalA1{
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.labelPayAtModalA2{
  padding-right: 0px !important;
}
.labelPayAtModalA1{
  padding-right: 0px !important;
}
.boxModalDateA2{
  padding-left: 0px !important;
  width: 100% !important;
  margin-top: 20px !important;
}
.labels{
  font-size: 14px;
  font-weight: bold;
}
.boxModalDateA2 div.column,.boxModalGuestA2 div.column {
  padding: 0px !important;
}
.boxModalDateA2 div.column div.field label,.boxModalGuestA2 div.column div.field label {
  font-size: 14px;
  font-weight: bold;
}
.hotelListBooking div div.DateRangePickerInput{
  width: 100% !important;
  margin-left: 0px !important;
}
div.DateRangePicker, .DateRangePickerInput{
  width: 100% !important;
}
.boxModalGuestA2{
  padding-left: 0px !important;
  width: 100% !important;
  margin-top: 10px !important;
}
.labelGuestDetailA2{
  width: 100% !important;
  margin: 0px !important;
}
.labelHargaModalA2{
  margin-top: 20px !important;
}
.labelPriceMalam{
  font-size: 14px;
  color: black;
  width: 100%;
  margin-left: -5px;
}
.labeltotalPrice{
  font-size: 14px;
  font-weight: bold;
  color: black;
  width: 100%;
  margin-left: -5px;
}
.btnBooking{
  width: 100% !important;
  margin-left: -25px !important;
  margin-top: 20px !important;
}
.boxDateA1 {
    padding-left: 10px !important;
    width: 100% !important;
    margin-top: 20px !important;
}
.hotelListBookingA1 div div.DateRangePickerInput{
   width: 105% !important;
   display: block !important;
  }
.boxDateA1 div div.DateRangePickerInput{
   width: 107.5% !important;
   display: block !important;
}
.boxDateA1 div.column{
  padding-right: 0px;
  padding-left: 10px;
}
.boxGuestA1{
  padding-left: 10px;
  padding-right: 10px;
}
.labelHargaA1{
  width: 95%;
  margin-left: 10px;
  text-align: center;
  padding-left: 10px;
}
.batasGarisA1{
    width: 93% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 10px;
}
.btnReservation{
  padding-left: 20px !important;
}
.btnReservation div{
  padding-right: 9px !important;
}
input#endDate1{
  width: 70px;
}
input#endDate2{
  width: 70px;
}
.priceNotZero{
  margin-top: 0px !important;
}



  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bokModalBooking{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .hotelListBooking div div.DateRangePickerInput{
    width: 108% !important;
  }
}
@media only screen and (width: 360px) {
  .bokModalBooking{
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 568px) {
  .bokModalBooking{
    margin-top: 200px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 640px) {
  .bokModalBooking{
    margin-top: 200px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 411px) {
  .bokModalBooking{
    margin-top: -50px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 768px) {
  .hotelListBooking div div.DateRangePickerInput{
    width: 108% !important;
  }
  .bokModalBookingA{
    margin-top: 50px !important;
  }
}
@media only screen and (width: 823px) {
  .boxHeaderModalA{
    width: 110% !important;
  }
  .labelPayAtModalA1{
    padding-right: 5px !important;
  }
  .labelNameModalA1 {
    padding-left: 10px !important;
    padding-right: 30px !important;
  }
  .batasGarisModalAtasA1{
    width: 100% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 0px;
  }
  .boxDateA1 div div.DateRangePickerInput{
    width: 114% !important;
    display: block !important;
  }
  .boxDateA1 div.column{
    padding-right: 0px;
    padding-left: 0px;
  }
  .boxGuestA1{
    padding-left: 0px;
    padding-right: 0px;
  }
  .batasGarisA1{
    width: 93% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 5px;
  }
  .btnReservation{
    padding-left: 15px !important;
  }
  .btnReservation div{
    padding-right: 0px !important;
  }
}
.bookGuestA1{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: 1;
}
.blockTanggal{
 text-decoration: line-through !important; 
 color: #ccc;
}
.labelKonfr{
  font-size: 12px;
  margin-left: 10px;
}

@media only screen and (width: 812px) {
  .boxHeaderModalA{
    width: 110% !important;
  }
  .labelPayAtModalA1{
    padding-right: 5px !important;
  }
  .labelNameModalA1 {
    padding-left: 10px !important;
    padding-right: 30px !important;
  }
}
@media only screen and (width: 375px) {
  .bokModalBooking{
    margin-top: 0px !important;
    margin-bottom: 50px !important;
  }
}

@media only screen and (width: 1024px) {
  .boxDateA1 div div.DateRangePickerInput{
    width: 108% !important;
  }
  .boxGuestDetail{
    width: 115% !important;
    margin-left: -15px !important;
  }
   #BoxDetailBooking{
    width: 310px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bokModalBooking{
    margin-top: 200px !important;
    margin-bottom: 50px !important;
  }
}

@media only screen and (width: 768px) {
  .bokModalBooking{
    margin-top: 50px !important;
  }
  .column-price{
    margin-top: 20px !important;
  }
  .priceNotZero{
    margin-top: 10px !important; 
  }
}