.text-danger {
  color: red !important;
}
.childIMages {
  padding-right: 0px !important;
  overflow: hidden;
  width: 75% !important;
  float: left;
}
.childIMagesMore {
  padding-left: 0px !important;
  width: 25% !important;
  float: left;
}
.imagesMore {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100% !important;
  height: 110px !important;
  z-index: 1;
}
.imagesChile {
  width: 100% !important;
  height: 110px !important;
  border-right: solid 2px white !important;
  cursor: pointer;
}
.seeMore {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 110px !important;
  position: relative;
  text-align: center;
  color: #fff;
  padding-top: 40px;
  cursor: pointer;
  z-index: 2;
  margin-top: -115px;
}
.columnImgChild {
  margin-top: -20px !important;
}
.desktopImage {
  margin-left: 0% !important;
}
.allSlider {
  width: 760px !important;
  height: 427px !important;
}
/*slider header*/
.imgSliderHeader {
  width: 760px !important;
  height: 427px !important;
}
.fontImage {
  z-index: 9999;
  color: white;
}
.fontImagePer {
  z-index: 9999;
  color: white;
}
/*slider header*/

/*slider all*/
.sliderAll,
.imgSliderAll {
  width: 100% !important;
  height: 400px !important;
}
@media screen and (width: 768px) {
  .sliderAll {
    height: 427px !important;
  }
  .fontImage {
    z-index: 9999;
    margin-top: 10px;
    color: white;
  }
  .fontImagePer {
    z-index: 9999;
    margin-top: 10px;
    color: white;
  }
  .desktopImage {
    margin-top: -35%;
    width: 760px !important;
    /* margin-left: -210px !important; */
  }
}
@media screen and (height: 768px) {
  .imgSliderAll,
  .sliderAll {
    width: 700px !important;
    height: 394px !important;
  }
  .fontImagePer {
    z-index: 9999;
    margin-right: -91px;
    color: white;
  }
}
@media screen and (width: 320px) {
  .sliderAll {
    margin-top: 28% !important;
  }
}
/*slider all*/
.slick-next {
  margin-right: 5px !important;
}
@media screen and (max-width: 640px) {
  /*slider header*/
  .imgSliderHeader {
    width: 760px !important;
    height: 210px !important;
  }
  /*slider header*/

  /*slider all*/
  .sliderAll,
  .imgSliderAll {
    width: 317px !important;
    height: 200px !important;
  }
  @media screen and (max-width: 320px) {
    /*slider all*/
    .sliderAll,
    .imgSliderAll {
      width: 300px !important;
      height: 210px !important;
    }
  }
  /*slider all*/

  .childIMages {
    padding-right: 0px !important;
    overflow: hidden;
    width: 75% !important;
    float: left;
  }
  .childIMagesMore {
    padding-left: 0px !important;
    width: 25% !important;
    float: left;
  }
  .imagesMore {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100% !important;
    height: 50px !important;
    /*float: right !important;*/
  }
  .imagesChile {
    width: 100% !important;
    height: 50px !important;
  }
  .seeMore {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    width: 100%;
    height: 50px !important;
    position: relative;
    text-align: center;
    color: #fff;
    padding-top: 20px;
    cursor: pointer;
    z-index: 2;
    margin-top: -57px;
    font-size: 8px;
  }
  .desktopImage {
    /* margin-left: 5px !important; */
  }
  .mapJarak {
    margin-bottom: 0px !important;
  }
  .text-image {
    height: 25px !important;
    padding-top: 8px !important;
    width: 30% !important;
  }
  .text-image > p {
    font-size: 9px !important;
  }
}

/*----------- other -------*/
.zoom,
img.imageKecil {
  /*height: 60px !important;*/
}
/*.batas-image{
	width: 100%;
	height: 5px;
	clear: both;
}*/
