.tutorial {
  position: fixed;
  left: 10px;
  top: 80px;
  background-color: white;
  padding: 20px;
  padding-left: 50px;
  z-index: 999;
  width: 500px;
  border-radius: 10px;
  box-shadow: 5px 5px 100px black;
  opacity: 1;
}

@media screen and (max-width: 425px) {
  .tutorial {
    width: 100%;
    left: 0;
    top: 60px;
    overflow-y: auto;
  }
}
