.footer {
  /*border-top: 2px solid #eaeaea;*/
  /* background: #fff; */
  background-color: #f9f9f9;
  padding-bottom: 5%;
  padding-top: 3%;

  /*padding-left: 100px;*/
  /*padding-right: 100px;*/
}
.containerFooter {
  z-index: 1 !important;
  padding-left: 4%;
}
.tutupIn {
  background: blue;
  z-index: 99999;
}
.footer a {
  color: #888888;
  font-weight: 700;
  width: 35px;
}

.footer-about {
  /*margin-left: 70px;*/
}

.titlefooter {
  margin-top: 20px;
}
.mobilefooter b {
  font-size: 18px;
}
.mobilefooter ul li a {
  font-size: 16px;
}

.wa-cs-container {
  position: fixed;
  z-index: 2;
  right: 10px;
  bottom: 10px;
  display: flex;
}

.wa-cs {
  display: flex;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  background-color: #1d8b15;
  -webkit-box-shadow: 2px 2px 18px -5px #000000;
  box-shadow: 2px 2px 18px -5px #000000;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.wa-cs img {
  width: 20px;
  margin-right: 10px;
}
.wa-cs i {
  font-size: 20px;
}
@media screen and (max-width: 1100px) {
  .footer {
    background: #fff;
    padding-bottom: 5%;
    padding-left: 0px;
    padding-right: 0px;
    z-index: -1 !important;
  }

  .titlefooter {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding-left: 20px;
    width: 96%;
  }
}

@media screen and (max-width: 768px) {
  .footer-about {
    margin-top: 30px;
    margin-left: 0px;
  }

  .footer {
    padding-bottom: 55px;
    width: 96%;
  }

  .titlefooter {
    margin-top: 0px;
  }

  .titlefollow {
    margin-top: -50px;
  }

  .mobilefooter {
    padding-bottom: 6%;
  }
}

@media screen and (max-width: 640px) {
  .mobilefooter b {
    font-size: 16px;
  }
  .mobilefooter ul li a {
    font-size: 14px;
  }
  .footer {
    /*margin-left: 5px;*/
  }
}

.titlefollow a:hover {
  background-color: #f36603;
}
