@import url(https://fonts.googleapis.com/css2?family=Nunito&family=Roboto&display=swap);
.tourist-attraction-img {
  border-radius: 30px;
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.border-radius-30px {
  border-radius: 30px;
}

.sectionHehe {
  padding: 0;
}
.h1Title {
  font-size: 36px;
  color: white;
  font-weight: bold;
}

.h2Title {
  font-size: 14px;
  color: white;
  padding-top: 15px;
}

.explore-btn {
  margin-top: 25px;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: flex-end;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: white;
}

/* selected link */
a:active {
  color: white;
}

.facilitybox {
  padding: 20px;
  height: auto;
  background-color: white;
  border-radius: 10px;
}

.facilities {
  margin-top: 0px;
}

.promobanner {
  z-index: -1;
}

.forbanner {
  height: 30px;
  padding-top: 3px;
}

.forbanner2 {
  height: 31px;
}
.payvilla {
  font-size: 12px !important;
  border-color: #f16726;
  background-color: #f16726;
  border-radius: 12px;
  width: 110px;
  height: 25px;
  font-weight: bold;
  padding-top: 4px;
}

.cottagepic {
  margin-top: -16px;
  height: 12px;
  width: 12px;
}

.phonenumber {
  margin-top: 7px !important;
}

.changepass {
  font-size: 12px;
}

.editprof {
  font-size: 12px;
}

.circlesmobile {
  margin-left: -11%;
}

.kolomkotanama {
  cursor: pointer;
}

.images-galery {
  cursor: pointer;
}

.kolomharga {
  cursor: pointer;
}

.section2 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  padding-right: 0rem !important;
  padding-left: 0.6rem !important;
}
.section2Home {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-right: 0rem !important;
  padding-left: 0.6rem !important;
}

.icon {
  width: 28px;
  width: 28px;
}

.downloadbutton {
  border-radius: 5px;
  width: 130px;
  height: 34px;
  margin-top: 4.5px;
  font-size: 12px;
}

.nobookfound {
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: bold;
}

.bookdetailmobile {
  display: none;
}

.item:hover {
  background-color: rgba(247, 194, 94, 0.6);
}

.bookhistory:hover {
  background-color: white;
}

.firstparagraph {
  margin-left: 30px;
  margin-top: 2.7%;
  margin-bottom: 30px;
  text-align: justify;
}

.firstpict {
  margin-right: 30px;
}

.secondparagraph {
  margin-left: 30px;
  margin-bottom: 10px;
  text-align: justify;
}

.title-about {
  font-size: 36px;
  font-weight: 1000;
  margin-top: 15px;
  margin-bottom: 30px;
}
.boxSimilar .slick-track {
  margin-left: 0px !important;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  margin-left: 30px;
}

.linemobile2 {
  display: none;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.circles {
  width: 100px;
  margin-top: -12px;
}

.villaname {
  font-size: 14px;
}

.loccheck {
  margin-top: 1%;
}

@media screen and (max-width: 1408px) {
  .firstparagraph {
    margin-left: 30px;
    margin-top: 0.6%;
    margin-bottom: 30px;
    text-align: justify;
  }
}

@media screen and (max-width: 1215px) {
  .firstparagraph {
    margin-left: 20px;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: justify;
  }
  .firstpict {
    margin-right: 20px;
  }

  .secondparagraph {
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: justify;
  }
  .Aligner-item {
    margin-left: 20px;
  }
}

@media screen and (max-width: 921px) {
  .title-about {
    font-size: 32px;
  }
}

@media screen and (max-width: 900px) {
  .displayempty {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .title-about {
    font-size: 28px;
  }
}

@media screen and (max-width: 600px) {
  .facilitybox {
    padding: 0px;
    padding-top: 120px;
    padding-left: 15px;
    height: auto;
  }
  .boxSimilar .box {
    padding: 0px;
    margin-left: 1px;
  }
  .boxSlide {
    padding: 0px !important;
    z-index: 9999 !important;
  }
  .gambarSimilar {
    height: 160px !important;
  }
  .facilities {
    margin-top: -120px !important;
    padding-top: 0px;
  }
}

@media screen and (max-width: 330px) {
  .facilitybox {
    padding-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .mobiles {
    display: none;
  }
  .desktopsTeks {
    margin-top: -80px !important;
  }
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px !important;
    margin-top: 20px;
  }
  .desktops {
    width: 105% !important;
    margin-left: -2px !important;
  }

  .kolomeventnama {
    margin-left: 0px;
    margin-right: 0px;
    background: rgba(0, 0, 0, 0.8);
    margin-top: -74px;
    height: 45px;
    z-index: 9 !important;
    position: relative;
  }

  .gambarSimilar {
    height: 144px;
    width: 500px;
    cursor: pointer;
  }
  .aboutpage {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
  }

  .cottagepic {
    margin-top: -17px;
    height: 8px;
    width: 8px;
  }

  .firstpict {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .firstparagraph {
    margin-left: 0px;
    padding-top: 0px;
    margin-bottom: 15px;
  }

  .secondparagraph {
    margin-left: 0px;
  }

  .title-about {
    margin-top: 20px;
    font-size: 25px;
  }

  .Aligner-item {
    margin-left: 0px;
  }

  .passconfr {
    padding-top: 7px;
  }
  .forbanner {
    height: 25px;
    padding-top: 0px;
  }
  .forbanner2 {
    height: 26px;
  }
}

@media screen and (max-width: 518px) {
  .title-about {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .displaymobile {
    display: none;
  }
  .linemobile2 {
    display: block;
  }
  .mobiles {
    display: block;
  }
  .kolomeventnama {
    margin-left: 0px;
    margin-right: 0px;
    background: rgba(0, 0, 0, 0.8);
    margin-top: -58px !important;
    z-index: 9 !important;
    position: relative;
  }
  .boxSimilar .box {
    width: 100%;
  }

  .desktops {
    display: none;
  }
  .desktopsTeks {
    display: none;
  }
  .gambarSimilar {
    height: 144px;
    width: 500px;
    cursor: pointer;
  }
}

.paginationmargin {
  margin-top: 100px;
  margin-bottom: -25px;
}

.boxheight {
  height: 100%;
}

.inputatas {
  margin-top: -80px;
  margin-bottom: -50px;
}

.tengahexplore {
  width: 160%;
  height: 40px;
}
.tengahexplorefilter {
  width: 60%;
  height: 40px;
}

.tengahexplore:hover {
  color: #fff;
}

.tengahexplorefilter:hover {
  color: #fff;
}

.whitedate {
  background-color: white !important;
}

.isrounded {
  border-radius: 8px;
}

.kolommobile {
  margin-left: -15px;
}

.ratatengah {
  text-align: center;
  text-align-last: center;
}

.tengahtanggal {
  width: 100%;
  height: 40px;
}

.tengah {
  height: 50px;
  line-height: 50px;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1 !important; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1 !important; /* Firefox */
}

.kolomkotanama {
  margin-left: 5px;
  margin-right: 5px;
}
.kolomkalender {
  margin-top: -25px;
  margin-left: 5px;
  margin-right: 5px;
}
.kolomeventnama {
  margin-left: 0px;
  margin-right: 0px;
  background: rgba(0, 0, 0, 0.8);
  margin-top: -58px;
  z-index: 9 !important;
  height: 36px;
  position: relative;
}
.namaevent {
  z-index: 10 !important;
  color: #f1f2f3;
  float: left;
  margin-top: -5px;
  font-size: 14px;
}

.kolomkota {
  margin-bottom: 10px;
  margin-top: -10px;
}

.kolomharga {
  margin-top: -10px;
  margin-bottom: 20px;
}

.pagee {
  margin-bottom: -70px;
  margin-top: -45px;
}

.centerpagination {
  justify-content: center !important;
}

.myaccountbox {
  box-shadow: 0.5px -0.5px 5px 0px #cfcfcf;
  margin-right: 50px;
  height: 180px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  overflow: hidden;
  margin-left: 7px;
}

.accountsettingbox {
  box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-left: -25px;
  width: 64% !important;
  /*overflow: hidden;*/
}

.activebook {
  box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-left: -25px;
  width: 64% !important;
  overflow: hidden;
}

.emptybooking {
  margin-right: 50px;
  height: 180px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-top: 35px;
}

.bookhistory {
  box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-left: -25px;
  margin-top: 35px;
  width: 64% !important;
  overflow: hidden;
  background-color: #eeee;
}

.activated {
  background-color: rgba(247, 194, 94, 0.6);
  font-weight: bold;
}

.boxes {
  padding-left: 30px !important;
  width: 150% !important;
}

.primarybutton {
  border-radius: 10px;
  width: 200px;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 12px;
}

.buttons {
  display: -webkit-flex; /* Safari */
  -webkit-justify-content: flex-end; /* Safari 6.1+ */
  display: flex;
  justify-content: flex-end;
}

.mobileprof {
  padding-bottom: 425px;
}

.mobileprof2 {
  padding-bottom: 180px;
}

.displayline {
  display: none;
}

.linemobile {
  display: block;
  margin-top: 23px;
}

.box-mobile {
  display: block;
}

.submitbutton {
  border-radius: 10px;
  width: 190px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border: none !important;
}

.savebutton {
  border-radius: 10px;
  width: 190px;
  padding-top: 10px;
  padding-bottom: 28px;
  font-size: 12px;
  border: none !important;
}

.editprof {
  margin-left: 15px;
}

.inputbox {
  width: 96% !important;
}

.mobilecolumn {
  padding-right: 0px;
}

.telpbox {
  padding-right: 10px;
}

.conpass {
  padding-right: 10px;
}

.mobileedit {
  padding-bottom: 466px;
}

.unactive:hover {
  background-color: rgba(251, 127, 80, 0.1);
}

.mobilefont {
  font-size: 14px;
  text-align: justify !important;
  text-justify: inter-word !important;
}

@media screen and (max-width: 990px) {
  .phonenumber {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 769px) {
  .long {
    width: 100%;
  }
  .mobiles {
    display: none;
  }
}

@media screen and (max-width: 1220px) {
  .accountsettingbox {
    box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    margin-left: -25px;
    width: 62.5% !important;
  }

  .activebook {
    box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    margin-left: -25px;
    width: 62.5% !important;
  }

  .bookhistory {
    box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    margin-left: -25px;
    width: 62.5% !important;
  }
}

@media screen and (max-width: 1205px) {
  .mobile {
    float: none;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 98%;
    height: 40px;
  }

  .tengahexplore {
    width: 160%;
    height: 40px;
  }
  .tengahexplorefilter {
    width: 60%;
    height: 40px;
  }

  .boxheight {
    height: 100%;
  }

  .inputatas {
    margin-top: -90px;
    padding-top: -30px;
    margin-bottom: -50px;
  }

  .kolommobile {
    margin-left: -20px;
  }

  .forguest {
    width: 15%;
  }

  .ratatengah {
    text-align: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 985px) {
  .mobile {
    float: none;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 120%;
    height: 40px;
  }

  .tengahexplore {
    width: 160%;
    height: 40px;
    margin-top: -15px;
    margin-bottom: 6px;
  }
  .tengahexplorefilter {
    width: 100%;
    height: 40px;
  }

  .boxheight {
    height: 100%;
  }

  .inputatas {
    margin-top: -80px;
    margin-bottom: -50px;
  }

  .kolommobile {
    margin-left: -15px;
  }

  .forguest {
    width: 21%;
  }

  .ratatengah {
    text-align: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 850px) {
  .profpage {
    padding-left: 1%;
    padding-right: 5px;
  }
  .bg-done {
    font-size: 16px;
    margin-top: -20px;
    font-weight: bold;
    cursor: pointer;
    color: #f36603;
    margin-left: 45%;
  }
  .guestHilangListAll {
    width: 200% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -100% !important;
    margin-top: -15px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 10;
  }
  .guestHilangList {
    width: 200% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -100% !important;
    margin-top: 5px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: absolute;
    z-index: 9999 !important;
  }
  .guestHilang {
    width: 90% !important;
    padding: 9.5px 0px !important;
    color: black !important;
    margin-left: -150px !important;
    height: 150px !important;
    margin-top: 10px !important;
    background-color: white !important;
    font-size: 14px !important;
    border: 1px solid #ccc !important;
    position: absolute !important;
  }

  .bookpage {
    padding-left: 1%;
    padding-right: 5px;
  }

  .primarybutton {
    border-radius: 10px;
    width: 150px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .circlesmobile {
    margin-left: -9% !important;
  }
  .explore-btn {
    margin-top: 0px;
  }
  .h1Title {
    font-size: 24px;
  }

  .h2Title {
    font-size: 12px;
    padding-top: 5px;
  }
  .sectionHehe {
    padding: 10px;
  }

  h1 {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 400px) {
  .circlesmobile {
    margin-left: -11% !important;
  }
}

@media screen and (max-width: 769px) {
  .mobile {
    float: none;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .section2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .section2Home {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }

  .circlesmobile {
    margin-left: -4.5%;
  }

  .downloadbutton {
    border-radius: 5px;
    width: 120px;
    height: 35px;
    margin-top: 0px;
    font-size: 12px;
  }

  .bookdetailmobile {
    display: block;
  }

  .mobileprof2 {
    padding-bottom: 50px;
  }

  .nobookfound {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .box-mobile {
    display: none;
  }

  .mobilefont {
    font-size: 12px;
    text-align: justify !important;
    text-justify: inter-word !important;
  }

  .loccheck {
    margin-top: 0px;
  }

  .item {
    margin-top: -25px;
  }

  .circle {
    height: 23px;
    width: 23px;
    border-radius: 50%;
  }

  .circles {
    width: 95px;
    margin-top: -5px;
  }

  .villaname {
    font-size: 13px;
  }

  .emptybooking {
    display: none;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 120%;
  }

  .tengahexplore {
    width: 100%;
  }
  .tengahexplorefilter {
    width: 100%;
  }
  .boxheight {
    height: 100%;
  }

  .inputatas {
    margin-top: -80px;
    margin-bottom: -50px;
  }

  .kolommobile {
    margin-left: 0px;
  }

  .forguest {
    width: auto;
  }

  .ratatengah {
    text-align-last: center;
  }

  .tengahtanggal {
    width: 100%;
  }

  .myaccountbox {
    display: none;
  }

  .mobileprof {
    padding-bottom: 50px;
  }

  .displayline {
    display: block;
    margin-top: 25px;
    margin-bottom: -25px;
  }

  .linemobile {
    display: none;
  }

  .profpage {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bookpage {
    padding-left: 0px;
    padding-right: 0px;
  }

  .accountsettingbox {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-top: -20px;
    margin-left: 0px;
    width: 100% !important;
  }

  .bookdetailbox {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-top: -37px;
    margin-left: 0px;
    width: 100% !important;
  }

  .activebook {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 0px;
    margin-top: -37px;
    margin-left: 0px;
    width: 100% !important;
  }

  .bookhistory {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 0px;
    margin-top: 27px;
    margin-left: 0px;
    width: 100% !important;
    background-color: white;
  }

  .primarybutton {
    border-radius: 10px;
    width: 48%;
    padding-top: 19px;
    padding-bottom: 19px;
    font-size: 12px;
  }

  .mobilecolumn {
    width: 100% !important;
    padding-right: 10px;
  }

  .mobileedit {
    padding-bottom: 50px;
  }

  .newpass {
    width: 100% !important;
    padding-right: 10px;
  }

  .buttons {
    display: -webkit-flex; /* Safari */
    -webkit-justify-content: center; /* Safari 6.1+ */
    display: flex;
    justify-content: center;
    margin-left: -14px;
    margin-right: -14px;
  }

  .submitbutton {
    border-radius: 10px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }

  .savebutton {
    border-radius: 10px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 28px;
    font-size: 12px;
  }

  .inputbox {
    width: 100% !important;
  }

  .telpbox {
    padding-left: 0px;
  }

  .editprof {
    margin-left: 5px;
    width: 48%;
  }

  .changepass {
    width: 48%;
    margin-right: 5px;
  }
}

@media screen and (max-width: 339px) {
  .downloadbutton {
    border-radius: 5px;
    width: 115px !important;
    height: 35px;
    font-size: 11px !important;
    margin-top: 0px !important;
  }
  .guestHilang {
    width: 90%;
    padding: 9.5px 0px;
    color: black;
    margin-left: -135px !important;
    height: 150px;
    margin-top: 10px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
  }
  .guestHilangListAll {
    width: 220% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -150px !important;
    margin-top: -15px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999 !important;
  }
  .guestHilangList {
    width: 220% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -110% !important;
    margin-top: 5px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999 !important;
  }

  .editprof {
    margin-left: 5px;
    width: 47% !important;
    font-size: 11px;
  }

  .changepass {
    width: 47% !important;
    margin-right: 5px;
    font-size: 11px;
  }
}

@media screen and (max-width: 500px) {
  .mobile {
    float: none;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 110%;
  }

  .tengahexplore {
    width: 100%;
    margin-top: -15px;
    margin-bottom: 6px;
  }
  .tengahexplorefilter {
    width: 100%;
  }

  .boxheight {
    height: auto;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
    padding-right: 0px;
  }

  .inner {
    margin-right: 0px;
    margin-left: 0px;
  }

  .inputatas {
    margin-top: -90px;
    height: 260px;
    margin-bottom: -50px;
  }

  .forguest {
    margin-top: -10px;
    margin-bottom: 5px;
    width: auto;
  }

  .kolommobile {
    margin-left: 0px;
  }

  .ratatengah {
    text-align-last: center;
  }

  .tengahtanggal {
    width: 100%;
  }

  .kolomkota {
    margin-bottom: 11px;
    margin-top: -10px;
  }

  .kolomharga {
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .pagee {
    margin-top: -60px;
    margin-bottom: -100px;
  }
}

.btn-searchbox {
  height: 40px;
  color: #fff;
  border-color: #f16726;
  background-color: #f16726;
}

/* Popup container - can be anything you want */
.popup {
  position: relative;
  margin-left: 285px;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 10px grey;
}
.popup .popuptext-modal {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 10px grey;
}

/* Popup arrow */
.popup .popuptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
}

.popup .show2 {
  visibility: visible;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bg-guest-min {
  background: white;
  color: #f36603;
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 25px 1px 1px 25px;
  border: 1px solid #cfcfcf;
}
.bg-guest-min-hilang {
  background: white;
  color: #fbd1a7;
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 25px 1px 1px 25px;
  border: 1px solid #cfcfcf;
}
.bg-guest-min-hilang:hover {
  color: #fbd1a7;
}
.bg-done {
  font-size: 16px;
  margin-top: -20px;
  font-weight: bold;
  cursor: pointer;
  color: #f36603;
}
.bg-guest-min:hover {
  color: #f36603;
}
.bg-guest-plus:hover {
  color: #f36603;
}
.bg-guest-plus {
  background: white;
  color: #f36603;
  height: 35px;
  font-weight: bold;
  width: 50%;
  font-size: 20px;
  border-radius: 1px 25px 25px 1px;
  border: 1px solid #cfcfcf;
}
.bg-guest-plus-hilang {
  background: white;
  color: #fbd1a7;
  height: 35px;
  font-weight: bold;
  width: 50%;
  font-size: 20px;
  border-radius: 1px 25px 25px 1px;
  border: 1px solid #cfcfcf;
}
.bg-guest-plus-hilang:hover {
  color: #fbd1a7;
}
.txtGuest {
  height: 35px;
}
.border-guest {
  border: 1px solid #cfcfcf;
  border-radius: 25px;
}
.bg-primary {
  background: #f36603 !important;
  color: white;
}

.bg-primary:hover {
  background: #f36603;
  color: white;
}
.bg-green {
  background-color: #f0fff0;
}

/*--------------- React slick ------------*/

.slide-item {
  width: 100%;
}
.slide-item img {
  width: 100%;
  height: 100%;
}
.slide-item-view img {
  width: 100%;
  height: 100%;
}

.slick-next {
  right: 10px;
}
.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  color: #ffff;
}
.slick-prev {
  z-index: 999;
  left: 10px;
}
.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: white;
  font-size: 10px;
}
.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}
.boxSlide .slick-next:before,
.boxSlide .slick-prev:before {
  font-size: 25px;
  color: black;
}
.boxSlide .slick-disabled:before {
  color: transparent !important;
  cursor: default;
}
.boxSlide .slick-prev {
  left: -30px;
}
.boxSlide .slick-next {
  right: -30px;
}
.gambarSimilar {
  height: 180px;
  cursor: pointer;
}
.boxShadow {
  border: solid 1px #ccc !important;
  background-color: white;
  margin: 30px;

  box-shadow: 7px 7px 10px 15px;
  margin-left: -5px;
}
.boxSimilar .gambarAja {
  width: 100%;
}

.desktops {
  margin-left: -20px;
  width: 101.5% !important;
}
.desktopsTeks {
  margin-top: -27px;
}
.boxSimilar .box {
  padding: 1px;
  width: 105% !important;
}
.boxSlide .gambarAja .slick-next:before,
.boxSlide .gambarAja .slick-prev:before {
  font-size: 25px;
  color: #ffff;
}
.boxSlide .slick-prev {
  z-index: 1;
}
.boxSlide .gambarAja .slick-prev {
  z-index: 999;
  left: 10px;
}
.boxSlide .gambarAja .slick-dots {
  bottom: 10px;
}

.boxSlide .gambarAja .slick-next {
  right: 10px;
}

/*--------- end of react slick ------------*/

.icon-facility {
  padding: 3px 10px !important;
}

/*--------------- Modal ------------*/
.modal {
  overflow: auto;
  background-color: rgba(10, 10, 10, 0.86);
  z-index: 9999;
}

.modal-content {
  overflow: visible;
}

.modal-background {
  opacity: 0;
}
.modal-background-image {
  overflow: visible;
  background: transparent;
}
.box-image {
  box-shadow: none !important;
  background: transparent;
  margin-top: -40px !important;
}
.box-image2 {
  box-shadow: none !important;
  background: white;
  margin-top: 0px !important;
}
/*--------- end of react modal ------------*/

.m-top-50 {
  margin-top: 50px;
}

table th {
  padding-bottom: 5px;
}

.loader {
  /*border: 10px solid #f3f3f3;*/
  border-radius: 100%;
  border-top: 3px solid #fff;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 1023px) {
  /*.navbar-menu {
    background-color: #fff;
  }*/
  .navbar-dropdown .navbar-item {
    color: #fff !important;
  }
}

.modal-trans {
  overflow: auto;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
}

.images-galery {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  margin-top: 12px;
}

/*-------- paging -------------*/
.bulet li a {
  margin-left: 10px;
  border-radius: 60%;
  border: 1px solid #f36603;
  color: #f36603;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}
li.btn-numbered-page {
  /*margin: 0px;*/
  float: left;
}
.bulet li.active a {
  margin-left: 10px;
  border-radius: 60%;
  border: 1px solid #f36603;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f36603;
}
.bulet li.active a:hover {
  background-color: #f7c25e;
  border: 1px solid #f7c25e;
  color: #fff;
}
.bulet li a:hover {
  background-color: #f7c25e;
  border: 1px solid #f7c25e;
  color: #fff;
}
.bulet li.disabled a {
  display: none;
}
.btn-first-page,
.btn-last-page {
  display: none !important;
}
li.btn-prev-page a,
li.btn-next-page a {
  font-size: 19px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 9px;
  padding-right: 9px;
}
.pagination-sm {
  width: auto;
}
a {
  text-decoration: none !important;
}

/* message box*/
.box {
  box-shadow: none !important;
}
.modal-load {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}
.modal-head {
  width: 100%;
  height: 100%;
  background-color: #000 !important;
  overflow: hidden;
  z-index: 99999;
}
/*select guest*/
.hidden-select {
  -webkit-appearance: none !important;
          appearance: none !important;
}
.width-select {
  width: 100% !important;
}
/*reset password*/
.reset-center {
  width: 80% !important;
}

/* about */
.aboutpage {
  padding-bottom: 50px;
}
/* about */
/* calender */
.flatpickr-calendar {
  width: auto !important;
  margin-top: 20px !important;
  content: '' !important;
  font-size: 12px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 20px !important;
  border-radius: 0px !important;
}
.flatpickr-month,
.flatpickr-weekdays,
.flatpickr-weekdaycontainer,
.flatpickr-weekday,
.flatpickr-calendar {
  background-color: white !important;
  color: #636262 !important;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background-color: #ffb865 !important;
  box-shadow: none !important;
  color: white !important;
  font-size: 12px !important;
}
.flatpickr-day {
  border-radius: 5px;
}
.flatpickr-day:hover {
  background-color: #f3693a !important;
  border-radius: 5px;
}
.flatpickr-day.disabled:hover {
  background-color: #ccc !important;
  border-radius: 5px;
  color: #fff !important;
}
span.flatpickr-day,
span.flatpickr-day.prevMonthDay,
span.flatpickr-day.nextMonthDay {
  border: none !important;
  max-width: 50px !important;
}
span.startRange:focus {
  background-color: #f3693a !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: #f3693a !important;
  border-radius: 5px !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: none !important;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  color: #ccc !important;
}
.flatpickr-day.inRange {
  border-radius: 0px !important;
}
span.flatpickr-next-month svg,
span.flatpickr-prev-month svg {
  /*display: none;*/
  opacity: 0;
}
span.flatpickr-next-month {
  background-image: url('/icon/kanan.png') !important;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-top: 20px;
}
span.flatpickr-prev-month {
  background-image: url('/icon/kiri.png') !important;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  margin-left: 15px;
  margin-top: 20px;
}
.flatpickr-weekdaycontainer {
  padding-top: 10px !important;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  border: none !important;
}
.flatpickr-months {
  margin-top: 10px;
}
.flatpickr-prev-month:focus,
.flatpickr-next-month:focus {
  background-color: transparent !important;
}
.flatpickr-prev-month svg:focus,
.flatpickr-next-month svg:focus {
  background-color: transparent !important;
}
.dayContainer {
  min-width: 25px !important;
}

@media screen and (max-width: 640px) {
  .flatpickr-calendar {
    width: auto !important;
    margin-top: 5px !important;
    content: '' !important;
    overflow: scroll !important;
    width: 280px !important;
    font-size: 10px !important;
    border-radius: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
  }
  .flatpickr-weekdays,
  .flatpickr-days,
  .dayContainer {
    width: 260px !important;
  }
  .flatpickr-day {
    border-radius: 0px !important;
    width: 5px !important;
    padding: 0px 0px;
  }
  .dayContainer {
    min-width: 20px !important;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    background-color: #ffb865 !important;
    box-shadow: none !important;
    color: white !important;
    font-size: 10px;
  }
}

/* airbnb*/
div.DateRangePicker {
  display: none;
}
.DateInput_input {
  font-size: 14px !important;
  font-weight: 440 !important;
  font-family: 'Libre Franklin' !important;
  padding-top: 9px !important;
  padding-bottom: 5px !important;
  color: black !important;
  text-align: center !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #ffb865 !important;
}
.DateInput {
  width: 40% !important;
  padding: 0px 0px !important;
  border-radius: 15px !important;
}
.DateRangePickerInput svg {
  width: 15px !important;
  /*float: left;*/
  margin-left: 5px;
}
.DateRangePickerInput {
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
  overflow: hidden;
  width: 100% !important;
}
.DateRangePicker {
  border-radius: 15px !important;
  padding-left: 2px !important;
  width: 100% !important;
}
.CalendarDay__selected_span {
  background-color: #ffb865 !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #f3693a !important;
  border-radius: 5px !important;
}
button.DayPickerKeyboardShortcuts_buttonReset {
  display: none !important;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  color: white !important;
  background: #ffb865 !important;
  border: none !important;
}
.CalendarDay__default {
  border: none !important;
  padding: 10px 10px !important;
  margin-bottom: 2px !important;
  border-bottom: 2px solid white;
}
.DateRangePicker_picker {
  margin-right: 10px !important;
  left: -30px !important;
}
.hilang {
  display: none !important;
}
.DayPickerNavigation_button__default {
  border: none !important;
}
.CalendarMonth {
  margin-bottom: 50px;
}
input#startDate1 {
  margin-left: -10px !important;
  width: 60px;
}
input#endDate1 {
  width: 60px;
}
input#startDate1_B {
  /*margin-left: 5px !important;*/
  margin-right: 15px !important;
  /*width: 60px !important;*/
  /*padding-left: 15px !important;*/
}
#endDate1_B {
  width: 70px !important;
  margin-left: 20px !important;
}
.DateInput_input {
  padding: 0px !important;
  padding-top: 9px !important;
  padding-bottom: 6px !important;
}
.hilang {
  display: none !important;
}
.labelDates {
  width: 100%;
  text-align: center;
  padding: 9.5px 0px;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.labelGuest {
  width: 100%;
  text-align: center;
  padding: 9.5px 0px;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.labelGuestList {
  width: 100%;
  text-align: center;
  padding: 9.5px 0px;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.guestHilang {
  width: 60%;
  padding: 9.5px 0px;
  color: black;
  margin-left: -50px;
  height: 150px;
  margin-top: 24px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 9999 !important;
}
.guestHilangList {
  width: 200%;
  padding: 9.5px 0px;
  color: black;
  margin-left: -60px;
  margin-top: 24px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 9999 !important;
}
.guestHilangListAll {
  width: 200%;
  padding: 9.5px 0px;
  color: black;
  margin-left: -70px;
  margin-top: 0px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 9999 !important;
}
.guestHilangDetail {
  width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;
}
@media screen and (max-width: 769px) {
  /* airbnb*/
  .DateRangePickerInput {
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
    overflow: hidden;
  }
  .DateRangePicker {
    border-radius: 15px !important;
    width: 90% !important;
  }
  .DateRangePickerInput svg {
    width: 10px !important;
    float: left;
  }
  .DateInput {
    width: 60px;
    padding: 0px 0px !important;
    border-radius: 15px !important;
  }
  .DateRangePicker_picker {
    margin-right: 10px !important;
    left: 0px !important;
    margin-top: -10px !important;
  }
  input#startDate1 {
    margin-left: -10px !important;
    width: 60px;
  }
  input#endDate1 {
    width: 60px;
  }
  input#startDate1_B {
    /*width: 60px;*/
    padding-left: 0px !important;
  }
  .DateInput_input {
    padding: 0px !important;
    padding-top: 9px !important;
    padding-bottom: 6px !important;
  }
  .labelDates {
    width: 90%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}

/**/
/* calender */

/* kode phone */
/*.react-phone-number-input{
    width: 45px !important;
    border: solid 1px #ccc;
    padding: 0px !important;
    border-right: none !important;
    height: 50px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  .react-phone-number-input,.rrui__input-element{
    height: 37px !important;
    padding-bottom: 5px !important;
    padding-left: 2px !important;
  }
  .rrui__input-element{
    border-bottom: none;
    border: none !important;
  }
  .react-phone-number-input__icon,img.react-phone-number-input__icon-image{
    width: 30px !important;
    height: 20px !important;
  }
  .rrui__select__selected-label{
    margin-top: -5px !important;
    width: 30px !important;
  }
  .rrui__select__arrow{
    display: none !important;
  }
  .labelCodePhone{

  }
  .labelCode{
    margin: 0px !important;
    padding: 0px !important;
    padding-top: 7px !important;
    width: 50px !important;
    padding-right: 5px !important;
    border: 1px solid #ccc;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 5px !important;
  }
  .rrui__select,.react-phone-number-input__row,.rrui__input-element,.rrui__select__selected-content{
    width: 30px !important;

  }
  .rrui__select__options:not(.rrui__select__options--menu){
    width: 200px !important;
  }

  .rrui__select__autocomplete{
    z-index: 99999 !important;
    position: sticky;
    margin-bottom: 5px !important;
    width: 202px !important;
    border: 1px solid #ccc !important;
    margin-top: 40px;
    margin-left: -20px;
    border-radius: 4px !important;
    padding-left: 20px !important;
  }
  .react-phone-number-input__icon svg{
    width: 30px !important;
    height: 20px !important;
  }*/

/* version 2 */
.intl-tel-input {
  width: 40px !important;
  background-color: white !important;
  height: 37px !important;
  border: solid 1px #ccc;
  padding-right: 5px !important;
  border-right: none !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.intl-tel-input {
  width: 40px !important;
}
input[type='tel'] {
  width: 40px !important;
  padding: 0px !important;
  display: none !important;
}
.country-list {
  width: 250px !important;
  margin-top: 10px !important;
  max-height: 250px !important;
}
.highlight:not(:last-child) {
  margin-bottom: 0px !important;
}
.iti-arrow {
  display: none !important;
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent !important;
  width: 81px !important;
  box-shadow: none !important;
  border: none !important;
}
.intl-tel-input.allow-dropdown .selected-flag {
  width: 81px !important;
  box-shadow: none !important;
  border: none !important;
}
.labelCodePhone {
  width: 40px !important;
  text-align: left !important;
}
.labelCode {
  border: solid 1px #ccc;
  height: 37px !important;
  padding-top: 6px !important;
  width: auto !important;
  padding-right: 5px !important;
  margin-right: 5px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-left: none !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .iti-container {
    position: fixed;
    z-index: 999991 !important;
    background-color: transparent !important;
    border: none !important;
    width: 83% !important;
    margin-top: 50px !important;
    text-align: center !important;
    height: 90% !important;
  }

  .country-list {
    width: 100% !important;
    position: absolute !important;
    max-height: 90% !important;
  }
  .column-conf {
    float: left;
    width: 50%;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .iti-container {
    position: fixed;
    z-index: 999991 !important;
    background-color: transparent !important;
    border: none !important;
    width: 90% !important;
    text-align: center !important;
    height: 90% !important;
  }
  .country-list {
    width: 100% !important;
    position: absolute !important;
    max-height: 90% !important;
  }
  .column-conf {
    float: left;
    width: 50%;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (width: 768px) {
  .labelGuest {
    width: 100%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-left: 25px;
  }
  .gambarSimilar {
    height: 220px;
    cursor: pointer;
  }
  .labelGuestList {
    width: 100%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    margin-left: -1px !important;
    cursor: pointer;
  }
  .bg-done {
    font-size: 16px;
    margin-top: -20px;
    font-weight: bold;
    cursor: pointer;
    color: #f36603;
    margin-left: 55% !important;
  }
  .guestHilang {
    width: 50% !important;
    padding: 9.5px 0px !important;
    color: black !important;
    margin-left: 7px !important;
    height: 150px !important;
    margin-top: 10px !important;
    background-color: white !important;
    font-size: 14px !important;
    border: 1px solid #ccc !important;
    cursor: pointer !important;
    position: absolute !important;
  }
  .guestHilangListAll {
    width: 100% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: 0px !important;
    margin-top: -15px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999 !important;
  }
  .guestHilangList {
    width: 100% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: 0px !important;
    margin-top: 5px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: absolute;
    z-index: 9999 !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .country-list {
    width: 250px !important;
    position: absolute !important;
    max-height: 300px !important;
  }
  .column-conf {
    float: left;
    width: 25%;
  }
}
/* kode phone */

.tabs .is-active {
  border-bottom: 1px solid #f16726;
}
.tabs li.is-active a {
  border-bottom-color: #f16726;
  color: #f16726;
}

.bg-pulsa:hover {
  background-color: rgba(241, 103, 38, 0.4);
}
.input-pulsa {
  border: unset;
  padding: 10px;
  flex: 1 1;
  font-size: 16px;
}
.input-pulsa:focus {
  border: unset;
}
.input-pulsa:first-child {
  width: 30px;
}
.input-pulsa-container {
  display: flex;
  align-items: center;
  padding: 4px 20px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid rgb(116, 116, 116);
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.flex-1 {
  flex: 1 1;
}

/* start text */
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
/* end text */

.border-radius {
  border-radius: 10px;
}

/* start padding */
.p-10px {
  padding: 10px;
}

.p-20px {
  padding: 20px;
}
/* end padding */

/* start border */
.border-black {
  border: 1px solid black;
}
.border-grey {
  border: 1px solid grey;
}
/* end border */

.text-grey {
  color: grey;
}
.text-primary {
  color: #f16726;
}

/* start margin */
.ml-10px {
  margin-left: 10px;
}
.mr-10px {
  margin-right: 10px;
}
.mr-20px {
  margin-right: 20px;
}
.mr-30px {
  margin-right: 30px;
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-10px {
  margin-bottom: 10px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-10px {
  margin-top: 10px;
}
.mt-5px {
  margin-top: 5px;
}
.ml-5px {
  margin-left: 5px;
}
/* end margin */

/* max width */
.mw-100px {
  max-width: 100px;
}
/* end max width */

.cursor-pointer {
  cursor: pointer;
}
@media screen and (max-width: 425px) {
  .mt-200px-mobile {
    margin-top: 200px;
  }
  .mt-250px-mobile {
    margin-top: 250px;
  }
  .mt-350px-mobile {
    margin-top: 350px;
  }
  .mt-400px-mobile {
    margin-top: 400px;
  }
  .mt-100px-mobile {
    margin-top: 100px;
  }
  hr {
    margin: 0.6rem 0;
  }
}

.navbar {
  /*box-shadow: 0px 2px 2px 0px #eaeaea;*/
  background: #000;
  /*padding: 8px;*/
  z-index: 999;
}

.navbar-logo {
	font-size: 25px;
	font-weight: 900;
}
.navbar-link{
	color: #000;
}
.navbarhead:hover{
  background-color: red !important;
}
a.navbar-item,.navbar-item {
  color: #000;
}
.navbar-dropdown .navbar-item {
  color: #000 !important;
}

.navbar.is-black .navbar-end > a.navbar-item:hover{
	border-bottom: 1px solid #ccc;
}
.classTop{
	z-index: 100000;
}
.eng{
	color: #000;
}
.eng:hover{
	color: #000;
}
.box{
	border-radius: 10px;
}
.btnLOgin{
    width: 70%;
    background-color: #f16726;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.btnReset{
    width: 40%;
    background-color: #f16726;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.isHeader{
  border-bottom: 1px solid #ccc;
}
.menulogo:hover{
  background: transparent !important;
  color: #f16726 !important;
}
.menu:hover{
  background: transparent !important;
  border-bottom: 1.5px solid #f16726;
  color: #f16726 !important;
}
.menudropdown:hover{
  background: #fff !important;
}
.menudropdownchild:hover{
 background: #fff !important;
 color:  #000 !important;
}

a.menudropdownchild:hover,a.menudropdown:hover{
  background-color: rgba(251, 127, 80,0.1)  !important;
}

.footer {
  /*border-top: 2px solid #eaeaea;*/
  /* background: #fff; */
  background-color: #f9f9f9;
  padding-bottom: 5%;
  padding-top: 3%;

  /*padding-left: 100px;*/
  /*padding-right: 100px;*/
}
.containerFooter {
  z-index: 1 !important;
  padding-left: 4%;
}
.tutupIn {
  background: blue;
  z-index: 99999;
}
.footer a {
  color: #888888;
  font-weight: 700;
  width: 35px;
}

.footer-about {
  /*margin-left: 70px;*/
}

.titlefooter {
  margin-top: 20px;
}
.mobilefooter b {
  font-size: 18px;
}
.mobilefooter ul li a {
  font-size: 16px;
}

.wa-cs-container {
  position: fixed;
  z-index: 2;
  right: 10px;
  bottom: 10px;
  display: flex;
}

.wa-cs {
  display: flex;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  background-color: #1d8b15;
  box-shadow: 2px 2px 18px -5px #000000;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.wa-cs img {
  width: 20px;
  margin-right: 10px;
}
.wa-cs i {
  font-size: 20px;
}
@media screen and (max-width: 1100px) {
  .footer {
    background: #fff;
    padding-bottom: 5%;
    padding-left: 0px;
    padding-right: 0px;
    z-index: -1 !important;
  }

  .titlefooter {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding-left: 20px;
    width: 96%;
  }
}

@media screen and (max-width: 768px) {
  .footer-about {
    margin-top: 30px;
    margin-left: 0px;
  }

  .footer {
    padding-bottom: 55px;
    width: 96%;
  }

  .titlefooter {
    margin-top: 0px;
  }

  .titlefollow {
    margin-top: -50px;
  }

  .mobilefooter {
    padding-bottom: 6%;
  }
}

@media screen and (max-width: 640px) {
  .mobilefooter b {
    font-size: 16px;
  }
  .mobilefooter ul li a {
    font-size: 14px;
  }
  .footer {
    /*margin-left: 5px;*/
  }
}

.titlefollow a:hover {
  background-color: #f36603;
}


.showAll{
	clear: both;
}
/*color 466566*/
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 148px;
	    margin-top: 13px;
	}
	.kolomRespon{
		margin-top: 30px !important;
	}
}


/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 180px;
	    margin-top: 20px;
	}

	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 160px;
	    margin-top: 20px;
	}

}


/*-----------------------------------------------------------------------------------------*/


/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 220px;
	    margin-top: 20px;
	}


}


/*-----------------------------------------------------------------------------------------*/


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
	.pembatas{
	display: none;
	}

}


/*-----------------------------------------------------------------------------------------*/


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}
	.showAllTab{
		margin-top: -10px;
	}

}



.payAtHomeBackup{
	margin-top: 10px !important;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (width: 1024px){
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 100px;
	    margin-top: 20px;
	}

}

/* other screen 320*/

@media only screen and (width: 731px) {
	.payAtVillaHomeD-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 210px;
	    margin-top: 20px;
	}
}

/* other screen 320*/

@media only screen and (width: 768px) {
	.payAtHomeBackup{
		margin-top: 20px !important;
	}
	.showAllTab{
		margin-top: -40px
	}
}

/*-------------------------------------------------*/

/* other screen 320*/

@media only screen and (width: 320px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 108px;
	    margin-top: 13px;
	}
}

/* other screen 411*/

@media only screen and (width: 411px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 200px;
	    margin-top: 13px;
	}
}

/* other screen 414*/

@media only screen and (width: 414px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 200px;
	    margin-top: 13px;
	}
}
@media only screen and (width: 375px) {
	.payAtVillaHomeM-180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 160px;
	    margin-top: 13px;
	}
}
/* other screen tablet*/
@media only screen and (width: 768px) {
.kolomResponheadD{
	margin-top: 10px !important;
}
.kolomResponheadDTag{
	margin-top: -60px !important;
}

}




/*--------not screen----------*/
.showAll{
	margin-top: 0px
}
.labelTypeVilla180913{
	width: 100%;
	font-size: 14px;
	color: #3A6A6D;
	font-weight: bold;
	position: relative;
	margin-bottom: 10px;
}
.labelCityEvent{
	font-size: 14px;
	position: relative;
	color: black;
}


.labelCityName180913{
	width: 100%;
	font-size: 14px;
	position: relative;
	color: black;
}
.labelEventName180913{
	width: 100%;
	font-size: 12px;
	position: relative;
	color: black;
}
.labelEventKalneder180913{
	width: 100%;
	font-size: 14px;
	position: relative;
	color: black;
}
.labelAddressKalender180914{
	margin-top: 1px;
}

.iconLocationVilla180913{
	height: 12px;
    width: 12px;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
}
.iconKalender180913{
		height: 15px;
    width: 15px;
    float: left;
    margin-right: 5px;
    margin-top: 2px;
}
.labelAddress180914{
	float: left;
	margin-top: 3px;
}
.labelEventAddress180914{
	float: left;
	margin-top: 6px;
}
.boxKonten180913{
	padding-top: 7px !important;
}
.kolomharg{
	padding-top: 0px !important;
}
.labelNameVilla180914{
	color: black !important;
	font-size: 16px !important;
}
div.box.boxheight{
	border:solid 0.5px #E9ECEC;
	padding-bottom: 0px !important;
	height: auto !important;
    padding-bottom: 10px !important;
}
.pembatas{
	clear: both;
	height: 50px;
	width: 100%;
}
.kolomResponhead{
	margin-top: 70px !important;
	padding: 0px !important;
}
.kolomResponbody{
	margin-top: -60px !important;
}
.kolomResponheadD{
	margin-top: 60px ;
}
.kolomResponheadTag{
	margin-top: -60px;
}
.kolomResponbodyM{
	margin-top: 5px !important;
}

.popup-promo {
  max-width: 800px;
}
.popup-image {
  max-width: 100%;
}
@media screen and (max-width: 425px) {
  .hide-on-mobile {
    display: none;
  }
  .popup-image {
    max-width: 90%;
  }
  .popup-promo {
    text-align: center;
    max-width: 95%;
  }
}

@media screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }
}

.input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #aaa !important;
}

::-webkit-input-placeholder {
  /* Most modern browsers support this now. */
  color: #aaa !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #aaa !important;
}

.orange-btn {
  padding: 2px;
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient(180deg, #f77e3c 19.05%, #ff9b64 138.1%);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

/*color 466566*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 731px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 375px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px;
    margin-top: 20px;
  }
}
/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 414px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 230px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 411px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 230px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 823px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 812*/

@media only screen and (width: 812px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 768*/

@media only screen and (width: 768px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 220px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1024*/

@media only screen and (width: 1024px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1366*/

@media only screen and (width: 1366px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 885*/

@media only screen and (width: 885px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-right: 35px !important;
    right: 0;
    margin-top: 20px;
  }
}

/*--------not screen----------*/

.labelTypeVilla180913 {
  width: 100%;
  font-size: 14px;
  color: #3a6a6d;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
}

.labelCityName180913 {
  width: 100%;
  font-size: 14px;
  position: relative;
  color: black;
}

.iconLocationVilla180913 {
  height: 12px;
  width: 12px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.labelAddress180914 {
  float: left;
  margin-top: 3px;
}
.boxKonten180913 {
  padding-top: 7px !important;
}
.kolomharga {
  padding-top: 0px !important;
}
.labelNameVilla180914 {
  color: black !important;
  font-size: 16px !important;
}
.section2 {
  background-color: #fff !important;
}
div.box.boxheight {
  border: solid 0.5px #e9ecec;
  padding-bottom: 0px !important;
  height: auto !important;
  padding-bottom: 10px !important;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1) !important;
  border-radius: 21px !important;
}
.DateRangePicker_picker,
.guestHilangListAll,
.guestHilangList {
  z-index: 10 !important;
}

.text-danger {
  color: red !important;
}
.childIMages {
  padding-right: 0px !important;
  overflow: hidden;
  width: 75% !important;
  float: left;
}
.childIMagesMore {
  padding-left: 0px !important;
  width: 25% !important;
  float: left;
}
.imagesMore {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100% !important;
  height: 110px !important;
  z-index: 1;
}
.imagesChile {
  width: 100% !important;
  height: 110px !important;
  border-right: solid 2px white !important;
  cursor: pointer;
}
.seeMore {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 110px !important;
  position: relative;
  text-align: center;
  color: #fff;
  padding-top: 40px;
  cursor: pointer;
  z-index: 2;
  margin-top: -115px;
}
.columnImgChild {
  margin-top: -20px !important;
}
.desktopImage {
  margin-left: 0% !important;
}
.allSlider {
  width: 760px !important;
  height: 427px !important;
}
/*slider header*/
.imgSliderHeader {
  width: 760px !important;
  height: 427px !important;
}
.fontImage {
  z-index: 9999;
  color: white;
}
.fontImagePer {
  z-index: 9999;
  color: white;
}
/*slider header*/

/*slider all*/
.sliderAll,
.imgSliderAll {
  width: 100% !important;
  height: 400px !important;
}
@media screen and (width: 768px) {
  .sliderAll {
    height: 427px !important;
  }
  .fontImage {
    z-index: 9999;
    margin-top: 10px;
    color: white;
  }
  .fontImagePer {
    z-index: 9999;
    margin-top: 10px;
    color: white;
  }
  .desktopImage {
    margin-top: -35%;
    width: 760px !important;
    /* margin-left: -210px !important; */
  }
}
@media screen and (height: 768px) {
  .imgSliderAll,
  .sliderAll {
    width: 700px !important;
    height: 394px !important;
  }
  .fontImagePer {
    z-index: 9999;
    margin-right: -91px;
    color: white;
  }
}
@media screen and (width: 320px) {
  .sliderAll {
    margin-top: 28% !important;
  }
}
/*slider all*/
.slick-next {
  margin-right: 5px !important;
}
@media screen and (max-width: 640px) {
  /*slider header*/
  .imgSliderHeader {
    width: 760px !important;
    height: 210px !important;
  }
  /*slider header*/

  /*slider all*/
  .sliderAll,
  .imgSliderAll {
    width: 317px !important;
    height: 200px !important;
  }
  @media screen and (max-width: 320px) {
    /*slider all*/
    .sliderAll,
    .imgSliderAll {
      width: 300px !important;
      height: 210px !important;
    }
  }
  /*slider all*/

  .childIMages {
    padding-right: 0px !important;
    overflow: hidden;
    width: 75% !important;
    float: left;
  }
  .childIMagesMore {
    padding-left: 0px !important;
    width: 25% !important;
    float: left;
  }
  .imagesMore {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100% !important;
    height: 50px !important;
    /*float: right !important;*/
  }
  .imagesChile {
    width: 100% !important;
    height: 50px !important;
  }
  .seeMore {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    width: 100%;
    height: 50px !important;
    position: relative;
    text-align: center;
    color: #fff;
    padding-top: 20px;
    cursor: pointer;
    z-index: 2;
    margin-top: -57px;
    font-size: 8px;
  }
  .desktopImage {
    /* margin-left: 5px !important; */
  }
  .mapJarak {
    margin-bottom: 0px !important;
  }
  .text-image {
    height: 25px !important;
    padding-top: 8px !important;
    width: 30% !important;
  }
  .text-image > p {
    font-size: 9px !important;
  }
}

/*----------- other -------*/
.zoom,
img.imageKecil {
  /*height: 60px !important;*/
}
/*.batas-image{
	width: 100%;
	height: 5px;
	clear: both;
}*/

.listFacilities img{  
	width: 20px;
	height: 25px;
	margin-bottom: -5px;
	margin-left: 10px;
	margin-right: 10px;
}
.kilometermap{
	margin-left: 40px;
	color: #A9A7A7;
	padding-bottom: 20px;
}
.facilitiesmap label{
	font-weight: bold;
	margin-left: 30px;
}
.facilitiesmap{
	padding-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100% !important;
}
.listFacilities{
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	font-size: 14px;
	width: 100%;
}
.iconSelectFacilities input{
	position: absolute;
	display: none;
}
.iconSelectFacilities .selectFacilites{
	width: 20px;
	height: 20px;
	position: absolute;
	border:1px solid #ccc;
}
.iconSelectFacilities input:checked ~ .selectFacilites{
	background-image: url('/icon/ceklis.png');
	background-size: 15px 15px;
	background-position: center;
	background-repeat: no-repeat;
	border:1px solid #f16726;
}
.batasJarak{
	width: 100%;
	height: 5px;
	background-color: transparent;
}
.box-ruless{
	width: 100%;
	height: auto;
	margin-top: 10px;
	font-size: 14px;
}
ul.list-rules{
	margin-top: -15px;
}
ul.list-rules li{
	list-style-type: circle;
	margin-left: 25px;
}
.headerModalRules{
	width: 100%;
	font-size: 18px;
	font-weight: bold;
}
.boxRules{
	border-bottom: solid 1px #ccc;
	width: 97% !important;
	margin-left: 10px !important;
	padding-left: 0px !important;
	font-size: 12px !important;
	height: 40px !important;
}
.boxRulesRes{
	width: 97% !important;
	margin-left: 10px !important;
	padding-left: 0px !important;
	font-size: 12px !important;
	height: 40px !important;
}
.iconRules{
	width: 30%;
}
.labelRules{
	width: 70%;
}
.iconRulesRes label{
	margin-left: 10px;
	color: #f36603;
}
.iconRules,.labelRules,.iconRulesRes,.labelRulesRes{
	float: left;
}

/*responsive*/
.rulesDetail{
	/*border-bottom: solid 1px #ccc;*/
	margin-bottom: 0px !important;
}
.iconRulesRes{
	width: 40%;
}
.labelRulesRes{
	width: 60%;
}
.boxModalRules{
	max-height: 1000px !important;
	height: auto !important;
	padding-bottom: 20px !important;
	padding-left: 30px !important;
	padding-right: 35px !important;
}
.desRules{
	margin-top: 10px;
}




  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.box-ruless{
		width: 100%;
		height: auto;
		margin-top: 10px;
		font-size: 12px;
	}
	.boxRules{
		border-bottom: solid 1px #ccc;
		width: 97% !important;
		margin-left: 10px !important;
		padding-left: 0px !important;
		font-size: 12px !important;
		/*height:55px !important;*/
		padding-bottom: 30px !important;
	}
	.boxRulesRes{
		width: 97% !important;
		margin-left: 10px !important;
		padding-left: 0px !important;
		font-size: 12px !important;
		padding-bottom: 30px !important;
		/*height:55px !important;*/
	}
	.iconRules,.labelRules,.iconRulesRes,.labelRulesRes{
		float: left;
	}
	.iconRules,.labelRules,.iconRulesRes,.labelRulesRes{
		width: 50%;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}

  /* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.boxModalRules{
		margin-top: 5px !important;
    	margin-bottom: 5px;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
	.boxModalRules{
		margin-top: 5px !important;
    	margin-bottom: 5px;
	}
}
@media only screen and (width: 640px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 731px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 823px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 568px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 411px) {
	.boxModalRules{
		margin-top: -100px !important;
    	margin-bottom: 5px;
	}

}
.boxDate div.DateRangePicker{
	display: block !important;
}
.DateRangePickerInput_clearDates{
	top: 40% !important;
}
.DateRangePickerInput_clearDates:hover{
	background-color: transparent !important;
}
.hotelListBooking > div.DateRangePicker{
	display: block !important;
}
.control-Guest{
	height: 60px !important;
	margin-top: 10px !important;
	margin-left: 3.5% !important;
}
.control-Guest2{
	height: 60px !important;
	margin-left: 3.5% !important;
}
.control-Guest .select-option,.control-Guest2 .select-option{
	width: 95% !important;
	border-radius: 4px !important;
}
.boKPopUp{
	padding: 10px 10px !important;
}
.hotelListBooking div div.DateRangePickerInput{
	border-radius: 4px !important;
	width: 100.5% !important;
	margin-left: 3% !important;
}
.labelBokPopUp{
	margin-left: 4% !important;
  font-size: 14px !important;
}
.labelBok{
	padding-left: 7% !important;
}
.bokNameVilla{
	margin-left: 3.5% !important;
}
.labelGuestDetail {
	width: 93%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 4px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-left: 3.5%;
}
.labelGuestDetailA2{
  width: 355px;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 4px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-left: 3.5%;
}
.guestHilangDetailRes{
  width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;
}
.guestHilangDetailNotZero{
	width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;
}
.guestHilangDetailNotZeroRes{
	width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;	
}
.garisBooking{
  background-color: #ccc;
  height: 1px !important;
  padding: 0px !important;
  margin-left: 22px;
  margin-bottom: 20px;
  margin-top: 5px;
  width: 355px !important;
}
.labelBookHeader{
  margin-top: 20px;
  padding-right: 25px;
}
.labelBookHeaderB{
  padding-right: 0px !important;
}
.labelPrice{
  font-size: 12px !important;
}
.labelTotalBox{
  padding-left: 25px;
}
.ttlPrice{
  font-size: 14px !important;
  font-weight: bold;
  color: #383635 !important;
}
.labelJml{
  padding-right: 15px;
}
.boxDetail{
  margin-top: -20px;
}
.garisBookingTotal{
  background-color: #ccc;
  height: 1px !important;
  padding: 0px !important;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 35px;
  width: 86% !important;
  margin-left: 7%;
  margin-top: 20px !important;
}
.btnBooking{
  width: 93.5% !important;
  border-radius: 5px !important;
}
.labelBookingZero{
  margin-top: -5px;
  margin-bottom: -50px;
}
.labelZeroBook{
  padding-left: 25px !important;
  margin-top: 20px !important;
  width: 60% !important;
}
.labelPayAtZero{
  width: 40% !important;
}
.labeZeroBook{
  color: black !important;
  font-size: 14px !important;
}
.garisBookingTotalZero{
  background-color: #ccc;
  height: 1px !important;
  padding: 0px !important;
  margin-left: 23px;
  margin-bottom: 30px;
  margin-top: -15px;
  margin-bottom: 20px;
  width: 87% !important;
  margin-top: 5px !important;
}
.boxGuestDetail{
  width: 100%;
  padding: 9.5px 0px;
  color: black;
  margin-left: 5px;
  margin-top: 5px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  /*cursor: pointer;*/
  position: absolute;
  z-index: 9999 !important;
  position: absolute;
}
.price-responsive{
  font-size: 14px !important;
  color: black !important;
}
.payatvillaResZero{
  margin-left: 50px;
  margin-top: 0px;
}
.payAllRes{
  margin-top: -30px !important;
}
.labelPayAt{
  margin-top: 0px !important; 
  width: 35% !important;
  text-align: right !important;
}
.bookNameVilla{
  width: 60% !important;
}
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .paylist{
    margin-top: 0px !important;
  }
  .column-price{
    margin-top: 7px !important;
  }
  .labelGuestDetailA2{
    width: 93% !important;
  }
}

@media only screen and (max-width: 375px) {
  .labelGuestDetailA2{
    width: 94% !important;
  }
  .garisBooking {
    background-color: #ccc;
    height: 1px !important;
    padding: 0px !important;
    margin-left: 22px;
    margin-bottom: 20px;
    margin-top: 5px;
    width: 87% !important;
  }
  .hotelListBooking div div.DateRangePickerInput{
    border-radius: 4px !important;
    width: 102% !important;
    margin-left: 3% !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .paylist{
    margin-top: 0px !important;
  }
  .boxModal{
    max-height: 450px !important;
    margin-top: 150px !important;
    padding-top: 80px !important;
  }
  .hotelListBooking div div.DateRangePickerInput{
    width: 94% !important;
  }
  .labelBookHeaderB{
    padding-right: 0px !important;
    margin-top: 25px !important;
  }
  .hotelListBookingB div div.DateRangePickerInput{
    width: 100% !important;
  }
  .boxDetailA{
    margin-top: -70px !important;
  }
  .hotelListBookingA2 div div.DateRangePickerInput{
    width: 355px !important;
  }
  .labelPayAtA2{
    margin-right: 0px !important;
  }
  .labelGuestDetailA2{
    width: 93% !important;
  }
}

  /* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (width: 768px) {
  .paylist{
    margin-top: 25px !important;
  }
  .boxModal{
    max-height: 470px !important;
    margin-top: -150px !important;
    padding-top: 80px !important;
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 850px){
   .labelNameVillaA{
    width: 60% !important;
  }
  .labelPatAtVillaA{
    width: 40% !important;
  }
  .hotelListBookingA div div.DateRangePickerInput{
    width: 100% !important;
  }
  .payAtVillaNoResA{
    margin-right: -15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
  .paylist{
    margin-top: 25px !important;
  }
  .payAtVillaNoResA{
    margin-top: 5px !important;
    margin-right: -15px !important;
  }
  .labelNameVillaA{
    width: 60% !important;
  }
  .labelPatAtVillaA{
    width: 35% !important;
  }
  .labelPatAtVillB{
    margin-top: 0px !important;
    float: right;
  }
  .hotelListBookingA div div.DateRangePickerInput{
    width: 100% !important;
  }
  .hotelListBookingB div div.DateRangePickerInput{
    width: 100% !important;
  }
  #BoxDetailBooking{
    width: 361px !important;
  }
}


/* new style */
.boxHeaderModal{
  width: 100% !important;
  margin-top: 5px !important;
}
.boxHeaderModalA{
  width: 100% !important;
  margin-top: 5px !important;
}
.bokModalBooking{
  overflow: visible;
  padding-left: 30px;
  padding-right: 5px;
  padding-top: 20px !important;
  padding-bottom: 30px !important;
  height: auto;
  max-height: 600px;
}
.labelNamaVilla{
  width: 60% !important;
  float: left;
  height: auto;
  padding: 0px !important;
}
.labelPayAtModal{
  width: 40% !important;
  overflow: hidden;
  height: auto;
  padding: 0px !important;
  float: left;
  left: 0;
  padding-top: 5px !important;
}
.batasGarisModalAtas{
  width: 100% !important;
  background-color: #ccc;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 1px !important;
}
.batasGarisModalAtasA1{
    width: 93% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 10px;
}
.payAtVillaModalA2{
  margin-top: 0px !important;
}
.labelNameModalA2{
  padding-left: 0px !important;
  padding-right: 30px !important;
}
.labelNameModalA1{
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.labelPayAtModalA2{
  padding-right: 0px !important;
}
.labelPayAtModalA1{
  padding-right: 0px !important;
}
.boxModalDateA2{
  padding-left: 0px !important;
  width: 100% !important;
  margin-top: 20px !important;
}
.labels{
  font-size: 14px;
  font-weight: bold;
}
.boxModalDateA2 div.column,.boxModalGuestA2 div.column {
  padding: 0px !important;
}
.boxModalDateA2 div.column div.field label,.boxModalGuestA2 div.column div.field label {
  font-size: 14px;
  font-weight: bold;
}
.hotelListBooking div div.DateRangePickerInput{
  width: 100% !important;
  margin-left: 0px !important;
}
div.DateRangePicker, .DateRangePickerInput{
  width: 100% !important;
}
.boxModalGuestA2{
  padding-left: 0px !important;
  width: 100% !important;
  margin-top: 10px !important;
}
.labelGuestDetailA2{
  width: 100% !important;
  margin: 0px !important;
}
.labelHargaModalA2{
  margin-top: 20px !important;
}
.labelPriceMalam{
  font-size: 14px;
  color: black;
  width: 100%;
  margin-left: -5px;
}
.labeltotalPrice{
  font-size: 14px;
  font-weight: bold;
  color: black;
  width: 100%;
  margin-left: -5px;
}
.btnBooking{
  width: 100% !important;
  margin-left: -25px !important;
  margin-top: 20px !important;
}
.boxDateA1 {
    padding-left: 10px !important;
    width: 100% !important;
    margin-top: 20px !important;
}
.hotelListBookingA1 div div.DateRangePickerInput{
   width: 105% !important;
   display: block !important;
  }
.boxDateA1 div div.DateRangePickerInput{
   width: 107.5% !important;
   display: block !important;
}
.boxDateA1 div.column{
  padding-right: 0px;
  padding-left: 10px;
}
.boxGuestA1{
  padding-left: 10px;
  padding-right: 10px;
}
.labelHargaA1{
  width: 95%;
  margin-left: 10px;
  text-align: center;
  padding-left: 10px;
}
.batasGarisA1{
    width: 93% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 10px;
}
.btnReservation{
  padding-left: 20px !important;
}
.btnReservation div{
  padding-right: 9px !important;
}
input#endDate1{
  width: 70px;
}
input#endDate2{
  width: 70px;
}
.priceNotZero{
  margin-top: 0px !important;
}



  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bokModalBooking{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .hotelListBooking div div.DateRangePickerInput{
    width: 108% !important;
  }
}
@media only screen and (width: 360px) {
  .bokModalBooking{
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 568px) {
  .bokModalBooking{
    margin-top: 200px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 640px) {
  .bokModalBooking{
    margin-top: 200px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 411px) {
  .bokModalBooking{
    margin-top: -50px !important;
    margin-bottom: 50px !important;
  }
}
@media only screen and (width: 768px) {
  .hotelListBooking div div.DateRangePickerInput{
    width: 108% !important;
  }
  .bokModalBookingA{
    margin-top: 50px !important;
  }
}
@media only screen and (width: 823px) {
  .boxHeaderModalA{
    width: 110% !important;
  }
  .labelPayAtModalA1{
    padding-right: 5px !important;
  }
  .labelNameModalA1 {
    padding-left: 10px !important;
    padding-right: 30px !important;
  }
  .batasGarisModalAtasA1{
    width: 100% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 0px;
  }
  .boxDateA1 div div.DateRangePickerInput{
    width: 114% !important;
    display: block !important;
  }
  .boxDateA1 div.column{
    padding-right: 0px;
    padding-left: 0px;
  }
  .boxGuestA1{
    padding-left: 0px;
    padding-right: 0px;
  }
  .batasGarisA1{
    width: 93% !important;
    background-color: #ccc;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 1px !important;
    margin-left: 5px;
  }
  .btnReservation{
    padding-left: 15px !important;
  }
  .btnReservation div{
    padding-right: 0px !important;
  }
}
.bookGuestA1{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: 1;
}
.blockTanggal{
 text-decoration: line-through !important; 
 color: #ccc;
}
.labelKonfr{
  font-size: 12px;
  margin-left: 10px;
}

@media only screen and (width: 812px) {
  .boxHeaderModalA{
    width: 110% !important;
  }
  .labelPayAtModalA1{
    padding-right: 5px !important;
  }
  .labelNameModalA1 {
    padding-left: 10px !important;
    padding-right: 30px !important;
  }
}
@media only screen and (width: 375px) {
  .bokModalBooking{
    margin-top: 0px !important;
    margin-bottom: 50px !important;
  }
}

@media only screen and (width: 1024px) {
  .boxDateA1 div div.DateRangePickerInput{
    width: 108% !important;
  }
  .boxGuestDetail{
    width: 115% !important;
    margin-left: -15px !important;
  }
   #BoxDetailBooking{
    width: 310px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bokModalBooking{
    margin-top: 200px !important;
    margin-bottom: 50px !important;
  }
}

@media only screen and (width: 768px) {
  .bokModalBooking{
    margin-top: 50px !important;
  }
  .column-price{
    margin-top: 20px !important;
  }
  .priceNotZero{
    margin-top: 10px !important; 
  }
}
.circle-akses {
  height: 23px;
  width: 23px;
  background-color: #bbb;
  border-radius: 50%;
	font-size: 16px;
  display: inline-block;
	text-align: center;
}
.imageAkses{
  margin-top: -10%;
}
.text-akses{
	font-size: 14px;
}
.text-aksesModal{
  font-size: 13px;
}
.mobile-akses{
  display: none;
}
.desktop-akses{
  display: block;
}
.box-akses{
	width: 100%;
	height: auto !important;
	margin-top: 10px;
	font-size: 14px;
}
@media screen and (max-width: 800px){
.desktop-akses{
  display: none;
}
.boxAkses{
  margin-top: -100px;
}
.mobile-akses{
  display: block;
}
}


 466566*/
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	/* .payAtVillaSimilar{
			font-size: 12px !important;
				border-color: #F16726;
				background-color: #F16726;
				border-radius: 12px;
				width: 110px;
				height: 25px;
				font-weight: bold;
				padding-top: 4px;
				position: absolute;
				z-index: 9;
				margin-left: 160px !important;
				margin-top: 20px;

	} */

}


/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 10px !important;
    	right: 0;
	    margin-top: 20px;
	}

}


/*-----------------------------------------------------------------------------------------*/


/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 10px !important;
    	right: 0;
	    margin-top: 20px;
	}


}


/*-----------------------------------------------------------------------------------------*/


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 10px !important;
    	right: 0;
	    margin-top: 20px;
	}


}


/*-----------------------------------------------------------------------------------------*/


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
	/* .payAtVillaSimilar{
			font-size: 12px !important;
		    border-color: #F16726;
		    background-color: #F16726;
		    border-radius: 12px;
		    width: 110px;
		    height: 25px;
		    font-weight: bold;
		    padding-top: 4px;
		    position: absolute;
		    z-index: 9;
				margin-left: 150px;
				margin-top: 20px;
	} */


}


/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 823px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 35px !important;
    	right: 0;
	    margin-top: 20px;
	}
}


/*--------------------------------------*/

/* other screen 812*/

@media only screen and (width: 812px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 35px !important;
    	right: 0;
	    margin-top: 20px;
	}
}


/*--------------------------------------*/

/* other screen 1024*/

@media only screen and (width: 1024px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 35px !important;
    	right: 0;
	    margin-top: 20px;
	}
}


/*--------------------------------------*/

/* other screen 1366*/

@media only screen and (width: 1366px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 35px !important;
    	right: 0;
	    margin-top: 20px;
	}
}

/*--------------------------------------*/

/* other screen 885*/

@media only screen and (width: 885px) {
	.payAtVillaSimilar180914{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-right: 35px !important;
    	right: 0;
	    margin-top: 20px;
	}
}





/*--------not screen----------*/

.labelTypeVilla180913{
	width: 100%;
	font-size: 14px;
	color: #3A6A6D;
	font-weight: bold;
	position: relative;
	margin-bottom: 10px;
}

.labelCityName180913{
	width: 100%;
	font-size: 14px;
	position: relative;
	color: black;
}

.iconLocationVilla180913{
	height: 12px;
    width: 12px;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
}

.labelAddress180914{
	float: left;
	margin-top: 3px;
}

.labelNameVillaHeader180913{
	font-size: 20px;
	font-weight: bold;
	color: black;
}
.labelAddressHeaderVilla180913{
	font-size: 12px;
	color: black;
}


/*color 466566*/
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 180px;
	    margin-top: 20px;
	}
*/
}


/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}
*/
}


/*-----------------------------------------------------------------------------------------*/


/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/


}


/*-----------------------------------------------------------------------------------------*/


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/


}


/*-----------------------------------------------------------------------------------------*/


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/


}


/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 823px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}


/*--------------------------------------*/

/* other screen 812*/

@media only screen and (width: 812px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}


/*--------------------------------------*/

/* other screen 1024*/

@media only screen and (width: 1024px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}


/*--------------------------------------*/

/* other screen 1366*/

@media only screen and (width: 1366px) {
	/*.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}

/*--------------------------------------*/

/* other screen 885*/

@media only screen and (width: 885px) {
/*	.payAtVilla180913{
		font-size: 12px !important;
	    border-color: #F16726;
	    background-color: #F16726;
	    border-radius: 12px;
	    width: 110px;
	    height: 25px;
	    font-weight: bold;
	    padding-top: 4px;
	    position: absolute;
	    z-index: 9;
	    margin-left: 140px;
	    margin-top: 20px;
	}*/
}





/*--------not screen----------*/

.labelTypeVilla180913{
	width: 100%;
	font-size: 14px;
	color: #3A6A6D;
	font-weight: bold;
	position: relative;
	margin-bottom: 10px;
}

.labelCityName180913{
	width: 100%;
	font-size: 14px;
	position: relative;
	color: black;
}

.iconLocationVilla180913{
	height: 15px;
    width: 15px;
}

.labelNameVillaHeader180913{
	font-size: 20px;
	font-weight: bold;
	color: black;
}
.labelAddressHeaderVilla180913{
	font-size: 12px;
	color: black;
}
.boxKonten180913{
	padding-top: 7px !important;
}
.kontenDescDetail180914{
	margin-top: 5px !important;
}
.detail-reser2 {
  width: 100% !important;
}

@media screen and (max-width: 425px) {
  hr {
    margin: 1.2rem 20px;
  }
}

.text-promo-menarik {
  font-size: 36px;
  color: white;
  font-weight: bold;
}

.quotes {
  font-size: 28px;
}
.select select {
  border-radius: 8px;
  border: unset;
}
.select:not(.is-multiple)::after {
  border: 1px solid #f16726;
  border-right: 0;
  border-top: 0;
  content: ' ';
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.5em;
  margin-top: -0.375em;
  right: 1.125em;
  top: 50%;
  z-index: 4;
}
.text-white {
  color: white;
}
.hello {
  font-size: 24px;
  color: white;
  font-weight: bold;
}
.bg-gray {
  background-color: #bbbbbb;
}

.text-black {
  color: black;
}

.imgVillasList {
  border-radius: 20px !important;
}

.buttonLoginFacebook {
  background-color: white;
  width: 165px;
  height: 30px;
  margin-bottom: -30px;
  border: none;
  background-image: url('/icon/loginwithfb.png');
  background-size: cover;
  margin-right: 5px;
  background-repeat: no-repeat;
}

.tagVilla {
  margin-top: -4%;
}
.text-spoken {
  margin-top: -10%;
  font-size: 12px;
}
.tanggalKanan {
  margin-left: 10px !important;
}
.circle-image {
  border: 3px solid #7e9cc2;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backAtas {
  position: absolute;
  width: 100%;
  height: 100px;
}
.reset-box {
  z-index: 999;
}
.modal-background-image-galery {
  width: 320px;
  height: 300px;
  margin-top: -350px !important;
}
.imageKategori {
  padding-right: 0.1px !important;
  margin-bottom: -12px !important;
  width: 20% !important;
  float: left;
  z-index: 1;
}
.imageKecil {
  width: 200px;
  height: 85px !important;
  cursor: pointer;
}
.zoom {
  background-color: rgba(1, 1, 1, 0.6);
  width: 100%;
  cursor: pointer;
  z-index: 9999 !important;
  margin-top: -92.5px;
  height: 86px; /* IE 9 */
  -webkit-transform: scale(1); /* Safari 3-8 */
  transform: scale(1);
}

/* .active, .imageSmall:hover {

} */
.active,
.zoom:hover {
  cursor: pointer;
  background-color: transparent;
}
.scrool::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}
.scrool::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}
/* Track */

/* Handle */
/*  */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
		background: white;
} */
.scrollImage {
  height: 130px;
  z-index: 9999;
  margin-top: -1px;
  overflow-y: scroll;
}

.discount {
  font-size: 14px;
}
.promopopup {
  width: 1200px;
  height: 600px;
}

.buttonLoginGoogle {
  background-color: white;
  width: 165px;
  height: 30px;
  margin-bottom: -30px;
  border: none;
  background-image: url('/icon/loginwithgoogle.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.buttonLoginFacebook:hover,
.buttonLoginGoogle:hover {
  cursor: pointer;
}
.input-conf {
  width: 300px;
  margin-right: 100px;
}

.closebanner {
  font-size: 20px;
  margin-top: -5px;
  cursor: pointer;
}

.tombol-popup {
  width: 150px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 1215px) {
  .namavilla {
    width: 190px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 1200px) {
  .namavilla {
    width: 190px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 1000px) {
  .namavilla {
    width: 170px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 940px) {
  .namavilla {
    width: 170px !important;
  }

  .payatvilla {
    width: 95px !important;
  }
}

@media screen and (max-width: 900px) {
  .namavilla {
    width: 150px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 850px) {
  .namavilla {
    width: 130px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 400px) {
  .booknama {
    width: 120px !important;
  }
  .bookpayat {
    width: 120px !important;
  }
}

@media screen and (max-width: 1200px) {
  .promopopup {
    width: auto !important;
    height: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .input-conf {
    margin-left: 24%;
    margin-right: 0px;
  }

  .promopopup {
    width: auto !important;
    height: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .input-conf {
    margin-left: 23%;
  }
  .desktopsHome {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .input-conf {
    margin-left: 20%;
  }
}

@media screen and (max-width: 550px) {
  .input-conf {
    margin-left: 18%;
  }
}

@media screen and (max-width: 500px) {
  .input-conf {
    margin-left: 15%;
  }
}

@media screen and (max-width: 450px) {
  .input-conf {
    margin-left: 7%;
  }
}

@media screen and (max-width: 400px) {
  .input-conf {
    margin-left: 2%;
  }
}

@media screen and (max-width: 321px) {
  .input-conf {
    width: 250px;
  }
}

.box-button {
  width: 31%;
  margin-left: 69%;
  margin-top: -65px;
}

.searchbox {
  height: 255px;
  /* padding-top: 20px; */
  /*padding-left: 5px;*/
  /*padding-right: 5px;*/
  /* padding-bottom: 20px; */
  width: 45% !important;
  padding: 30px;
  border-radius: 25px !important;
}
.guestImage {
  margin-top: 12px;
  margin-left: 7%;
}
.guestImageHome {
  margin-top: 15px;
  margin-left: 7%;
}
.tamu {
  margin-left: -5%;
  margin-top: 5px;
}

.tamuHome {
  margin-left: -5%;
  margin-top: 8px;
}
.guestImage p {
  margin-top: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #333432;
}
.guestImageHome p {
  margin-top: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #333432;
}

.box-guest {
  border-color: #cfcfcf;
  border-radius: 100px;
  padding-left: 5px;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1 !important; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1 !important; /* Firefox */
}

.whitening {
  background-color: white !important;
  font-size: 16px !important;
}

.guesthome {
  border-color: #cfcfcf !important;
  height: 40px !important;
}

.linkto {
  color: blue !important;
}

@media screen and (max-width: 1024px) {
  .noname {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .buttonLoginFacebook {
    background-color: white;
    width: 90%;
    height: 40px;
    margin-bottom: -30px;
    border: none;
    background-image: url('/icon/loginwithfb.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 10%;
  }
  .buttonLoginGoogle {
    background-color: white;
    width: 90%;
    height: 40px;
    margin-bottom: -30px;
    border: none;
    background-image: url('/icon/loginwithgoogle.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 10%;
  }
  .modal {
    background-color: #fff;
  }
  .box {
    border: none;
  }
  .box-login {
    margin-top: -80px;
  }
  .btnCloseLogin {
    z-index: 99999;
  }
  .box-register {
    margin-top: -50px;
  }

  .btnLOginFb,
  .btnLOginGoogle {
    width: 160px;
    background-color: transparent;
    height: 44px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 15px;
  }
  .btnLOginFb {
    background-image: url('/img/tombol fb.png');
    background-size: cover;
    padding: 3px 3px;
    border: 1px solid #000;
  }
  .btnLOginGoogle {
    margin-left: 15px;
    background-image: url('/img/tombol google.png');
    background-size: cover;
    padding: 3px 3px;
    border: 1px solid #000;
  }
}
.button-make-request {
  background: transparent;
  width: 70px;
  height: 70px;
  cursor: pointer;
  position: fixed;
  margin-bottom: 30px;
  margin-right: 35px;
  bottom: 0;
  right: 0;
}

.register {
  margin-bottom: 30px;
  background-color: white;
}
.btnLOgin {
  width: 70%;
  background-color: #f16726;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
.btnPromo {
  width: 100%;
  background-color: #f16726;
  color: white;
  padding: 10px 10px;
  /*margin: 8px 0;*/
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.password-login {
  height: 150px;
}
.labelatau {
  z-index: 11;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.garislogin {
  z-index: 10;
  margin-top: -11px;
  border: 0.5px solid #ccc;
}
.btnLOginFb,
.btnLOginGoogle {
  width: 160px;
  background-color: transparent;
  padding: 12px 10px;
  margin: 8px 0;
  height: 44px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 15px;
}
.btnLOginFb {
  background-image: url('/img/tombol fb.png');
  background-size: cover;
  padding: 3px 3px;
  border: 1px solid #000;
}
.btnLOginGoogle {
  background-image: url('/img/tombol google.png');
  background-size: cover;
  padding: 3px 3px;
  border: 1px solid #000;
}
.labellogin {
  font-size: 14px;
}
.modal {
  z-index: 9991;
}
.input-register {
  width: 260px;
}
.input-login {
  width: 260px;
}
.phone-register {
  width: 100%;
  /*float: right;*/
}
/*.boxPhoneRegister{
	width: 63% !important;
}*/
@media screen and (max-width: 600px) {
  .btnLOginFb,
  .btnLOginGoogle {
    width: 115px;
    background-color: transparent;
    padding: 12px 10px;
    margin: 8px 0;
    height: 30px;
    border: solid 1px gray;
    border-radius: 15px;
    cursor: pointer;
    font-size: 15px;
  }
  .btnLOginFb {
    margin-left: 5px;
    background-image: url('/img/tombol fb.png');
    background-size: cover;
  }
  .btnLOginGoogle {
    margin-left: 5px;
    background-image: url('/img/tombol google.png');
    background-size: cover;
  }
  .btnClose {
    background-image: url('/img/close.png');
    background-size: cover;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    z-index: 9999;
    position: absolute;
    margin-top: 10px;
    margin-right: 10px;
  }
  .box {
    box-shadow: none;
  }
  .input-register {
    width: 100%;
  }
  .input-login {
    width: 100%;
  }
}
.phone {
  width: 190px;
}

.search {
  border-radius: 10px;
  margin-top: 10%;
  width: 30%;
  margin-left: 53%;
  background: #ffffff;
  z-index: 100;
  position: absolute;
}
.select-option2 {
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  border-color: #cfcfcf;
  border-radius: 10px;
  text-align-last: center;
  height: 44px;
  width: 100%;
  box-shadow: none !important;
  border: solid 1px #d8d6d6;
  padding-left: 5px !important;
  text-indent: -5px;
}
.select-option {
  box-shadow: none !important;
  border: solid 1px gray;
  height: 42.5px !important;
  font-size: 15px !important;
}

@media screen and (max-width: 800px) {
  .tagVilla {
    margin-top: -50%;
  }
  .select-option {
    cursor: pointer;
    font-size: 16px;
    background-color: white;
    border-color: #cfcfcf;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 200%;
    text-align-last: center;
    height: 42.5px !important;
    margin-top: -9px !important;
  }
  .payAtTab {
    margin-left: -5px !important;
  }
  .durasi {
    display: none;
  }
  .name-responsive {
    margin-bottom: 1px;
    margin-top: -35px;
  }

  .guesthome {
    border-color: #cfcfcf !important;
    width: 70% !important;
    margin-top: -10px;
    margin-left: 32%;
  }
}
@media screen and (max-width: 800px) {
  .select-option {
    cursor: pointer;
    font-size: 16px;
    background-color: white;
    margin-top: -28%;
    border-radius: 10px;
    /*margin-left: 30%;*/
    width: 90% !important;
    text-align: center;
    text-align-last: center;
    height: 55px;
  }
}
.select-option {
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  border-radius: 10px !important;
  border-color: #cfcfcf;
  width: 100%;
  text-align-last: center;
  height: 40px;
  padding-left: 5px !important;
  text-indent: -5px;
}
.select-option > option {
  padding-top: 10px !important;
}
option.optionGUest {
  padding-top: 50px !important;
}

@media screen and (max-width: 500px) {
  .search {
    border-radius: 5px;
    height: 30%;
    background: #ffffff;
    margin-top: 40%;
    z-index: 100;
    margin-left: 7% !important;
  }
  .searchbox {
    height: 255px;
  }
}

@media screen and (max-width: 800px) {
  .search {
    border-radius: 10px;
    width: 100%;
    height: 30%;
    background: #ffffff;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    margin-top: 35%;
    z-index: 100;
    margin-left: 5.5%;
  }
  /*.search select{
	border-radius: 10px;
	width: 100% !important;
	margin-left: 7px !important;
}*/

  .searchbox {
    height: 255px;
    width: 93% !important;
    margin-bottom: 20px;
  }
}
.text-background {
  border-radius: 10px;
  margin-top: 25%;
  margin-left: 10%;
  background: transparent;
  z-index: 10;
  position: absolute;
}
.text-background2 {
  border-radius: 10px;
  margin-top: 24.2%;
  margin-left: 9.15%;
  background: transparent;
  z-index: 10;
  position: absolute;
}
@media screen and (max-width: 800px) {
  .text-background {
    margin-top: 15%;
  }
  .text-background2 {
    border-radius: 10px;
    margin-left: 6.7%;
    background: transparent;
    z-index: 10;
    position: absolute;
    margin-top: 15%;
  }
}

.text-background p {
  color: white;
  font-size: 36px;
}
.text-background2 p {
  color: white;
  font-size: 36px;
}
@media screen and (max-width: 600px) {
  .text-background p {
    color: white;
    font-size: 18px;
  }
  .text-background2 p {
    color: white;
    font-size: 18px;
  }
}
.box-radius {
  border-radius: 10px;
  height: 40px;
  text-align: center;
  border-color: #cfcfcf;
  font-size: 14;
  padding-right: 5px;
}

@media screen and (max-width: 800px) {
  .box-radius {
    border-radius: 10px;
    width: 55% !important;
    /*margin-left: -2%;*/
    text-align: center;
    font-size: 14;
    padding-right: 13px;
    margin-right: 25px !important;
  }
  .respo {
    font-size: 12px !important;
    margin-top: -32px !important;
    margin-right: -5px !important;
  }

  .emptybox {
    clear: both;
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .emptybox {
    clear: both;
    height: 60px;
  }
}

@media screen and (max-width: 700px) {
  .emptybox {
    clear: both;
    height: 75px;
  }
}

@media screen and (max-width: 590px) {
  .emptybox {
    clear: both;
    height: 85px;
  }
}

@media screen and (max-width: 450px) {
  .emptybox {
    clear: both;
    height: 10px;
  }
}

@media screen and (max-width: 800px) {
  .box-guest {
    border-radius: 10px;
    width: 50% !important;
    height: 40px;
    margin-top: 1px;
    margin-left: -12.5%;
    text-align: center;
    /*padding-left: 13px;*/
  }
  .kolomRespon {
    margin-top: 130px;
  }
}
.box-button {
  border-radius: 8px;
  height: 40px !important;
}
@media screen and (max-width: 800px) {
  .box-button {
    border-radius: 8px;
    width: 100% !important;
    margin-left: -370%;
    margin-top: 5%;
    text-align: center;
    margin-left: 0px;
  }
  .icon-pop {
    margin-top: -10px;
  }
  .price-total {
    display: none;
  }
}
.box-select {
  border-radius: 1000px;
  width: 200px;
}
.text-fasilitas {
  font-size: 12px;
}
.orange {
  color: #ff9900;
  font-size: 18px;
  margin-right: 5px !important;
  margin-left: 5px;
  margin-top: -38px;
  font-weight: bold;
}

.price-responsive {
  font-size: 16px;
  margin-right: 5px !important;
  margin-left: 0px;
  margin-top: -38px;
}
.size {
  font-size: 12px;
  margin-top: 5%;
  margin-left: -50%;
}
.tombol {
  width: 150px;
  height: 40px;
  margin-top: -10px;
  margin-bottom: 18px;
  border-radius: 10px;
}
@media screen and (max-width: 800px) {
  .size {
    margin-left: 4%;
    font-size: 12px;
  }
}
@media screen and (max-width: 360px) {
  .tombol-responsive {
    width: 30%;
    margin-top: -35px !important;
    margin-right: -200px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 360px) {
  .tombol-responsive {
    width: 30%;
    margin-top: -35px !important;
    margin-right: -200px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 800px) {
  .tombol-responsive {
    width: 30%;
    margin-top: -36px;
    margin-right: -200px;
    border-radius: 10px;
  }
  .tombol {
    display: none;
  }
}
.font-file {
  font-size: 14px;
  padding-left: 15px;
}
.font-city {
  font-size: 14px;
  margin-bottom: 20px;
}
.font-label {
  font-size: 12px;
  margin-bottom: 2%;
}
.font-title {
  font-size: 18px;
  margin-bottom: 1%;
}
.font-name {
  font-size: 18px;
  margin-bottom: 1%;
}
.colom {
  margin-right: -15%;
}

.galery {
  height: -50px;
}
.read-more-home {
  color: #f27602;
  font-size: 17px;
  font-weight: bold;
}
.reset-filter {
  color: #4a4a4a;
  font-size: 13px;
  cursor: pointer;
  margin-top: 13px;
}
.read-more-home:hover {
  color: #f16726 !important;
}
.read-more {
  color: #ff9900;
  font-size: 14px;
}
.read-more:hover {
  color: #f16726 !important;
}

.find-more {
  color: white;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.find-more:hover {
  font-weight: bold;
}
.bullet-m {
  height: 25px;
  width: 25px;
  background-color: #ff7d00;
  border-radius: 50%;
  margin-top: -2%;
  display: inline-block;
}
.bullet {
  height: 25px;
  width: 25px;
  background-color: #ff9900;
  border-radius: 50%;
  margin-top: -2%;
  display: inline-block;
}
.bullet-maximal {
  margin-left: -5%;
}
@media screen and (max-width: 800px) {
  .bullet-maximal {
    margin-left: 30px;
    width: 70% !important;
    font-size: 12px;
    margin-top: -47px;
  }
}
.bullet-minnight {
  margin-left: -5%;
}
@media screen and (max-width: 800px) {
  .bullet-minnight {
    margin-left: 190px;
    margin-top: -48px;
    font-size: 12px;
    width: 140px !important;
  }
  .reset-filter {
    color: #4a4a4a;
    font-size: 14px;
    cursor: pointer;
    margin-top: 30px;
    text-align: right;
  }
  .reset-box {
    z-index: 999;
    margin-top: -20px;
  }
  .text-minNight {
    margin-left: 8px;
  }
  .bullet-column {
    width: 140px !important;
    margin-left: 170px;
    margin-top: -42px;
  }
  .bullet-m {
    height: 25px;
    width: 25px;
    background-color: #ff7d00;
    border-radius: 50%;
    display: inline-block;
  }
}
.per-night {
  margin-left: -35px;
}
.info {
  margin-left: -15px;
}
@media screen and (max-width: 900px) {
  .info {
    margin-left: 0px;
  }
}
@media screen and (max-width: 900px) {
  .per-night {
    margin-left: 0px;
  }
}
.price {
  margin-left: 25px;
}
@media screen and (max-width: 800px) {
  .price {
    margin-left: 0px;
  }
}
.image-box {
  margin-top: -33px;
}
.image-detail {
  height: 370px;
  width: 10px;
}
.image-preview {
  height: 400px;
  width: 100px;
}
.view-image {
  margin-left: -10px;
}
.image-detail-bottom {
  height: 100px;
  width: 188px;
  cursor: pointer;
  margin-left: 1px;
}
.image-view-all {
  height: 100px;
  width: 190px;
  z-index: 90;
  cursor: pointer;
  background: black;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=50);
  font-size: 16px;
  margin-left: 569px;
  margin-top: -107px;
}
@media only screen and (max-width: 1100px) {
  .image-view-all {
    height: 100px;
    width: 190px;
    z-index: 90;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
    margin-left: 2px !important;
    margin-top: -107px;
  }
  .text-detail-image {
    background: transparent;
    z-index: 80;
    color: white;
    font-weight: 1000;
    margin-left: 45px !important;
    margin-top: 35px !important;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 16px;
    position: absolute;
  }
}
@media only screen and (max-width: 960px) {
  .image-view-all {
    height: 100px;
    width: 190px;
    z-index: 90;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
    margin-left: 192px !important;
    margin-top: -107px;
  }
  .text-detail-image {
    background: transparent;
    z-index: 80;
    color: white;
    font-weight: bold;
    margin-left: 230px !important;
    margin-top: -70px !important;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 16px;
    position: absolute;
  }
}
.image-detail-bottom-grayscale {
  height: 100px;
  background: white;
  width: 190px;
  cursor: pointer;
  margin-left: 2px;
}

.text-detail-image {
  background: transparent;
  z-index: 80;
  color: white;
  font-weight: 1000;
  margin-left: 610px;
  margin-top: -70px;
  cursor: pointer;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-size: 16px;
  position: absolute;
}
.input-reservation2 {
  width: 100% !important;
}
@media screen and (width: 640px),
  (width: 568px),
  (width: 667px),
  (width: 736px),
  (width: 768px) {
  .box-detail-absolute {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    bottom: 0;
    margin-bottom: -1% !important;
    padding: 1.25rem;
    z-index: 100;
  }
  .box-detail {
    position: fixed;
    width: 100%;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -1% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
}
@media screen and (max-width: 768px) {
  .bg-secondary {
    background-color: white;
    border-radius: 13px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 5px !important;
    margin-top: -30px !important;
    padding-top: 10px;
    box-shadow: 1px 1px 10px grey;
  }
  .filter-ls {
    width: 50%;
    float: left;
    margin: 0px;
  }
  .box-list {
    padding-top: 30px !important;
  }
  .input-reservation {
    width: 100% !important;
  }
  .input-reservation2 {
    width: 100% !important;
  }
  .label-down {
    width: 93% !important;
  }
  .box-reservation {
    margin-bottom: 25px;
  }
  .is-input {
    width: 100% !important;
  }
  .box-reservation-detail {
    margin-bottom: 5px !important;
  }
}
.btn-filterls {
  margin-left: 0px;
  height: 42px !important;
}
/* .box-deatil-column{
	position: fixed;
	background: white;
	box-shadow: 0px 2px 4px 1px  #bbbbbb;
	border-radius: 6px;
	padding: 1.25rem;
} */
.box-detail {
  position: fixed;
  width: 30.2% !important;
  background: white;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  box-shadow: 0px 2px 4px 1px #bbbbbb;
  border-radius: 6px;
  padding: 1.25rem;
}
.box-detail-absolute {
  position: absolute;
  background: white;
  box-shadow: 0px 2px 4px 1px #bbbbbb;
  border-radius: 6px;
  padding: 1.25rem;
  z-index: 100;
}

/*form reset password*/
.input-reset {
  width: 305px !important;
}

@media screen and (max-width: 321px) {
  .input-reset {
    width: 250px !important;
  }
}

@media screen and (min-width: 400px) {
  .input-reset {
    margin-left: 5.5%;
  }
}

@media screen and (min-width: 430px) {
  .input-reset {
    margin-left: 9%;
  }
}

@media screen and (min-width: 480px) {
  .input-reset {
    margin-left: 16%;
  }
}

@media screen and (min-width: 530px) {
  .input-reset {
    margin-left: 19%;
  }
}

@media screen and (min-width: 580px) {
  .input-reset {
    margin-left: 23%;
  }
}

@media screen and (min-width: 700px) {
  .input-reset {
    margin-left: 25%;
  }
}

@media screen and (min-width: 769px) {
  .input-reset {
    margin-left: 0%;
  }
}

.btnReset {
  width: 30%;
  background-color: #f16726;
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}
/*form reset password*/

/*forgot*/
a.link-forgot {
  color: #f7c25e !important;
}
a.link-forgot:hover {
  color: #f16726 !important;
}

/* form reservation */
.box-reservation {
  border: solid 1px #e6e6e6;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: -25px;
}
.box-reservation-detail {
  border: solid 1px #e6e6e6;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: -25px;
  margin-bottom: 50px;
}
.is-input {
  width: 50%;
}
.label-down {
  color: #bdbdbd;
  font-size: 10px;
  padding-top: 5px;
  font-weight: 100;
}
.title-request {
  font-weight: bold;
  font-size: 18px;
}
table.detail-reser tr td {
  padding-bottom: 10px !important;
  font-size: 14px !important;
}

.detail-reser2 {
  width: 67%;
}

table.detail-reser2 tr td {
  padding-bottom: 5px !important;
  font-size: 14px !important;
  text-align: justify;
}

table.facilitytable tr td {
  padding-bottom: 10px !important;
}

.btnCheckout {
  width: 100%;
  background-color: #f16726;
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}
.list-promo {
  padding-top: 10px;
  padding-bottom: -10px;
}
.input-select {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background-color: red;
  overflow: hidden;
}

.switchs {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switchs input {
  display: none;
}

.sliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.sliders:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input[name='sliders']:checked + .sliders {
  background-color: #f16726;
}

input[name='sliders']:focus + .sliders {
  box-shadow: 0 0 1px #2196f3;
}

input[name='sliders']:checked + .sliders:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliders.round {
  border-radius: 34px;
}
.filed-input {
  background: white !important;
  color: black !important;
  border: 2px solid #e5e5e6 !important;
  border-radius: 5px;
}
.filed {
  background: white !important;
  border: 2px solid #e5e5e6 !important;
  color: black !important;
  border-radius: 5px;
}
.filed-input-popup {
  background: white !important;
  color: black !important;
  border: 2px solid #e5e5e6 !important;
  border-radius: 5px;
}
.filed-popup {
  background: white !important;
  color: black !important;
  border: 2px solid #e5e5e6 !important;
  border-radius: 5px;
}

.sliders.round:before {
  border-radius: 50%;
}

.promo-nonactive {
  /*display: none;*/
  opacity: 0;
  position: absolute;
  z-index: -10;
}
.promo-active {
  /*display: block;*/
  opacity: 10;
  position: static;
  z-index: 0;
}
span.panah-bawah {
  background-image: url('/icon/panah-bawah.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin-left: 10px;
}
span.panah-atas {
  background-image: url('/panah-atas.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin-left: 10px;
}
.request-active {
  display: block;
}
.request-nonactive {
  display: none;
}
.box-request {
  cursor: pointer;
}
.panah-request-aktif {
  margin-left: 10px;
  margin-bottom: -2px;
  opacity: 10;
  z-index: 2;
  cursor: pointer;
}
.panah-request-nonaktif {
  margin-left: 10px;
  margin-bottom: -2px;
  opacity: 0;
  z-index: 1;
  margin-left: -18px;
  cursor: pointer;
}
/* form reservation */

/* cahnge password profile*/

.btnCahngePass {
  border-radius: 10px;
  width: 200px;
  padding-top: 8px;
  padding-bottom: 26px;
  font-size: 12px;
}

@media screen and (max-width: 769px) {
  table.detail-reser2 tr td {
    padding-bottom: 5px !important;
    font-size: 12px !important;
  }
  .detail-reser2 {
    width: 100% !important;
  }

  .tablemobile {
    margin-top: 50px !important;
    margin-bottom: 5px !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .discount {
    font-size: 11px;
  }
  .find-more {
    font-size: 9px;
    margin-left: 3px;
  }
  .closebanner {
    font-size: 14px;
    margin-top: 1px;
  }
  .labelbanner {
    margin-top: -1px;
  }
}

@media screen and (max-width: 320px) {
  .labelbanner {
    margin-top: -2px;
  }
  .modal-background-image-galery {
    width: 300px !important;
    height: 300px !important;
    margin-top: -280px !important;
  }
}

@media screen and (max-width: 350px) {
  .discount {
    font-size: 10px;
  }
  .find-more {
    font-size: 7px;
  }
  .closebanner {
    font-size: 10px;
    margin-top: 6px !important;
  }
}

/* cahnge password profile*/

@media only screen and (width: 768px) {
  .box-button {
    border-radius: 8px;
    width: 100% !important;
    margin-left: -370%;
    margin-top: 3%;
    text-align: center;
    margin-left: 0px;
  }
  .tagVilla {
    margin-top: -25%;
  }
  .box-detail-absolute {
    position: fixed;
    width: 100%;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -10% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .scrollImage {
    height: 280px;
    overflow-y: auto;
    width: 100%;
    z-index: 9999;
    position: relative;
    background-color: transparent !important;
  }
  .box-detail {
    position: fixed;
    width: 100%;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -1% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
}
@media only screen and (min-width: 800px) {
  .respo {
    display: none;
  }
  .tes-modal {
    display: none !important;
  }
  .mapJarak {
    margin-bottom: 0px;
  }

  .iconMinimal {
    padding-left: 40px !important;
  }
  .responsive {
    display: none;
  }
  .responsive-guest {
    display: none;
  }
  .modal-background-image {
    width: 100px !important;
    height: 800px !important;
    margin-top: 300px;
    padding-top: 320px !important;
  }
  .modal-background-image-galery {
    width: 90% !important;
    height: 800px !important;
    margin-left: 90px;
    margin-top: 300px;
    padding-top: 320px !important;
  }
  .desktop {
    padding: 2px !important;
    padding-right: 20px;
  }
  .icon-responsive {
    margin-top: -1% !important;
    margin-left: 1px !important;
  }
  .orange {
    color: #ff9900;
    font-size: 16px;
    margin-right: 5px !important;
    margin-top: 0px !important ;
    font-weight: bold;
  }
  .price-total {
    color: #ff9900;
    font-size: 16px;
    margin-right: 5px !important;
    margin-top: 0px !important ;
    font-weight: bold;
  }
  .price-responsive {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .line {
    margin-top: -4%;
  }
  .line-2 {
    margin-top: 0px;
  }
  .fasilitas {
    margin-top: -3%;
  }
  .map-responsive {
    margin-top: -2.5%;
  }
  .name-responsive {
    display: none;
  }
  .filed-text {
    display: none;
  }
  .text-detail-image-responsive {
    display: none;
  }
  .image-view-all-responsive {
    display: none;
  }
  .image-detail-bottom-responsive {
    display: none;
  }
  .image-detail-bottom-grayscale-responsive {
    display: none;
  }
  .tombol-responsive {
    display: none;
  }
  .box-text {
    display: none;
  }
  .modal-content-book {
    display: none;
  }
}
@media only screen and (max-width: 320px) {
  .image-detail-bottom-responsive {
    height: 50px;
    width: 88px !important;
    cursor: pointer;
    margin-left: 1px;
  }
  .boxStickyMobile {
    display: block;
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 105%;
    z-index: 999;
    border: solid 1px #ccc;
    margin-left: -25px !important;
    background-color: white;
  }
  .box-detail-absolute {
    position: fixed;
    width: 100% !important;
    height: 100px !important;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -15% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .image-view-all-responsive {
    height: 50px;
    width: 89px !important;
    z-index: 90;
    margin-top: -57px;
    margin-left: 179px !important;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 88px !important;
    cursor: pointer;
    z-index: 2;
    margin-left: 2px;
  }
  .text-detail-image-responsive {
    color: black;
    font-size: 10px;
    margin-top: -40px !important;
    margin-left: 188px !important;
    z-index: 1 !important;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
}
@media only screen and (max-width: 800px) {
  /* .box-fix{
		position: fixed !important;
		bottom: 0;
		z-index: 100;
} */
  .popup {
    width: 15px;
  }
  .total-popup {
    color: #ff9900;
    font-weight: bold;
  }
  .box-detail {
    position: fixed;
    width: 100% !important;
    height: 100px !important;
    margin-left: -12px;
    background: white;
    bottom: 0;
    border: 1.5px solid #d3d3d3;
    margin-bottom: -11%;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .desktop {
    display: none;
  }
  .desktop-fasil {
    display: none;
  }
  .responsive {
    padding: 1px;
    padding-left: 10px;
  }
  .responsive-guest {
    padding: 1px;
    padding-left: 10px;
    margin-top: -10%;
  }

  .box-detail-absolute {
    position: fixed;
    width: 100%;
    height: 100px !important;
    margin-left: -12px;
    background: white;
    border: 1.5px solid #d3d3d3;
    bottom: 0;
    margin-bottom: -11%;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .box-detail-popup {
    width: 100%;
    background: white;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .font-file {
    display: none;
  }
  .filed-text {
    font-size: 10px;
    margin-top: -40px;
    margin-left: 0px;
    font-weight: bold;
  }
  .icon-responsive {
    margin-top: -35px;
    margin-left: 10px;
  }

  .filed-input {
    display: none;
  }
  .filed {
    display: none;
  }
  .font-city {
    display: none;
  }
  .normal {
    display: none;
  }
  /* .image-box{
	display: none;
} */
  .image-view-all-responsive {
    height: 50px;
    width: 100px;
    z-index: 90;
    margin-top: -57px;
    margin-left: 205px;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .text-detail-image {
    background: transparent;
    color: white;
    font-weight: bold;
    margin-left: 40px;
    margin-top: 40px;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 16px;
    position: absolute;
  }
  .text-fasilitas {
    font-size: 12px;
  }
  .font-name {
    display: none;
  }
  .tombol-popup {
    width: 150px;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .orange-popup {
    font-size: 14px;
    margin-right: 5px !important;
    margin-top: 0px !important ;
  }
  .orange-total-popup {
    color: #ff9900;
    margin-top: -16px;
    margin-bottom: -10px;
    font-size: 16px;
    font-weight: bold;
  }
  .modal-background-image {
    width: 320px !important;
    height: 300px !important;
    margin-top: -100px !important;
  }
  .modal-background-image-galery {
    width: 320px;
    height: 300px;
    margin-top: -150px !important;
  }
  .imageKecil {
    width: 80px;
    height: 30px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -37px;
    height: 31px; /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
  .image-detail-bottom {
    height: 50px;
    width: 100px;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-detail-bottom-responsive {
    height: 50px;
    width: 100px;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 100px;
    cursor: pointer;
    z-index: 1;
    margin-left: 2px;
  }
  .image-detail-bottom-grayscale {
    height: 50px;
    background: white;
    width: 100px;
    cursor: pointer;
    margin-left: 2px;
  }
  .image-box {
    display: none;
  }
  .image-respon {
    margin-top: -33px;
  }
  .text-detail-image-responsive {
    color: white;
    font-size: 10px;
    margin-top: -40px;
    margin-left: 220px;
    z-index: 100;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
  .image-detail {
    height: 250px;
    width: 10px;
  }
  .line {
    margin-top: -9%;
  }
  .line-2 {
    margin-top: -4%;
  }
  .fasilitas {
    margin-top: -60px;
  }
  .map-responsive {
    margin-top: -50px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 375px) {
  .image-detail-bottom-responsive {
    height: 50px;
    width: 105px !important;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-view-all-responsive {
    height: 50px;
    width: 108px !important;
    z-index: 90;
    margin-top: -57px;
    margin-left: 210px !important;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 105px !important;
    cursor: pointer;
    z-index: 1;
    margin-left: 2px;
  }
  .text-detail-image-responsive {
    color: white;
    font-size: 10px;
    margin-top: -40px !important;
    margin-left: 230px !important;
    z-index: 2;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
}
@media only screen and (min-width: 414px), (min-width: 411px) {
  .image-detail-bottom-responsive {
    height: 50px;
    width: 118px !important;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-view-all-responsive {
    height: 50px;
    width: 118px !important;
    z-index: 90;
    margin-top: -57px;
    margin-left: 240px !important;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 118px !important;
    cursor: pointer;
    z-index: 1;
    margin-left: 2px;
  }
  .text-detail-image-responsive {
    color: white;
    font-size: 10px;
    margin-top: -40px !important;
    margin-left: 265px !important;
    z-index: 2;
    cursor: pointer;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
}
.durasi {
  font-size: 16px;
}
.icon-pop {
  margin-top: -21px;
}

.boxSticky {
  position: fixed;
  width: 30%;
  height: auto;
  top: 93px;
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 10px;
  display: block;
}
.boxStickynot {
  position: fixed;
  width: 30%;
  height: auto;
  top: 125px;
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 10px;
  display: block;
}
.boxStickyMobile {
  display: none;
}
.labelFasilitas {
  margin-bottom: 10px !important;
}

@media screen and (max-width: 800px) {
  .boxSticky {
    display: none;
  }
  .boxStickynot {
    display: none;
  }
  .boxStickyMobile {
    display: block;
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 105%;
    z-index: 999;
    border: solid 1px #ccc;
    margin-left: -25px !important;
    background-color: white;
  }
  .column-price,
  .column-buttom {
    float: left;
    padding-top: 12px;
  }
  .column-price {
    width: 60%;
    padding-left: 15px;
    font-size: 10px;
  }

  .column-price label {
    font-weight: bold;
    font-size: 14px;
  }
  .column-buttom {
    width: 40%;
    text-align: center;
  }
  .column-buttom button {
    margin-right: 10%;
    border-radius: 10px;
    width: 100px;
  }
  .column-label label {
    font-weight: bold;
    font-size: 10px !important;
  }
  .iconImg img {
    margin-top: 10px !important;
  }
  .mapJarak {
    margin-bottom: -100px;
  }
  .payAt {
    border: 1px solid !important;
    border-radius: 5px !important;
    padding: 2px;
    width: 90px;
    color: white;
    background-color: #f36603;
    font-size: 13px;
  }
}
@media only screen and (min-width: 768px) {
  .responsive-guest {
    padding: 1px;
    padding-left: 10px;
    margin-top: -5%;
  }
  .mobilesHome {
    display: none;
  }
  .line {
    margin-top: -4% !important;
  }
}

/* responsive phone and tablet */
@media only screen and (width: 768px) {
  .boxStickyMobile {
    display: block;
    position: fixed;
    bottom: 0;
    height: 80px;
    width: 105%;
    z-index: 999;
    border: solid 1px #ccc;
    margin-left: -25px !important;
    background-color: white;
  }
  .reset-filter {
    color: #4a4a4a;
    font-size: 14px;
    cursor: pointer;
    margin-top: 30px;
  }
  .box-list {
    padding-top: 50px !important;
  }
  .column-label label {
    font-weight: bold;
    font-size: 14px !important;
  }
  .payAtTab {
    margin-top: 28px;
    margin-left: -5px !important;
  }
  .column-price,
  .column-buttom {
    float: left;
    padding-top: 6px;
  }
  .column-label {
    margin-top: -5px;
  }
  .column-price {
    width: 60%;
    /*margin-top: 10px !important;*/
    padding-left: 15px;
    font-size: 18px;
  }
  .column-price label {
    font-weight: bold;
    font-size: 16px;
  }
  .column-buttom {
    width: 40%;
    text-align: center;
  }
  .column-buttom button {
    margin-right: 10%;
    margin-top: 15px !important;
    border-radius: 10px;
    width: 200px;
  }
  .lbl-duration {
    font-weight: bold;
    font-size: 13px !important;
  }
  .lblPrice {
    font-weight: bold;
    font-size: 20px !important;
  }
  .iconImg img {
    margin-top: 10px !important;
  }
  .font-name-popup {
    margin-top: 30px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
}

/* responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .boxOptionPay {
    margin-top: -40px;
  }
  .jarak .colum {
    margin-right: 0px !important;
  }

  .search select {
    border-radius: 10px;
    /* width: 93% !important; */
    /* margin-left: -15px !important; */
  }
  div.DateRangePicker,
  .DateRangePickerInput {
    width: 93% !important;
    text-align: center !important;
  }
  .phoneCode {
    width: 100% !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .phone-register-profile,
  .inputbox {
    height: 36px !important;
  }
  .imgVillasList {
    height: 220px !important;
    border-radius: 30px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  .scrollImage {
    height: 280px;
    overflow-y: auto;
    width: 100% !important;
    z-index: 9999;
    background-color: transparent !important;
    position: relative;
    margin-top: 12px;
  }
  .column-conf-bottom {
    padding-bottom: 20px;
  }
  .labelFasilitas {
    margin-bottom: 20px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .boxOptionPay {
    margin-top: -40px;
  }
  .boxListVillas {
    width: 50% !important;
    float: left;
  }
  .boxListVillas > .boxheight {
    /*background-color: red !important;*/
    /*height: 320px !important;*/
  }
  .search select {
    border-radius: 10px;
    width: 100% !important;
    margin-left: 7px !important;
    border: unset;
  }
  .input-login,
  .input-register {
    width: 100% !important;
  }
  .DateRangePickerInput {
    width: 100% !important;
    text-align: center !important;
  }
  .DateRangePicker {
    width: 100% !important;
  }
  div.kalenderHome > div.DateRangePicker {
    width: 92% !important;
  }
  .phoneCode {
    width: 100% !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .boxPhoneRegister {
    width: 100% !important;
  }
  .phone-register-profile,
  .inputbox {
    height: 36px !important;
  }
  .imgVillasList {
    height: 220px !important;
  }
  .DateRangePicker_picker {
    z-index: 10 !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  .labelFasilitas {
    margin-bottom: 20px !important;
  }
}

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .boxOptionPay {
    margin-top: -40px;
  }
  div.DateRangePicker,
  .DateRangePickerInput {
    width: 93% !important;
  }
  div.hotelList > div.DateRangePicker,
  .DateRangePickerInput {
    width: 100% !important;
    text-align: center !important;
  }
  .boxPhoneRegister {
    width: 100% !important;
  }
  .boxListVillas {
    width: 50% !important;
    float: left;
  }
  .payAt {
    border: 1px solid !important;
    border-radius: 5px !important;
    padding: 2px;
    color: white;
    width: 90px;
    background-color: #f36603;
    font-size: 14px;
  }
  .boxListVillas > .boxheight {
    /*background-color: blue !important;*/
    height: 400px !important;
  }
  .imgVillasList {
    height: 250px !important;
  }
  .icon {
    width: 20px !important;
  }
  .iconRangeBox {
    margin-right: 7px !important;
  }
  .iconImageBox {
    width: 25px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .boxOptionPay {
    margin-top: 10px;
  }
  .search select {
    border-radius: 10px;
    width: 100% !important;
    margin-left: 0px !important;
  }
  .phoneCode {
    width: 100% !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .boxPhoneRegister {
    width: 63% !important;
  }
  .boxPhoneProfile,
  .codePhoneProfile,
  .phone-register-profile {
    width: 100% !important;
  }
  .inputRegister {
    width: 63% !important;
  }
  .phone-register-profile,
  .inputbox {
    height: 36px !important;
  }
  .boxListVillas {
    width: 25% !important;
    float: left;
  }
  .boxListVillas > .boxheight {
    /*background-color: green !important;*/
    height: 320px !important;
  }
  .imgVillasList {
    height: 210px !important;
  }
  .iconRangeBox {
    margin-right: 7px !important;
  }
  .iconImageBox {
    width: 25px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .boxOptionPay {
    margin-top: 10px;
  }
  .boxListVillas {
    width: 25% !important;
    float: left;
  }
  .boxListVillas > .boxheight {
    /*background-color: gray !important;*/
    height: 345px !important;
  }
  .imgVillasList {
    height: 210px !important;
  }
  .iconRangeBox {
    margin-right: 7px !important;
  }
  .iconImageBox {
    width: 25px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  .labelFasilitas {
    margin-bottom: 10px !important;
  }
}

/* style option payment */
/* The container */
.containerpay {
  display: block;
  position: relative;
  /*padding-left: 35px;*/
  margin-bottom: 12px;
  cursor: default;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  font-size: 14px;
}
.containerpaydisabled {
  display: block;
  position: relative;
  /*padding-left: 35px;*/
  margin-bottom: 12px;
  cursor: default;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
  font-size: 14px;
}
.containerpaydisabled label {
  margin-left: 25px;
  font-size: 14px;
  font-weight: bold;
  margin-top: -10px;
  color: #ccc;
}
.containerpay label {
  margin-left: 25px;
  font-size: 14px;
  font-weight: bold;
  margin-top: -10px;
}
.deskripless {
  font-size: 12px;
  cursor: text;
  margin-top: -5px;
}
.deskriplessdisabled {
  font-size: 12px;
  cursor: text;
  color: #ccc;
  margin-top: -5px;
}
.containerpaydisabled p {
  margin-left: 25px;
  font-size: 12px;
  color: #ccc;
  cursor: text;
}
.labelPrice {
  font-size: 14px;
  float: right;
}
.labelPriceDisabled {
  font-size: 14px;
  float: right;
  color: #ccc;
}
.deskripPayReservation {
  font-size: 12px;
  color: #000;
}
.boxOptionPay {
  background-color: #fff;
  padding: 40px 25px;
}
.labelDesOptionPay {
  font-size: 12px;
  font-weight: bold;
}
/* Hide the browser's default radio button */
.containerpay input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.containerpaydisabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkpay {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #ccc;
  cursor: pointer;
}
.checkmarkpaydisabled {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  border: none;
  cursor: not-allowed;
}

/* On mouse-over, add a grey background color */
/*.checkmarkpay:hover{
	background-color: #ccc;
}*/

/* When the radio button is checked, add a blue background */
.containerpay input:checked ~ .checkmarkpay {
  background-color: #f16726;
}

.containerpaydisabled:hover {
  background-color: transparent;
}
.labeldisable,
.labeldisable:hover {
  cursor: text !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkpay:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerpay input:checked ~ .checkmarkpay:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerpay .checkmarkpay:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/* style option payment */

/* text-image */
.text-image {
  margin-left: 10px;
  width: 19% !important;
  margin-top: 10px;
  height: 35px;
  background: rgba(0, 0, 0, 0.8);
}
.text-image p {
  font-size: 12px;
  margin-top: -2.2%;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .text-image {
    margin-left: 13px;
    width: 30% !important;
    margin-top: 22px !important;
    background: rgba(0, 0, 0, 0.9);
  }
  .text-image p {
    font-size: 12px;
    margin-top: -4%;
    color: white;
    font-weight: bold;
  }
}
@media screen and (width: 768px) {
  .text-image {
    margin-left: 13px;
    width: 20% !important;
    margin-top: 22px !important;
    background: rgba(0, 0, 0, 0.9);
  }
  .modal-background-image-galery {
    width: 90% !important;
    height: 300px;
    margin-top: -400px !important;
  }
  .text-image p {
    font-size: 14px;
    margin-top: -5%;
    color: white;
    font-weight: bold;
  }
  .imageKecil {
    width: 100%;
    height: 75px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -83px;
    height: 76px; /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
}

@media screen and (width: 1024px) {
  .imageKecil {
    width: 100%;
    height: 65px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -73px;
    height: 66px; /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
}

@media screen and (height: 768px) {
  .text-image {
    margin-left: 13px;
    width: 20% !important;
    margin-top: 22px !important;
    background: rgba(0, 0, 0, 0.9);
  }
  .modal-background-image-galery {
    width: 90% !important;
    height: 300px;
    margin-left: 20px !important;
    margin-top: -400px !important;
  }
  .text-image p {
    font-size: 14px;
    margin-top: -5%;
    color: white;
    font-weight: bold;
  }
  .imageKecil {
    width: 100%;
    height: 100px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -107px;
    height: 100px; /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
  .scrollImage {
    height: 280px;
    overflow-y: auto;
    width: 75% !important;
    z-index: 9999;
    position: relative;
    background-color: transparent !important;
  }
}
/*  PAY AT SIMILAR*/
@media only screen and (max-width: 600px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 150px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 731px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 375px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px !important;
    margin-top: 20px;
  }
}
/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 414px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px !important;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 411px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px !important;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 823px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 812*/

@media only screen and (width: 812px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 768*/

@media only screen and (width: 768px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 220px !important;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1024*/

@media only screen and (width: 1024px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 100px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1366*/

@media only screen and (width: 1366px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 155px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 885*/

@media only screen and (width: 885px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-right: 35px !important;
    right: 0;
    margin-top: 20px;
  }
}
@media only screen and (width: 320px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 100px !important;
    margin-top: 20px;
  }
}

/* PAY AT SIMILAR */

.select .select-kota {
  width: 100% !important;
  margin-left: auto !important;
}

.fs-24px {
  font-size: 24px;
}

@media screen and (max-width: 425px) {
  .quotes {
    font-size: 16px;
  }

  .fs-18px-mobile {
    font-size: 18px;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */

* {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Nunito', sans-serif;
}

.tutorial {
  position: fixed;
  left: 10px;
  top: 80px;
  background-color: white;
  padding: 20px;
  padding-left: 50px;
  z-index: 999;
  width: 500px;
  border-radius: 10px;
  box-shadow: 5px 5px 100px black;
  opacity: 1;
}

@media screen and (max-width: 425px) {
  .tutorial {
    width: 100%;
    left: 0;
    top: 60px;
    overflow-y: auto;
  }
}

.arrow img {
  max-width: 50px;
}

.arrow-left {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .promopopup {
    width: auto;
    height: auto;
    max-width: 650px;
    max-height: 450px;
  }
}

/* @media screen and (min-width: 1024px) and (max-width: 1024px) {
  .promopopup {
    width: auto;
    height: auto;
    max-width: 800px;
  }
} */

