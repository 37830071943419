.navbar {
  /*box-shadow: 0px 2px 2px 0px #eaeaea;*/
  background: #000;
  /*padding: 8px;*/
  z-index: 999;
}

.navbar-logo {
	font-size: 25px;
	font-weight: 900;
}
.navbar-link{
	color: #000;
}
.navbarhead:hover{
  background-color: red !important;
}
a.navbar-item,.navbar-item {
  color: #000;
}
.navbar-dropdown .navbar-item {
  color: #000 !important;
}

.navbar.is-black .navbar-end > a.navbar-item:hover{
	border-bottom: 1px solid #ccc;
}
.classTop{
	z-index: 100000;
}
.eng{
	color: #000;
}
.eng:hover{
	color: #000;
}
.box{
	border-radius: 10px;
}
.btnLOgin{
    width: 70%;
    background-color: #f16726;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.btnReset{
    width: 40%;
    background-color: #f16726;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.isHeader{
  border-bottom: 1px solid #ccc;
}
.menulogo:hover{
  background: transparent !important;
  color: #f16726 !important;
}
.menu:hover{
  background: transparent !important;
  border-bottom: 1.5px solid #f16726;
  color: #f16726 !important;
}
.menudropdown:hover{
  background: #fff !important;
}
.menudropdownchild:hover{
 background: #fff !important;
 color:  #000 !important;
}

a.menudropdownchild:hover,a.menudropdown:hover{
  background-color: rgba(251, 127, 80,0.1)  !important;
}
