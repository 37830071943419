.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

/* start text */
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
/* end text */

.border-radius {
  border-radius: 10px;
}

/* start padding */
.p-10px {
  padding: 10px;
}

.p-20px {
  padding: 20px;
}
/* end padding */

/* start border */
.border-black {
  border: 1px solid black;
}
.border-grey {
  border: 1px solid grey;
}
/* end border */

.text-grey {
  color: grey;
}
.text-primary {
  color: #f16726;
}

/* start margin */
.ml-10px {
  margin-left: 10px;
}
.mr-10px {
  margin-right: 10px;
}
.mr-20px {
  margin-right: 20px;
}
.mr-30px {
  margin-right: 30px;
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-10px {
  margin-bottom: 10px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-10px {
  margin-top: 10px;
}
.mt-5px {
  margin-top: 5px;
}
.ml-5px {
  margin-left: 5px;
}
/* end margin */

/* max width */
.mw-100px {
  max-width: 100px;
}
/* end max width */

.cursor-pointer {
  cursor: pointer;
}
@media screen and (max-width: 425px) {
  .mt-200px-mobile {
    margin-top: 200px;
  }
  .mt-250px-mobile {
    margin-top: 250px;
  }
  .mt-350px-mobile {
    margin-top: 350px;
  }
  .mt-400px-mobile {
    margin-top: 400px;
  }
  .mt-100px-mobile {
    margin-top: 100px;
  }
  hr {
    margin: 0.6rem 0;
  }
}
