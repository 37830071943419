.box-ruless{
	width: 100%;
	height: auto;
	margin-top: 10px;
	font-size: 14px;
}
ul.list-rules{
	margin-top: -15px;
}
ul.list-rules li{
	list-style-type: circle;
	margin-left: 25px;
}
.headerModalRules{
	width: 100%;
	font-size: 18px;
	font-weight: bold;
}
.boxRules{
	border-bottom: solid 1px #ccc;
	width: 97% !important;
	margin-left: 10px !important;
	padding-left: 0px !important;
	font-size: 12px !important;
	height: 40px !important;
}
.boxRulesRes{
	width: 97% !important;
	margin-left: 10px !important;
	padding-left: 0px !important;
	font-size: 12px !important;
	height: 40px !important;
}
.iconRules{
	width: 30%;
}
.labelRules{
	width: 70%;
}
.iconRulesRes label{
	margin-left: 10px;
	color: #f36603;
}
.iconRules,.labelRules,.iconRulesRes,.labelRulesRes{
	float: left;
}

/*responsive*/
.rulesDetail{
	/*border-bottom: solid 1px #ccc;*/
	margin-bottom: 0px !important;
}
.iconRulesRes{
	width: 40%;
}
.labelRulesRes{
	width: 60%;
}
.boxModalRules{
	max-height: 1000px !important;
	height: auto !important;
	padding-bottom: 20px !important;
	padding-left: 30px !important;
	padding-right: 35px !important;
}
.desRules{
	margin-top: 10px;
}




  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.box-ruless{
		width: 100%;
		height: auto;
		margin-top: 10px;
		font-size: 12px;
	}
	.boxRules{
		border-bottom: solid 1px #ccc;
		width: 97% !important;
		margin-left: 10px !important;
		padding-left: 0px !important;
		font-size: 12px !important;
		/*height:55px !important;*/
		padding-bottom: 30px !important;
	}
	.boxRulesRes{
		width: 97% !important;
		margin-left: 10px !important;
		padding-left: 0px !important;
		font-size: 12px !important;
		padding-bottom: 30px !important;
		/*height:55px !important;*/
	}
	.iconRules,.labelRules,.iconRulesRes,.labelRulesRes{
		float: left;
	}
	.iconRules,.labelRules,.iconRulesRes,.labelRulesRes{
		width: 50%;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}

  /* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.boxModalRules{
		margin-top: 5px !important;
    	margin-bottom: 5px;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px){
	.boxModalRules{
		margin-top: 5px !important;
    	margin-bottom: 5px;
	}
}
@media only screen and (width: 640px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 731px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 823px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 568px) {
	.boxModalRules{
		margin-top: 200px !important;
    	margin-bottom: 5px;
	}

}
@media only screen and (width: 411px) {
	.boxModalRules{
		margin-top: -100px !important;
    	margin-bottom: 5px;
	}

}