.text-promo-menarik {
  font-size: 36px;
  color: white;
  font-weight: bold;
}

.quotes {
  font-size: 28px;
}
.select select {
  border-radius: 8px;
  border: unset;
}
.select:not(.is-multiple)::after {
  border: 1px solid #f16726;
  border-right: 0;
  border-top: 0;
  content: ' ';
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 0.5em;
  margin-top: -0.375em;
  right: 1.125em;
  top: 50%;
  z-index: 4;
}
.text-white {
  color: white;
}
.hello {
  font-size: 24px;
  color: white;
  font-weight: bold;
}
.bg-gray {
  background-color: #bbbbbb;
}

.text-black {
  color: black;
}

.imgVillasList {
  border-radius: 20px !important;
}

.buttonLoginFacebook {
  background-color: white;
  width: 165px;
  height: 30px;
  margin-bottom: -30px;
  border: none;
  background-image: url('/icon/loginwithfb.png');
  background-size: cover;
  margin-right: 5px;
  background-repeat: no-repeat;
}

.tagVilla {
  margin-top: -4%;
}
.text-spoken {
  margin-top: -10%;
  font-size: 12px;
}
.tanggalKanan {
  margin-left: 10px !important;
}
.circle-image {
  border: 3px solid #7e9cc2;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backAtas {
  position: absolute;
  width: 100%;
  height: 100px;
}
.reset-box {
  z-index: 999;
}
.modal-background-image-galery {
  width: 320px;
  height: 300px;
  margin-top: -350px !important;
}
.imageKategori {
  padding-right: 0.1px !important;
  margin-bottom: -12px !important;
  width: 20% !important;
  float: left;
  z-index: 1;
}
.imageKecil {
  width: 200px;
  height: 85px !important;
  cursor: pointer;
}
.zoom {
  background-color: rgba(1, 1, 1, 0.6);
  width: 100%;
  cursor: pointer;
  z-index: 9999 !important;
  margin-top: -92.5px;
  height: 86px;
  -ms-transform: scale(1); /* IE 9 */
  -webkit-transform: scale(1); /* Safari 3-8 */
  transform: scale(1);
}

/* .active, .imageSmall:hover {

} */
.active,
.zoom:hover {
  cursor: pointer;
  background-color: transparent;
}
.scrool::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}
.scrool::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}
/* Track */

/* Handle */
/*  */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
		background: white;
} */
.scrollImage {
  height: 130px;
  z-index: 9999;
  margin-top: -1px;
  overflow-y: scroll;
}

.discount {
  font-size: 14px;
}
.promopopup {
  width: 1200px;
  height: 600px;
}

.buttonLoginGoogle {
  background-color: white;
  width: 165px;
  height: 30px;
  margin-bottom: -30px;
  border: none;
  background-image: url('/icon/loginwithgoogle.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.buttonLoginFacebook:hover,
.buttonLoginGoogle:hover {
  cursor: pointer;
}
.input-conf {
  width: 300px;
  margin-right: 100px;
}

.closebanner {
  font-size: 20px;
  margin-top: -5px;
  cursor: pointer;
}

.tombol-popup {
  width: 150px;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 1215px) {
  .namavilla {
    width: 190px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 1200px) {
  .namavilla {
    width: 190px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 1000px) {
  .namavilla {
    width: 170px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 940px) {
  .namavilla {
    width: 170px !important;
  }

  .payatvilla {
    width: 95px !important;
  }
}

@media screen and (max-width: 900px) {
  .namavilla {
    width: 150px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 850px) {
  .namavilla {
    width: 130px !important;
  }

  .payatvilla {
    width: 110px !important;
  }
}

@media screen and (max-width: 400px) {
  .booknama {
    width: 120px !important;
  }
  .bookpayat {
    width: 120px !important;
  }
}

@media screen and (max-width: 1200px) {
  .promopopup {
    width: auto !important;
    height: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .input-conf {
    margin-left: 24%;
    margin-right: 0px;
  }

  .promopopup {
    width: auto !important;
    height: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .input-conf {
    margin-left: 23%;
  }
  .desktopsHome {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .input-conf {
    margin-left: 20%;
  }
}

@media screen and (max-width: 550px) {
  .input-conf {
    margin-left: 18%;
  }
}

@media screen and (max-width: 500px) {
  .input-conf {
    margin-left: 15%;
  }
}

@media screen and (max-width: 450px) {
  .input-conf {
    margin-left: 7%;
  }
}

@media screen and (max-width: 400px) {
  .input-conf {
    margin-left: 2%;
  }
}

@media screen and (max-width: 321px) {
  .input-conf {
    width: 250px;
  }
}

.box-button {
  width: 31%;
  margin-left: 69%;
  margin-top: -65px;
}

.searchbox {
  height: 255px;
  /* padding-top: 20px; */
  /*padding-left: 5px;*/
  /*padding-right: 5px;*/
  /* padding-bottom: 20px; */
  width: 45% !important;
  padding: 30px;
  border-radius: 25px !important;
}
.guestImage {
  margin-top: 12px;
  margin-left: 7%;
}
.guestImageHome {
  margin-top: 15px;
  margin-left: 7%;
}
.tamu {
  margin-left: -5%;
  margin-top: 5px;
}

.tamuHome {
  margin-left: -5%;
  margin-top: 8px;
}
.guestImage p {
  margin-top: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #333432;
}
.guestImageHome p {
  margin-top: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #333432;
}

.box-guest {
  border-color: #cfcfcf;
  border-radius: 100px;
  padding-left: 5px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1 !important; /* Firefox */
}

.whitening {
  background-color: white !important;
  font-size: 16px !important;
}

.guesthome {
  border-color: #cfcfcf !important;
  height: 40px !important;
}

.linkto {
  color: blue !important;
}

@media screen and (max-width: 1024px) {
  .noname {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .buttonLoginFacebook {
    background-color: white;
    width: 90%;
    height: 40px;
    margin-bottom: -30px;
    border: none;
    background-image: url('/icon/loginwithfb.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 10%;
  }
  .buttonLoginGoogle {
    background-color: white;
    width: 90%;
    height: 40px;
    margin-bottom: -30px;
    border: none;
    background-image: url('/icon/loginwithgoogle.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 10%;
  }
  .modal {
    background-color: #fff;
  }
  .box {
    border: none;
  }
  .box-login {
    margin-top: -80px;
  }
  .btnCloseLogin {
    z-index: 99999;
  }
  .box-register {
    margin-top: -50px;
  }

  .btnLOginFb,
  .btnLOginGoogle {
    width: 160px;
    background-color: transparent;
    height: 44px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 15px;
  }
  .btnLOginFb {
    background-image: url('/img/tombol fb.png');
    background-size: cover;
    padding: 3px 3px;
    border: 1px solid #000;
  }
  .btnLOginGoogle {
    margin-left: 15px;
    background-image: url('/img/tombol google.png');
    background-size: cover;
    padding: 3px 3px;
    border: 1px solid #000;
  }
}
.button-make-request {
  background: transparent;
  width: 70px;
  height: 70px;
  cursor: pointer;
  position: fixed;
  margin-bottom: 30px;
  margin-right: 35px;
  bottom: 0;
  right: 0;
}

.register {
  margin-bottom: 30px;
  background-color: white;
}
.btnLOgin {
  width: 70%;
  background-color: #f16726;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
.btnPromo {
  width: 100%;
  background-color: #f16726;
  color: white;
  padding: 10px 10px;
  /*margin: 8px 0;*/
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.password-login {
  height: 150px;
}
.labelatau {
  z-index: 11;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.garislogin {
  z-index: 10;
  margin-top: -11px;
  border: 0.5px solid #ccc;
}
.btnLOginFb,
.btnLOginGoogle {
  width: 160px;
  background-color: transparent;
  padding: 12px 10px;
  margin: 8px 0;
  height: 44px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 15px;
}
.btnLOginFb {
  background-image: url('/img/tombol fb.png');
  background-size: cover;
  padding: 3px 3px;
  border: 1px solid #000;
}
.btnLOginGoogle {
  background-image: url('/img/tombol google.png');
  background-size: cover;
  padding: 3px 3px;
  border: 1px solid #000;
}
.labellogin {
  font-size: 14px;
}
.modal {
  z-index: 9991;
}
.input-register {
  width: 260px;
}
.input-login {
  width: 260px;
}
.phone-register {
  width: 100%;
  /*float: right;*/
}
/*.boxPhoneRegister{
	width: 63% !important;
}*/
@media screen and (max-width: 600px) {
  .btnLOginFb,
  .btnLOginGoogle {
    width: 115px;
    background-color: transparent;
    padding: 12px 10px;
    margin: 8px 0;
    height: 30px;
    border: solid 1px gray;
    border-radius: 15px;
    cursor: pointer;
    font-size: 15px;
  }
  .btnLOginFb {
    margin-left: 5px;
    background-image: url('/img/tombol fb.png');
    background-size: cover;
  }
  .btnLOginGoogle {
    margin-left: 5px;
    background-image: url('/img/tombol google.png');
    background-size: cover;
  }
  .btnClose {
    background-image: url('/img/close.png');
    background-size: cover;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    z-index: 9999;
    position: absolute;
    margin-top: 10px;
    margin-right: 10px;
  }
  .box {
    box-shadow: none;
  }
  .input-register {
    width: 100%;
  }
  .input-login {
    width: 100%;
  }
}
.phone {
  width: 190px;
}

.search {
  border-radius: 10px;
  margin-top: 10%;
  width: 30%;
  margin-left: 53%;
  background: #ffffff;
  z-index: 100;
  position: absolute;
}
.select-option2 {
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  border-color: #cfcfcf;
  border-radius: 10px;
  text-align-last: center;
  height: 44px;
  width: 100%;
  box-shadow: none !important;
  border: solid 1px #d8d6d6;
  padding-left: 5px !important;
  text-indent: -5px;
}
.select-option {
  box-shadow: none !important;
  border: solid 1px gray;
  height: 42.5px !important;
  font-size: 15px !important;
}

@media screen and (max-width: 800px) {
  .tagVilla {
    margin-top: -50%;
  }
  .select-option {
    cursor: pointer;
    font-size: 16px;
    background-color: white;
    border-color: #cfcfcf;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 200%;
    text-align-last: center;
    height: 42.5px !important;
    margin-top: -9px !important;
  }
  .payAtTab {
    margin-left: -5px !important;
  }
  .durasi {
    display: none;
  }
  .name-responsive {
    margin-bottom: 1px;
    margin-top: -35px;
  }

  .guesthome {
    border-color: #cfcfcf !important;
    width: 70% !important;
    margin-top: -10px;
    margin-left: 32%;
  }
}
@media screen and (max-width: 800px) {
  .select-option {
    cursor: pointer;
    font-size: 16px;
    background-color: white;
    margin-top: -28%;
    border-radius: 10px;
    /*margin-left: 30%;*/
    width: 90% !important;
    text-align: center;
    text-align-last: center;
    height: 55px;
  }
}
.select-option {
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  border-radius: 10px !important;
  border-color: #cfcfcf;
  width: 100%;
  text-align-last: center;
  height: 40px;
  padding-left: 5px !important;
  text-indent: -5px;
}
.select-option > option {
  padding-top: 10px !important;
}
option.optionGUest {
  padding-top: 50px !important;
}

@media screen and (max-width: 500px) {
  .search {
    border-radius: 5px;
    height: 30%;
    background: #ffffff;
    margin-top: 40%;
    z-index: 100;
    margin-left: 7% !important;
  }
  .searchbox {
    height: 255px;
  }
}

@media screen and (max-width: 800px) {
  .search {
    border-radius: 10px;
    width: 100%;
    height: 30%;
    background: #ffffff;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    margin-top: 35%;
    z-index: 100;
    margin-left: 5.5%;
  }
  /*.search select{
	border-radius: 10px;
	width: 100% !important;
	margin-left: 7px !important;
}*/

  .searchbox {
    height: 255px;
    width: 93% !important;
    margin-bottom: 20px;
  }
}
.text-background {
  border-radius: 10px;
  margin-top: 25%;
  margin-left: 10%;
  background: transparent;
  z-index: 10;
  position: absolute;
}
.text-background2 {
  border-radius: 10px;
  margin-top: 24.2%;
  margin-left: 9.15%;
  background: transparent;
  z-index: 10;
  position: absolute;
}
@media screen and (max-width: 800px) {
  .text-background {
    margin-top: 15%;
  }
  .text-background2 {
    border-radius: 10px;
    margin-left: 6.7%;
    background: transparent;
    z-index: 10;
    position: absolute;
    margin-top: 15%;
  }
}

.text-background p {
  color: white;
  font-size: 36px;
}
.text-background2 p {
  color: white;
  font-size: 36px;
}
@media screen and (max-width: 600px) {
  .text-background p {
    color: white;
    font-size: 18px;
  }
  .text-background2 p {
    color: white;
    font-size: 18px;
  }
}
.box-radius {
  border-radius: 10px;
  height: 40px;
  text-align: center;
  border-color: #cfcfcf;
  font-size: 14;
  padding-right: 5px;
}

@media screen and (max-width: 800px) {
  .box-radius {
    border-radius: 10px;
    width: 55% !important;
    /*margin-left: -2%;*/
    text-align: center;
    font-size: 14;
    padding-right: 13px;
    margin-right: 25px !important;
  }
  .respo {
    font-size: 12px !important;
    margin-top: -32px !important;
    margin-right: -5px !important;
  }

  .emptybox {
    clear: both;
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .emptybox {
    clear: both;
    height: 60px;
  }
}

@media screen and (max-width: 700px) {
  .emptybox {
    clear: both;
    height: 75px;
  }
}

@media screen and (max-width: 590px) {
  .emptybox {
    clear: both;
    height: 85px;
  }
}

@media screen and (max-width: 450px) {
  .emptybox {
    clear: both;
    height: 10px;
  }
}

@media screen and (max-width: 800px) {
  .box-guest {
    border-radius: 10px;
    width: 50% !important;
    height: 40px;
    margin-top: 1px;
    margin-left: -12.5%;
    text-align: center;
    /*padding-left: 13px;*/
  }
  .kolomRespon {
    margin-top: 130px;
  }
}
.box-button {
  border-radius: 8px;
  height: 40px !important;
}
@media screen and (max-width: 800px) {
  .box-button {
    border-radius: 8px;
    width: 100% !important;
    margin-left: -370%;
    margin-top: 5%;
    text-align: center;
    margin-left: 0px;
  }
  .icon-pop {
    margin-top: -10px;
  }
  .price-total {
    display: none;
  }
}
.box-select {
  border-radius: 1000px;
  width: 200px;
}
.text-fasilitas {
  font-size: 12px;
}
.orange {
  color: #ff9900;
  font-size: 18px;
  margin-right: 5px !important;
  margin-left: 5px;
  margin-top: -38px;
  font-weight: bold;
}

.price-responsive {
  font-size: 16px;
  margin-right: 5px !important;
  margin-left: 0px;
  margin-top: -38px;
}
.size {
  font-size: 12px;
  margin-top: 5%;
  margin-left: -50%;
}
.tombol {
  width: 150px;
  height: 40px;
  margin-top: -10px;
  margin-bottom: 18px;
  border-radius: 10px;
}
@media screen and (max-width: 800px) {
  .size {
    margin-left: 4%;
    font-size: 12px;
  }
}
@media screen and (max-width: 360px) {
  .tombol-responsive {
    width: 30%;
    margin-top: -35px !important;
    margin-right: -200px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 360px) {
  .tombol-responsive {
    width: 30%;
    margin-top: -35px !important;
    margin-right: -200px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 800px) {
  .tombol-responsive {
    width: 30%;
    margin-top: -36px;
    margin-right: -200px;
    border-radius: 10px;
  }
  .tombol {
    display: none;
  }
}
.font-file {
  font-size: 14px;
  padding-left: 15px;
}
.font-city {
  font-size: 14px;
  margin-bottom: 20px;
}
.font-label {
  font-size: 12px;
  margin-bottom: 2%;
}
.font-title {
  font-size: 18px;
  margin-bottom: 1%;
}
.font-name {
  font-size: 18px;
  margin-bottom: 1%;
}
.colom {
  margin-right: -15%;
}

.galery {
  height: -50px;
}
.read-more-home {
  color: #f27602;
  font-size: 17px;
  font-weight: bold;
}
.reset-filter {
  color: #4a4a4a;
  font-size: 13px;
  cursor: pointer;
  margin-top: 13px;
}
.read-more-home:hover {
  color: #f16726 !important;
}
.read-more {
  color: #ff9900;
  font-size: 14px;
}
.read-more:hover {
  color: #f16726 !important;
}

.find-more {
  color: white;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.find-more:hover {
  font-weight: bold;
}
.bullet-m {
  height: 25px;
  width: 25px;
  background-color: #ff7d00;
  border-radius: 50%;
  margin-top: -2%;
  display: inline-block;
}
.bullet {
  height: 25px;
  width: 25px;
  background-color: #ff9900;
  border-radius: 50%;
  margin-top: -2%;
  display: inline-block;
}
.bullet-maximal {
  margin-left: -5%;
}
@media screen and (max-width: 800px) {
  .bullet-maximal {
    margin-left: 30px;
    width: 70% !important;
    font-size: 12px;
    margin-top: -47px;
  }
}
.bullet-minnight {
  margin-left: -5%;
}
@media screen and (max-width: 800px) {
  .bullet-minnight {
    margin-left: 190px;
    margin-top: -48px;
    font-size: 12px;
    width: 140px !important;
  }
  .reset-filter {
    color: #4a4a4a;
    font-size: 14px;
    cursor: pointer;
    margin-top: 30px;
    text-align: right;
  }
  .reset-box {
    z-index: 999;
    margin-top: -20px;
  }
  .text-minNight {
    margin-left: 8px;
  }
  .bullet-column {
    width: 140px !important;
    margin-left: 170px;
    margin-top: -42px;
  }
  .bullet-m {
    height: 25px;
    width: 25px;
    background-color: #ff7d00;
    border-radius: 50%;
    display: inline-block;
  }
}
.per-night {
  margin-left: -35px;
}
.info {
  margin-left: -15px;
}
@media screen and (max-width: 900px) {
  .info {
    margin-left: 0px;
  }
}
@media screen and (max-width: 900px) {
  .per-night {
    margin-left: 0px;
  }
}
.price {
  margin-left: 25px;
}
@media screen and (max-width: 800px) {
  .price {
    margin-left: 0px;
  }
}
.image-box {
  margin-top: -33px;
}
.image-detail {
  height: 370px;
  width: 10px;
}
.image-preview {
  height: 400px;
  width: 100px;
}
.view-image {
  margin-left: -10px;
}
.image-detail-bottom {
  height: 100px;
  width: 188px;
  cursor: pointer;
  margin-left: 1px;
}
.image-view-all {
  height: 100px;
  width: 190px;
  z-index: 90;
  cursor: pointer;
  background: black;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=50);
  font-size: 16px;
  margin-left: 569px;
  margin-top: -107px;
}
@media only screen and (max-width: 1100px) {
  .image-view-all {
    height: 100px;
    width: 190px;
    z-index: 90;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
    margin-left: 2px !important;
    margin-top: -107px;
  }
  .text-detail-image {
    background: transparent;
    z-index: 80;
    color: white;
    font-weight: 1000;
    margin-left: 45px !important;
    margin-top: 35px !important;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 16px;
    position: absolute;
  }
}
@media only screen and (max-width: 960px) {
  .image-view-all {
    height: 100px;
    width: 190px;
    z-index: 90;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
    margin-left: 192px !important;
    margin-top: -107px;
  }
  .text-detail-image {
    background: transparent;
    z-index: 80;
    color: white;
    font-weight: bold;
    margin-left: 230px !important;
    margin-top: -70px !important;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 16px;
    position: absolute;
  }
}
.image-detail-bottom-grayscale {
  height: 100px;
  background: white;
  width: 190px;
  cursor: pointer;
  margin-left: 2px;
}

.text-detail-image {
  background: transparent;
  z-index: 80;
  color: white;
  font-weight: 1000;
  margin-left: 610px;
  margin-top: -70px;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-size: 16px;
  position: absolute;
}
.input-reservation2 {
  width: 100% !important;
}
@media screen and (width: 640px),
  (width: 568px),
  (width: 667px),
  (width: 736px),
  (width: 768px) {
  .box-detail-absolute {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    bottom: 0;
    margin-bottom: -1% !important;
    padding: 1.25rem;
    z-index: 100;
  }
  .box-detail {
    position: fixed;
    width: 100%;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -1% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
}
@media screen and (max-width: 768px) {
  .bg-secondary {
    background-color: white;
    border-radius: 13px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 5px !important;
    margin-top: -30px !important;
    padding-top: 10px;
    box-shadow: 1px 1px 10px grey;
  }
  .filter-ls {
    width: 50%;
    float: left;
    margin: 0px;
  }
  .box-list {
    padding-top: 30px !important;
  }
  .input-reservation {
    width: 100% !important;
  }
  .input-reservation2 {
    width: 100% !important;
  }
  .label-down {
    width: 93% !important;
  }
  .box-reservation {
    margin-bottom: 25px;
  }
  .is-input {
    width: 100% !important;
  }
  .box-reservation-detail {
    margin-bottom: 5px !important;
  }
}
.btn-filterls {
  margin-left: 0px;
  height: 42px !important;
}
/* .box-deatil-column{
	position: fixed;
	background: white;
	box-shadow: 0px 2px 4px 1px  #bbbbbb;
	border-radius: 6px;
	padding: 1.25rem;
} */
.box-detail {
  position: fixed;
  width: 30.2% !important;
  background: white;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  box-shadow: 0px 2px 4px 1px #bbbbbb;
  border-radius: 6px;
  padding: 1.25rem;
}
.box-detail-absolute {
  position: absolute;
  background: white;
  box-shadow: 0px 2px 4px 1px #bbbbbb;
  border-radius: 6px;
  padding: 1.25rem;
  z-index: 100;
}

/*form reset password*/
.input-reset {
  width: 305px !important;
}

@media screen and (max-width: 321px) {
  .input-reset {
    width: 250px !important;
  }
}

@media screen and (min-width: 400px) {
  .input-reset {
    margin-left: 5.5%;
  }
}

@media screen and (min-width: 430px) {
  .input-reset {
    margin-left: 9%;
  }
}

@media screen and (min-width: 480px) {
  .input-reset {
    margin-left: 16%;
  }
}

@media screen and (min-width: 530px) {
  .input-reset {
    margin-left: 19%;
  }
}

@media screen and (min-width: 580px) {
  .input-reset {
    margin-left: 23%;
  }
}

@media screen and (min-width: 700px) {
  .input-reset {
    margin-left: 25%;
  }
}

@media screen and (min-width: 769px) {
  .input-reset {
    margin-left: 0%;
  }
}

.btnReset {
  width: 30%;
  background-color: #f16726;
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}
/*form reset password*/

/*forgot*/
a.link-forgot {
  color: #f7c25e !important;
}
a.link-forgot:hover {
  color: #f16726 !important;
}

/* form reservation */
.box-reservation {
  border: solid 1px #e6e6e6;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: -25px;
}
.box-reservation-detail {
  border: solid 1px #e6e6e6;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: -25px;
  margin-bottom: 50px;
}
.is-input {
  width: 50%;
}
.label-down {
  color: #bdbdbd;
  font-size: 10px;
  padding-top: 5px;
  font-weight: 100;
}
.title-request {
  font-weight: bold;
  font-size: 18px;
}
table.detail-reser tr td {
  padding-bottom: 10px !important;
  font-size: 14px !important;
}

.detail-reser2 {
  width: 67%;
}

table.detail-reser2 tr td {
  padding-bottom: 5px !important;
  font-size: 14px !important;
  text-align: justify;
}

table.facilitytable tr td {
  padding-bottom: 10px !important;
}

.btnCheckout {
  width: 100%;
  background-color: #f16726;
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}
.list-promo {
  padding-top: 10px;
  padding-bottom: -10px;
}
.input-select {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background-color: red;
  overflow: hidden;
}

.switchs {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switchs input {
  display: none;
}

.sliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliders:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input[name='sliders']:checked + .sliders {
  background-color: #f16726;
}

input[name='sliders']:focus + .sliders {
  box-shadow: 0 0 1px #2196f3;
}

input[name='sliders']:checked + .sliders:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliders.round {
  border-radius: 34px;
}
.filed-input {
  background: white !important;
  color: black !important;
  border: 2px solid #e5e5e6 !important;
  border-radius: 5px;
}
.filed {
  background: white !important;
  border: 2px solid #e5e5e6 !important;
  color: black !important;
  border-radius: 5px;
}
.filed-input-popup {
  background: white !important;
  color: black !important;
  border: 2px solid #e5e5e6 !important;
  border-radius: 5px;
}
.filed-popup {
  background: white !important;
  color: black !important;
  border: 2px solid #e5e5e6 !important;
  border-radius: 5px;
}

.sliders.round:before {
  border-radius: 50%;
}

.promo-nonactive {
  /*display: none;*/
  opacity: 0;
  position: absolute;
  z-index: -10;
}
.promo-active {
  /*display: block;*/
  opacity: 10;
  position: static;
  z-index: 0;
}
span.panah-bawah {
  background-image: url('/icon/panah-bawah.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin-left: 10px;
}
span.panah-atas {
  background-image: url('/panah-atas.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  margin-left: 10px;
}
.request-active {
  display: block;
}
.request-nonactive {
  display: none;
}
.box-request {
  cursor: pointer;
}
.panah-request-aktif {
  margin-left: 10px;
  margin-bottom: -2px;
  opacity: 10;
  z-index: 2;
  cursor: pointer;
}
.panah-request-nonaktif {
  margin-left: 10px;
  margin-bottom: -2px;
  opacity: 0;
  z-index: 1;
  margin-left: -18px;
  cursor: pointer;
}
/* form reservation */

/* cahnge password profile*/

.btnCahngePass {
  border-radius: 10px;
  width: 200px;
  padding-top: 8px;
  padding-bottom: 26px;
  font-size: 12px;
}

@media screen and (max-width: 769px) {
  table.detail-reser2 tr td {
    padding-bottom: 5px !important;
    font-size: 12px !important;
  }
  .detail-reser2 {
    width: 100% !important;
  }

  .tablemobile {
    margin-top: 50px !important;
    margin-bottom: 5px !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .discount {
    font-size: 11px;
  }
  .find-more {
    font-size: 9px;
    margin-left: 3px;
  }
  .closebanner {
    font-size: 14px;
    margin-top: 1px;
  }
  .labelbanner {
    margin-top: -1px;
  }
}

@media screen and (max-width: 320px) {
  .labelbanner {
    margin-top: -2px;
  }
  .modal-background-image-galery {
    width: 300px !important;
    height: 300px !important;
    margin-top: -280px !important;
  }
}

@media screen and (max-width: 350px) {
  .discount {
    font-size: 10px;
  }
  .find-more {
    font-size: 7px;
  }
  .closebanner {
    font-size: 10px;
    margin-top: 6px !important;
  }
}

/* cahnge password profile*/

@media only screen and (width: 768px) {
  .box-button {
    border-radius: 8px;
    width: 100% !important;
    margin-left: -370%;
    margin-top: 3%;
    text-align: center;
    margin-left: 0px;
  }
  .tagVilla {
    margin-top: -25%;
  }
  .box-detail-absolute {
    position: fixed;
    width: 100%;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -10% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .scrollImage {
    height: 280px;
    overflow-y: auto;
    width: 100%;
    z-index: 9999;
    position: relative;
    background-color: transparent !important;
  }
  .box-detail {
    position: fixed;
    width: 100%;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -1% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
}
@media only screen and (min-width: 800px) {
  .respo {
    display: none;
  }
  .tes-modal {
    display: none !important;
  }
  .mapJarak {
    margin-bottom: 0px;
  }

  .iconMinimal {
    padding-left: 40px !important;
  }
  .responsive {
    display: none;
  }
  .responsive-guest {
    display: none;
  }
  .modal-background-image {
    width: 100px !important;
    height: 800px !important;
    margin-top: 300px;
    padding-top: 320px !important;
  }
  .modal-background-image-galery {
    width: 90% !important;
    height: 800px !important;
    margin-left: 90px;
    margin-top: 300px;
    padding-top: 320px !important;
  }
  .desktop {
    padding: 2px !important;
    padding-right: 20px;
  }
  .icon-responsive {
    margin-top: -1% !important;
    margin-left: 1px !important;
  }
  .orange {
    color: #ff9900;
    font-size: 16px;
    margin-right: 5px !important;
    margin-top: 0px !important ;
    font-weight: bold;
  }
  .price-total {
    color: #ff9900;
    font-size: 16px;
    margin-right: 5px !important;
    margin-top: 0px !important ;
    font-weight: bold;
  }
  .price-responsive {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .line {
    margin-top: -4%;
  }
  .line-2 {
    margin-top: 0px;
  }
  .fasilitas {
    margin-top: -3%;
  }
  .map-responsive {
    margin-top: -2.5%;
  }
  .name-responsive {
    display: none;
  }
  .filed-text {
    display: none;
  }
  .text-detail-image-responsive {
    display: none;
  }
  .image-view-all-responsive {
    display: none;
  }
  .image-detail-bottom-responsive {
    display: none;
  }
  .image-detail-bottom-grayscale-responsive {
    display: none;
  }
  .tombol-responsive {
    display: none;
  }
  .box-text {
    display: none;
  }
  .modal-content-book {
    display: none;
  }
}
@media only screen and (max-width: 320px) {
  .image-detail-bottom-responsive {
    height: 50px;
    width: 88px !important;
    cursor: pointer;
    margin-left: 1px;
  }
  .boxStickyMobile {
    display: block;
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 105%;
    z-index: 999;
    border: solid 1px #ccc;
    margin-left: -25px !important;
    background-color: white;
  }
  .box-detail-absolute {
    position: fixed;
    width: 100% !important;
    height: 100px !important;
    margin-left: -25px;
    background: white;
    bottom: 0;
    margin-bottom: -15% !important;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .image-view-all-responsive {
    height: 50px;
    width: 89px !important;
    z-index: 90;
    margin-top: -57px;
    margin-left: 179px !important;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 88px !important;
    cursor: pointer;
    z-index: 2;
    margin-left: 2px;
  }
  .text-detail-image-responsive {
    color: black;
    font-size: 10px;
    margin-top: -40px !important;
    margin-left: 188px !important;
    z-index: 1 !important;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
}
@media only screen and (max-width: 800px) {
  /* .box-fix{
		position: fixed !important;
		bottom: 0;
		z-index: 100;
} */
  .popup {
    width: 15px;
  }
  .total-popup {
    color: #ff9900;
    font-weight: bold;
  }
  .box-detail {
    position: fixed;
    width: 100% !important;
    height: 100px !important;
    margin-left: -12px;
    background: white;
    bottom: 0;
    border: 1.5px solid #d3d3d3;
    margin-bottom: -11%;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .desktop {
    display: none;
  }
  .desktop-fasil {
    display: none;
  }
  .responsive {
    padding: 1px;
    padding-left: 10px;
  }
  .responsive-guest {
    padding: 1px;
    padding-left: 10px;
    margin-top: -10%;
  }

  .box-detail-absolute {
    position: fixed;
    width: 100%;
    height: 100px !important;
    margin-left: -12px;
    background: white;
    border: 1.5px solid #d3d3d3;
    bottom: 0;
    margin-bottom: -11%;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .box-detail-popup {
    width: 100%;
    background: white;
    box-shadow: 0px 2px 4px 1px #bbbbbb;
    border-radius: 6px;
    padding: 1.25rem;
    z-index: 100;
  }
  .font-file {
    display: none;
  }
  .filed-text {
    font-size: 10px;
    margin-top: -40px;
    margin-left: 0px;
    font-weight: bold;
  }
  .icon-responsive {
    margin-top: -35px;
    margin-left: 10px;
  }

  .filed-input {
    display: none;
  }
  .filed {
    display: none;
  }
  .font-city {
    display: none;
  }
  .normal {
    display: none;
  }
  /* .image-box{
	display: none;
} */
  .image-view-all-responsive {
    height: 50px;
    width: 100px;
    z-index: 90;
    margin-top: -57px;
    margin-left: 205px;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .text-detail-image {
    background: transparent;
    color: white;
    font-weight: bold;
    margin-left: 40px;
    margin-top: 40px;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-size: 16px;
    position: absolute;
  }
  .text-fasilitas {
    font-size: 12px;
  }
  .font-name {
    display: none;
  }
  .tombol-popup {
    width: 150px;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .orange-popup {
    font-size: 14px;
    margin-right: 5px !important;
    margin-top: 0px !important ;
  }
  .orange-total-popup {
    color: #ff9900;
    margin-top: -16px;
    margin-bottom: -10px;
    font-size: 16px;
    font-weight: bold;
  }
  .modal-background-image {
    width: 320px !important;
    height: 300px !important;
    margin-top: -100px !important;
  }
  .modal-background-image-galery {
    width: 320px;
    height: 300px;
    margin-top: -150px !important;
  }
  .imageKecil {
    width: 80px;
    height: 30px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -37px;
    height: 31px;
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
  .image-detail-bottom {
    height: 50px;
    width: 100px;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-detail-bottom-responsive {
    height: 50px;
    width: 100px;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 100px;
    cursor: pointer;
    z-index: 1;
    margin-left: 2px;
  }
  .image-detail-bottom-grayscale {
    height: 50px;
    background: white;
    width: 100px;
    cursor: pointer;
    margin-left: 2px;
  }
  .image-box {
    display: none;
  }
  .image-respon {
    margin-top: -33px;
  }
  .text-detail-image-responsive {
    color: white;
    font-size: 10px;
    margin-top: -40px;
    margin-left: 220px;
    z-index: 100;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
  .image-detail {
    height: 250px;
    width: 10px;
  }
  .line {
    margin-top: -9%;
  }
  .line-2 {
    margin-top: -4%;
  }
  .fasilitas {
    margin-top: -60px;
  }
  .map-responsive {
    margin-top: -50px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 375px) {
  .image-detail-bottom-responsive {
    height: 50px;
    width: 105px !important;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-view-all-responsive {
    height: 50px;
    width: 108px !important;
    z-index: 90;
    margin-top: -57px;
    margin-left: 210px !important;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 105px !important;
    cursor: pointer;
    z-index: 1;
    margin-left: 2px;
  }
  .text-detail-image-responsive {
    color: white;
    font-size: 10px;
    margin-top: -40px !important;
    margin-left: 230px !important;
    z-index: 2;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
}
@media only screen and (min-width: 414px), (min-width: 411px) {
  .image-detail-bottom-responsive {
    height: 50px;
    width: 118px !important;
    cursor: pointer;
    margin-left: 1px;
  }
  .image-view-all-responsive {
    height: 50px;
    width: 118px !important;
    z-index: 90;
    margin-top: -57px;
    margin-left: 240px !important;
    cursor: pointer;
    background: black;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);
    font-size: 16px;
  }
  .image-detail-bottom-grayscale-responsive {
    height: 50px;
    width: 118px !important;
    cursor: pointer;
    z-index: 1;
    margin-left: 2px;
  }
  .text-detail-image-responsive {
    color: white;
    font-size: 10px;
    margin-top: -40px !important;
    margin-left: 265px !important;
    z-index: 2;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    font-size: 10px;
  }
}
.durasi {
  font-size: 16px;
}
.icon-pop {
  margin-top: -21px;
}

.boxSticky {
  position: fixed;
  width: 30%;
  height: auto;
  top: 93px;
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 10px;
  display: block;
}
.boxStickynot {
  position: fixed;
  width: 30%;
  height: auto;
  top: 125px;
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 10px;
  display: block;
}
.boxStickyMobile {
  display: none;
}
.labelFasilitas {
  margin-bottom: 10px !important;
}

@media screen and (max-width: 800px) {
  .boxSticky {
    display: none;
  }
  .boxStickynot {
    display: none;
  }
  .boxStickyMobile {
    display: block;
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 105%;
    z-index: 999;
    border: solid 1px #ccc;
    margin-left: -25px !important;
    background-color: white;
  }
  .column-price,
  .column-buttom {
    float: left;
    padding-top: 12px;
  }
  .column-price {
    width: 60%;
    padding-left: 15px;
    font-size: 10px;
  }

  .column-price label {
    font-weight: bold;
    font-size: 14px;
  }
  .column-buttom {
    width: 40%;
    text-align: center;
  }
  .column-buttom button {
    margin-right: 10%;
    border-radius: 10px;
    width: 100px;
  }
  .column-label label {
    font-weight: bold;
    font-size: 10px !important;
  }
  .iconImg img {
    margin-top: 10px !important;
  }
  .mapJarak {
    margin-bottom: -100px;
  }
  .payAt {
    border: 1px solid !important;
    border-radius: 5px !important;
    padding: 2px;
    width: 90px;
    color: white;
    background-color: #f36603;
    font-size: 13px;
  }
}
@media only screen and (min-width: 768px) {
  .responsive-guest {
    padding: 1px;
    padding-left: 10px;
    margin-top: -5%;
  }
  .mobilesHome {
    display: none;
  }
  .line {
    margin-top: -4% !important;
  }
}

/* responsive phone and tablet */
@media only screen and (width: 768px) {
  .boxStickyMobile {
    display: block;
    position: fixed;
    bottom: 0;
    height: 80px;
    width: 105%;
    z-index: 999;
    border: solid 1px #ccc;
    margin-left: -25px !important;
    background-color: white;
  }
  .reset-filter {
    color: #4a4a4a;
    font-size: 14px;
    cursor: pointer;
    margin-top: 30px;
  }
  .box-list {
    padding-top: 50px !important;
  }
  .column-label label {
    font-weight: bold;
    font-size: 14px !important;
  }
  .payAtTab {
    margin-top: 28px;
    margin-left: -5px !important;
  }
  .column-price,
  .column-buttom {
    float: left;
    padding-top: 6px;
  }
  .column-label {
    margin-top: -5px;
  }
  .column-price {
    width: 60%;
    /*margin-top: 10px !important;*/
    padding-left: 15px;
    font-size: 18px;
  }
  .column-price label {
    font-weight: bold;
    font-size: 16px;
  }
  .column-buttom {
    width: 40%;
    text-align: center;
  }
  .column-buttom button {
    margin-right: 10%;
    margin-top: 15px !important;
    border-radius: 10px;
    width: 200px;
  }
  .lbl-duration {
    font-weight: bold;
    font-size: 13px !important;
  }
  .lblPrice {
    font-weight: bold;
    font-size: 20px !important;
  }
  .iconImg img {
    margin-top: 10px !important;
  }
  .font-name-popup {
    margin-top: 30px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
}

/* responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .boxOptionPay {
    margin-top: -40px;
  }
  .jarak .colum {
    margin-right: 0px !important;
  }

  .search select {
    border-radius: 10px;
    /* width: 93% !important; */
    /* margin-left: -15px !important; */
  }
  div.DateRangePicker,
  .DateRangePickerInput {
    width: 93% !important;
    text-align: center !important;
  }
  .phoneCode {
    width: 100% !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .phone-register-profile,
  .inputbox {
    height: 36px !important;
  }
  .imgVillasList {
    height: 220px !important;
    border-radius: 30px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  .scrollImage {
    height: 280px;
    overflow-y: auto;
    width: 100% !important;
    z-index: 9999;
    background-color: transparent !important;
    position: relative;
    margin-top: 12px;
  }
  .column-conf-bottom {
    padding-bottom: 20px;
  }
  .labelFasilitas {
    margin-bottom: 20px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .boxOptionPay {
    margin-top: -40px;
  }
  .boxListVillas {
    width: 50% !important;
    float: left;
  }
  .boxListVillas > .boxheight {
    /*background-color: red !important;*/
    /*height: 320px !important;*/
  }
  .search select {
    border-radius: 10px;
    width: 100% !important;
    margin-left: 7px !important;
    border: unset;
  }
  .input-login,
  .input-register {
    width: 100% !important;
  }
  .DateRangePickerInput {
    width: 100% !important;
    text-align: center !important;
  }
  .DateRangePicker {
    width: 100% !important;
  }
  div.kalenderHome > div.DateRangePicker {
    width: 92% !important;
  }
  .phoneCode {
    width: 100% !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .boxPhoneRegister {
    width: 100% !important;
  }
  .phone-register-profile,
  .inputbox {
    height: 36px !important;
  }
  .imgVillasList {
    height: 220px !important;
  }
  .DateRangePicker_picker {
    z-index: 10 !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  .labelFasilitas {
    margin-bottom: 20px !important;
  }
}

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .boxOptionPay {
    margin-top: -40px;
  }
  div.DateRangePicker,
  .DateRangePickerInput {
    width: 93% !important;
  }
  div.hotelList > div.DateRangePicker,
  .DateRangePickerInput {
    width: 100% !important;
    text-align: center !important;
  }
  .boxPhoneRegister {
    width: 100% !important;
  }
  .boxListVillas {
    width: 50% !important;
    float: left;
  }
  .payAt {
    border: 1px solid !important;
    border-radius: 5px !important;
    padding: 2px;
    color: white;
    width: 90px;
    background-color: #f36603;
    font-size: 14px;
  }
  .boxListVillas > .boxheight {
    /*background-color: blue !important;*/
    height: 400px !important;
  }
  .imgVillasList {
    height: 250px !important;
  }
  .icon {
    width: 20px !important;
  }
  .iconRangeBox {
    margin-right: 7px !important;
  }
  .iconImageBox {
    width: 25px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .boxOptionPay {
    margin-top: 10px;
  }
  .search select {
    border-radius: 10px;
    width: 100% !important;
    margin-left: 0px !important;
  }
  .phoneCode {
    width: 100% !important;
  }
  .phone-register {
    width: 100% !important;
  }
  .boxPhoneRegister {
    width: 63% !important;
  }
  .boxPhoneProfile,
  .codePhoneProfile,
  .phone-register-profile {
    width: 100% !important;
  }
  .inputRegister {
    width: 63% !important;
  }
  .phone-register-profile,
  .inputbox {
    height: 36px !important;
  }
  .boxListVillas {
    width: 25% !important;
    float: left;
  }
  .boxListVillas > .boxheight {
    /*background-color: green !important;*/
    height: 320px !important;
  }
  .imgVillasList {
    height: 210px !important;
  }
  .iconRangeBox {
    margin-right: 7px !important;
  }
  .iconImageBox {
    width: 25px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .boxOptionPay {
    margin-top: 10px;
  }
  .boxListVillas {
    width: 25% !important;
    float: left;
  }
  .boxListVillas > .boxheight {
    /*background-color: gray !important;*/
    height: 345px !important;
  }
  .imgVillasList {
    height: 210px !important;
  }
  .iconRangeBox {
    margin-right: 7px !important;
  }
  .iconImageBox {
    width: 25px !important;
  }
  .labelOptionPayment {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .labelQuestion {
    border: 1px solid black;
    color: black;
    padding: 2px 2px;
    height: 20px;
    width: 20px !important;
    font-size: 20px;
    margin-bottom: -2px;
    margin-left: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  .labelFasilitas {
    margin-bottom: 10px !important;
  }
}

/* style option payment */
/* The container */
.containerpay {
  display: block;
  position: relative;
  /*padding-left: 35px;*/
  margin-bottom: 12px;
  cursor: default;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}
.containerpaydisabled {
  display: block;
  position: relative;
  /*padding-left: 35px;*/
  margin-bottom: 12px;
  cursor: default;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}
.containerpaydisabled label {
  margin-left: 25px;
  font-size: 14px;
  font-weight: bold;
  margin-top: -10px;
  color: #ccc;
}
.containerpay label {
  margin-left: 25px;
  font-size: 14px;
  font-weight: bold;
  margin-top: -10px;
}
.deskripless {
  font-size: 12px;
  cursor: text;
  margin-top: -5px;
}
.deskriplessdisabled {
  font-size: 12px;
  cursor: text;
  color: #ccc;
  margin-top: -5px;
}
.containerpaydisabled p {
  margin-left: 25px;
  font-size: 12px;
  color: #ccc;
  cursor: text;
}
.labelPrice {
  font-size: 14px;
  float: right;
}
.labelPriceDisabled {
  font-size: 14px;
  float: right;
  color: #ccc;
}
.deskripPayReservation {
  font-size: 12px;
  color: #000;
}
.boxOptionPay {
  background-color: #fff;
  padding: 40px 25px;
}
.labelDesOptionPay {
  font-size: 12px;
  font-weight: bold;
}
/* Hide the browser's default radio button */
.containerpay input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.containerpaydisabled input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkpay {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #ccc;
  cursor: pointer;
}
.checkmarkpaydisabled {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  border: none;
  cursor: not-allowed;
}

/* On mouse-over, add a grey background color */
/*.checkmarkpay:hover{
	background-color: #ccc;
}*/

/* When the radio button is checked, add a blue background */
.containerpay input:checked ~ .checkmarkpay {
  background-color: #f16726;
}

.containerpaydisabled:hover {
  background-color: transparent;
}
.labeldisable,
.labeldisable:hover {
  cursor: text !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkpay:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerpay input:checked ~ .checkmarkpay:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerpay .checkmarkpay:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/* style option payment */

/* text-image */
.text-image {
  margin-left: 10px;
  width: 19% !important;
  margin-top: 10px;
  height: 35px;
  background: rgba(0, 0, 0, 0.8);
}
.text-image p {
  font-size: 12px;
  margin-top: -2.2%;
  color: white;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .text-image {
    margin-left: 13px;
    width: 30% !important;
    margin-top: 22px !important;
    background: rgba(0, 0, 0, 0.9);
  }
  .text-image p {
    font-size: 12px;
    margin-top: -4%;
    color: white;
    font-weight: bold;
  }
}
@media screen and (width: 768px) {
  .text-image {
    margin-left: 13px;
    width: 20% !important;
    margin-top: 22px !important;
    background: rgba(0, 0, 0, 0.9);
  }
  .modal-background-image-galery {
    width: 90% !important;
    height: 300px;
    margin-top: -400px !important;
  }
  .text-image p {
    font-size: 14px;
    margin-top: -5%;
    color: white;
    font-weight: bold;
  }
  .imageKecil {
    width: 100%;
    height: 75px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -83px;
    height: 76px;
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
}

@media screen and (width: 1024px) {
  .imageKecil {
    width: 100%;
    height: 65px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -73px;
    height: 66px;
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
}

@media screen and (height: 768px) {
  .text-image {
    margin-left: 13px;
    width: 20% !important;
    margin-top: 22px !important;
    background: rgba(0, 0, 0, 0.9);
  }
  .modal-background-image-galery {
    width: 90% !important;
    height: 300px;
    margin-left: 20px !important;
    margin-top: -400px !important;
  }
  .text-image p {
    font-size: 14px;
    margin-top: -5%;
    color: white;
    font-weight: bold;
  }
  .imageKecil {
    width: 100%;
    height: 100px !important;
    cursor: pointer;
  }
  .zoom {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    cursor: pointer;
    z-index: 9999 !important;
    margin-top: -107px;
    height: 100px;
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari 3-8 */
    transform: scale(1);
  }
  .active,
  .zoom:hover {
    cursor: pointer;
    background-color: transparent;
  }
  .scrollImage {
    height: 280px;
    overflow-y: auto;
    width: 75% !important;
    z-index: 9999;
    position: relative;
    background-color: transparent !important;
  }
}
/*  PAY AT SIMILAR*/
@media only screen and (max-width: 600px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .payAtVilla180913 {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*-----------------------------------------------------------------------------------------*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 150px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 731px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 375px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 160px !important;
    margin-top: 20px;
  }
}
/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 414px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px !important;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 411px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 200px !important;
    margin-top: 20px;
  }
}

/*-------------------------------------------------*/

/* othe screen*/

@media only screen and (width: 823px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 812*/

@media only screen and (width: 812px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 768*/

@media only screen and (width: 768px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 220px !important;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1024*/

@media only screen and (width: 1024px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 100px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 1366*/

@media only screen and (width: 1366px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 155px;
    margin-top: 20px;
  }
}

/*--------------------------------------*/

/* other screen 885*/

@media only screen and (width: 885px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-right: 35px !important;
    right: 0;
    margin-top: 20px;
  }
}
@media only screen and (width: 320px) {
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 100px !important;
    margin-top: 20px;
  }
}

/* PAY AT SIMILAR */

.select .select-kota {
  width: 100% !important;
  margin-left: auto !important;
}

.fs-24px {
  font-size: 24px;
}

@media screen and (max-width: 425px) {
  .quotes {
    font-size: 16px;
  }

  .fs-18px-mobile {
    font-size: 18px;
  }
}
