.tourist-attraction-img {
  border-radius: 30px;
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.border-radius-30px {
  border-radius: 30px;
}

.sectionHehe {
  padding: 0;
}
.h1Title {
  font-size: 36px;
  color: white;
  font-weight: bold;
}

.h2Title {
  font-size: 14px;
  color: white;
  padding-top: 15px;
}

.explore-btn {
  margin-top: 25px;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: flex-end;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: white;
}

/* selected link */
a:active {
  color: white;
}

.facilitybox {
  padding: 20px;
  height: auto;
  background-color: white;
  border-radius: 10px;
}

.facilities {
  margin-top: 0px;
}

.promobanner {
  z-index: -1;
}

.forbanner {
  height: 30px;
  padding-top: 3px;
}

.forbanner2 {
  height: 31px;
}
.payvilla {
  font-size: 12px !important;
  border-color: #f16726;
  background-color: #f16726;
  border-radius: 12px;
  width: 110px;
  height: 25px;
  font-weight: bold;
  padding-top: 4px;
}

.cottagepic {
  margin-top: -16px;
  height: 12px;
  width: 12px;
}

.phonenumber {
  margin-top: 7px !important;
}

.changepass {
  font-size: 12px;
}

.editprof {
  font-size: 12px;
}

.circlesmobile {
  margin-left: -11%;
}

.kolomkotanama {
  cursor: pointer;
}

.images-galery {
  cursor: pointer;
}

.kolomharga {
  cursor: pointer;
}

.section2 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  padding-right: 0rem !important;
  padding-left: 0.6rem !important;
}
.section2Home {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-right: 0rem !important;
  padding-left: 0.6rem !important;
}

.icon {
  width: 28px;
  width: 28px;
}

.downloadbutton {
  border-radius: 5px;
  width: 130px;
  height: 34px;
  margin-top: 4.5px;
  font-size: 12px;
}

.nobookfound {
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: bold;
}

.bookdetailmobile {
  display: none;
}

.item:hover {
  background-color: rgba(247, 194, 94, 0.6);
}

.bookhistory:hover {
  background-color: white;
}

.firstparagraph {
  margin-left: 30px;
  margin-top: 2.7%;
  margin-bottom: 30px;
  text-align: justify;
}

.firstpict {
  margin-right: 30px;
}

.secondparagraph {
  margin-left: 30px;
  margin-bottom: 10px;
  text-align: justify;
}

.title-about {
  font-size: 36px;
  font-weight: 1000;
  margin-top: 15px;
  margin-bottom: 30px;
}
.boxSimilar .slick-track {
  margin-left: 0px !important;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  margin-left: 30px;
}

.linemobile2 {
  display: none;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.circles {
  width: 100px;
  margin-top: -12px;
}

.villaname {
  font-size: 14px;
}

.loccheck {
  margin-top: 1%;
}

@media screen and (max-width: 1408px) {
  .firstparagraph {
    margin-left: 30px;
    margin-top: 0.6%;
    margin-bottom: 30px;
    text-align: justify;
  }
}

@media screen and (max-width: 1215px) {
  .firstparagraph {
    margin-left: 20px;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: justify;
  }
  .firstpict {
    margin-right: 20px;
  }

  .secondparagraph {
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: justify;
  }
  .Aligner-item {
    margin-left: 20px;
  }
}

@media screen and (max-width: 921px) {
  .title-about {
    font-size: 32px;
  }
}

@media screen and (max-width: 900px) {
  .displayempty {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .title-about {
    font-size: 28px;
  }
}

@media screen and (max-width: 600px) {
  .facilitybox {
    padding: 0px;
    padding-top: 120px;
    padding-left: 15px;
    height: auto;
  }
  .boxSimilar .box {
    padding: 0px;
    margin-left: 1px;
  }
  .boxSlide {
    padding: 0px !important;
    z-index: 9999 !important;
  }
  .gambarSimilar {
    height: 160px !important;
  }
  .facilities {
    margin-top: -120px !important;
    padding-top: 0px;
  }
}

@media screen and (max-width: 330px) {
  .facilitybox {
    padding-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .mobiles {
    display: none;
  }
  .desktopsTeks {
    margin-top: -80px !important;
  }
  .payAtVillaSimilar {
    font-size: 12px !important;
    border-color: #f16726;
    background-color: #f16726;
    border-radius: 12px;
    width: 110px;
    height: 25px;
    font-weight: bold;
    padding-top: 4px;
    position: absolute;
    z-index: 9;
    margin-left: 140px !important;
    margin-top: 20px;
  }
  .desktops {
    width: 105% !important;
    margin-left: -2px !important;
  }

  .kolomeventnama {
    margin-left: 0px;
    margin-right: 0px;
    background: rgba(0, 0, 0, 0.8);
    margin-top: -74px;
    height: 45px;
    z-index: 9 !important;
    position: relative;
  }

  .gambarSimilar {
    height: 144px;
    width: 500px;
    cursor: pointer;
  }
  .aboutpage {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
  }

  .cottagepic {
    margin-top: -17px;
    height: 8px;
    width: 8px;
  }

  .firstpict {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .firstparagraph {
    margin-left: 0px;
    padding-top: 0px;
    margin-bottom: 15px;
  }

  .secondparagraph {
    margin-left: 0px;
  }

  .title-about {
    margin-top: 20px;
    font-size: 25px;
  }

  .Aligner-item {
    margin-left: 0px;
  }

  .passconfr {
    padding-top: 7px;
  }
  .forbanner {
    height: 25px;
    padding-top: 0px;
  }
  .forbanner2 {
    height: 26px;
  }
}

@media screen and (max-width: 518px) {
  .title-about {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .displaymobile {
    display: none;
  }
  .linemobile2 {
    display: block;
  }
  .mobiles {
    display: block;
  }
  .kolomeventnama {
    margin-left: 0px;
    margin-right: 0px;
    background: rgba(0, 0, 0, 0.8);
    margin-top: -58px !important;
    z-index: 9 !important;
    position: relative;
  }
  .boxSimilar .box {
    width: 100%;
  }

  .desktops {
    display: none;
  }
  .desktopsTeks {
    display: none;
  }
  .gambarSimilar {
    height: 144px;
    width: 500px;
    cursor: pointer;
  }
}

.paginationmargin {
  margin-top: 100px;
  margin-bottom: -25px;
}

.boxheight {
  height: 100%;
}

.inputatas {
  margin-top: -80px;
  margin-bottom: -50px;
}

.tengahexplore {
  width: 160%;
  height: 40px;
}
.tengahexplorefilter {
  width: 60%;
  height: 40px;
}

.tengahexplore:hover {
  color: #fff;
}

.tengahexplorefilter:hover {
  color: #fff;
}

.whitedate {
  background-color: white !important;
}

.isrounded {
  border-radius: 8px;
}

.kolommobile {
  margin-left: -15px;
}

.ratatengah {
  text-align: center;
  text-align-last: center;
}

.tengahtanggal {
  width: 100%;
  height: 40px;
}

.tengah {
  height: 50px;
  line-height: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1 !important; /* Firefox */
}

.kolomkotanama {
  margin-left: 5px;
  margin-right: 5px;
}
.kolomkalender {
  margin-top: -25px;
  margin-left: 5px;
  margin-right: 5px;
}
.kolomeventnama {
  margin-left: 0px;
  margin-right: 0px;
  background: rgba(0, 0, 0, 0.8);
  margin-top: -58px;
  z-index: 9 !important;
  height: 36px;
  position: relative;
}
.namaevent {
  z-index: 10 !important;
  color: #f1f2f3;
  float: left;
  margin-top: -5px;
  font-size: 14px;
}

.kolomkota {
  margin-bottom: 10px;
  margin-top: -10px;
}

.kolomharga {
  margin-top: -10px;
  margin-bottom: 20px;
}

.pagee {
  margin-bottom: -70px;
  margin-top: -45px;
}

.centerpagination {
  justify-content: center !important;
}

.myaccountbox {
  box-shadow: 0.5px -0.5px 5px 0px #cfcfcf;
  margin-right: 50px;
  height: 180px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  overflow: hidden;
  margin-left: 7px;
}

.accountsettingbox {
  box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-left: -25px;
  width: 64% !important;
  /*overflow: hidden;*/
}

.activebook {
  box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-left: -25px;
  width: 64% !important;
  overflow: hidden;
}

.emptybooking {
  margin-right: 50px;
  height: 180px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-top: 35px;
}

.bookhistory {
  box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-left: -25px;
  margin-top: 35px;
  width: 64% !important;
  overflow: hidden;
  background-color: #eeee;
}

.activated {
  background-color: rgba(247, 194, 94, 0.6);
  font-weight: bold;
}

.boxes {
  padding-left: 30px !important;
  width: 150% !important;
}

.primarybutton {
  border-radius: 10px;
  width: 200px;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 12px;
}

.buttons {
  display: -webkit-flex; /* Safari */
  -webkit-justify-content: flex-end; /* Safari 6.1+ */
  display: flex;
  justify-content: flex-end;
}

.mobileprof {
  padding-bottom: 425px;
}

.mobileprof2 {
  padding-bottom: 180px;
}

.displayline {
  display: none;
}

.linemobile {
  display: block;
  margin-top: 23px;
}

.box-mobile {
  display: block;
}

.submitbutton {
  border-radius: 10px;
  width: 190px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border: none !important;
}

.savebutton {
  border-radius: 10px;
  width: 190px;
  padding-top: 10px;
  padding-bottom: 28px;
  font-size: 12px;
  border: none !important;
}

.editprof {
  margin-left: 15px;
}

.inputbox {
  width: 96% !important;
}

.mobilecolumn {
  padding-right: 0px;
}

.telpbox {
  padding-right: 10px;
}

.conpass {
  padding-right: 10px;
}

.mobileedit {
  padding-bottom: 466px;
}

.unactive:hover {
  background-color: rgba(251, 127, 80, 0.1);
}

.mobilefont {
  font-size: 14px;
  text-align: justify !important;
  text-justify: inter-word !important;
}

@media screen and (max-width: 990px) {
  .phonenumber {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 769px) {
  .long {
    width: 100%;
  }
  .mobiles {
    display: none;
  }
}

@media screen and (max-width: 1220px) {
  .accountsettingbox {
    box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    margin-left: -25px;
    width: 62.5% !important;
  }

  .activebook {
    box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    margin-left: -25px;
    width: 62.5% !important;
  }

  .bookhistory {
    box-shadow: -0.5px -0.5px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 30px;
    padding-bottom: 20px;
    margin-left: -25px;
    width: 62.5% !important;
  }
}

@media screen and (max-width: 1205px) {
  .mobile {
    float: none;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 98%;
    height: 40px;
  }

  .tengahexplore {
    width: 160%;
    height: 40px;
  }
  .tengahexplorefilter {
    width: 60%;
    height: 40px;
  }

  .boxheight {
    height: 100%;
  }

  .inputatas {
    margin-top: -90px;
    padding-top: -30px;
    margin-bottom: -50px;
  }

  .kolommobile {
    margin-left: -20px;
  }

  .forguest {
    width: 15%;
  }

  .ratatengah {
    text-align: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 985px) {
  .mobile {
    float: none;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 120%;
    height: 40px;
  }

  .tengahexplore {
    width: 160%;
    height: 40px;
    margin-top: -15px;
    margin-bottom: 6px;
  }
  .tengahexplorefilter {
    width: 100%;
    height: 40px;
  }

  .boxheight {
    height: 100%;
  }

  .inputatas {
    margin-top: -80px;
    margin-bottom: -50px;
  }

  .kolommobile {
    margin-left: -15px;
  }

  .forguest {
    width: 21%;
  }

  .ratatengah {
    text-align: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 850px) {
  .profpage {
    padding-left: 1%;
    padding-right: 5px;
  }
  .bg-done {
    font-size: 16px;
    margin-top: -20px;
    font-weight: bold;
    cursor: pointer;
    color: #f36603;
    margin-left: 45%;
  }
  .guestHilangListAll {
    width: 200% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -100% !important;
    margin-top: -15px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 10;
  }
  .guestHilangList {
    width: 200% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -100% !important;
    margin-top: 5px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: absolute;
    z-index: 9999 !important;
  }
  .guestHilang {
    width: 90% !important;
    padding: 9.5px 0px !important;
    color: black !important;
    margin-left: -150px !important;
    height: 150px !important;
    margin-top: 10px !important;
    background-color: white !important;
    font-size: 14px !important;
    border: 1px solid #ccc !important;
    position: absolute !important;
  }

  .bookpage {
    padding-left: 1%;
    padding-right: 5px;
  }

  .primarybutton {
    border-radius: 10px;
    width: 150px;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .circlesmobile {
    margin-left: -9% !important;
  }
  .explore-btn {
    margin-top: 0px;
  }
  .h1Title {
    font-size: 24px;
  }

  .h2Title {
    font-size: 12px;
    padding-top: 5px;
  }
  .sectionHehe {
    padding: 10px;
  }

  h1 {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 400px) {
  .circlesmobile {
    margin-left: -11% !important;
  }
}

@media screen and (max-width: 769px) {
  .mobile {
    float: none;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .section2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .section2Home {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }

  .circlesmobile {
    margin-left: -4.5%;
  }

  .downloadbutton {
    border-radius: 5px;
    width: 120px;
    height: 35px;
    margin-top: 0px;
    font-size: 12px;
  }

  .bookdetailmobile {
    display: block;
  }

  .mobileprof2 {
    padding-bottom: 50px;
  }

  .nobookfound {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .box-mobile {
    display: none;
  }

  .mobilefont {
    font-size: 12px;
    text-align: justify !important;
    text-justify: inter-word !important;
  }

  .loccheck {
    margin-top: 0px;
  }

  .item {
    margin-top: -25px;
  }

  .circle {
    height: 23px;
    width: 23px;
    border-radius: 50%;
  }

  .circles {
    width: 95px;
    margin-top: -5px;
  }

  .villaname {
    font-size: 13px;
  }

  .emptybooking {
    display: none;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 120%;
  }

  .tengahexplore {
    width: 100%;
  }
  .tengahexplorefilter {
    width: 100%;
  }
  .boxheight {
    height: 100%;
  }

  .inputatas {
    margin-top: -80px;
    margin-bottom: -50px;
  }

  .kolommobile {
    margin-left: 0px;
  }

  .forguest {
    width: auto;
  }

  .ratatengah {
    text-align-last: center;
  }

  .tengahtanggal {
    width: 100%;
  }

  .myaccountbox {
    display: none;
  }

  .mobileprof {
    padding-bottom: 50px;
  }

  .displayline {
    display: block;
    margin-top: 25px;
    margin-bottom: -25px;
  }

  .linemobile {
    display: none;
  }

  .profpage {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bookpage {
    padding-left: 0px;
    padding-right: 0px;
  }

  .accountsettingbox {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-top: -20px;
    margin-left: 0px;
    width: 100% !important;
  }

  .bookdetailbox {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-top: -37px;
    margin-left: 0px;
    width: 100% !important;
  }

  .activebook {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 0px;
    margin-top: -37px;
    margin-left: 0px;
    width: 100% !important;
  }

  .bookhistory {
    box-shadow: 0px -1px 5px 0px #cfcfcf;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 0px;
    margin-top: 27px;
    margin-left: 0px;
    width: 100% !important;
    background-color: white;
  }

  .primarybutton {
    border-radius: 10px;
    width: 48%;
    padding-top: 19px;
    padding-bottom: 19px;
    font-size: 12px;
  }

  .mobilecolumn {
    width: 100% !important;
    padding-right: 10px;
  }

  .mobileedit {
    padding-bottom: 50px;
  }

  .newpass {
    width: 100% !important;
    padding-right: 10px;
  }

  .buttons {
    display: -webkit-flex; /* Safari */
    -webkit-justify-content: center; /* Safari 6.1+ */
    display: flex;
    justify-content: center;
    margin-left: -14px;
    margin-right: -14px;
  }

  .submitbutton {
    border-radius: 10px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }

  .savebutton {
    border-radius: 10px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 28px;
    font-size: 12px;
  }

  .inputbox {
    width: 100% !important;
  }

  .telpbox {
    padding-left: 0px;
  }

  .editprof {
    margin-left: 5px;
    width: 48%;
  }

  .changepass {
    width: 48%;
    margin-right: 5px;
  }
}

@media screen and (max-width: 339px) {
  .downloadbutton {
    border-radius: 5px;
    width: 115px !important;
    height: 35px;
    font-size: 11px !important;
    margin-top: 0px !important;
  }
  .guestHilang {
    width: 90%;
    padding: 9.5px 0px;
    color: black;
    margin-left: -135px !important;
    height: 150px;
    margin-top: 10px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
  }
  .guestHilangListAll {
    width: 220% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -150px !important;
    margin-top: -15px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999 !important;
  }
  .guestHilangList {
    width: 220% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: -110% !important;
    margin-top: 5px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999 !important;
  }

  .editprof {
    margin-left: 5px;
    width: 47% !important;
    font-size: 11px;
  }

  .changepass {
    width: 47% !important;
    margin-right: 5px;
    font-size: 11px;
  }
}

@media screen and (max-width: 500px) {
  .mobile {
    float: none;
    padding-right: 0px;
    padding-left: 0px;
    align-items: center;
  }

  .paginationmargin {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  .tengah {
    width: 110%;
  }

  .tengahexplore {
    width: 100%;
    margin-top: -15px;
    margin-bottom: 6px;
  }
  .tengahexplorefilter {
    width: 100%;
  }

  .boxheight {
    height: auto;
  }

  .mobile2 {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
    padding-right: 0px;
  }

  .inner {
    margin-right: 0px;
    margin-left: 0px;
  }

  .inputatas {
    margin-top: -90px;
    height: 260px;
    margin-bottom: -50px;
  }

  .forguest {
    margin-top: -10px;
    margin-bottom: 5px;
    width: auto;
  }

  .kolommobile {
    margin-left: 0px;
  }

  .ratatengah {
    text-align-last: center;
  }

  .tengahtanggal {
    width: 100%;
  }

  .kolomkota {
    margin-bottom: 11px;
    margin-top: -10px;
  }

  .kolomharga {
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .pagee {
    margin-top: -60px;
    margin-bottom: -100px;
  }
}

.btn-searchbox {
  height: 40px;
  color: #fff;
  border-color: #f16726;
  background-color: #f16726;
}

/* Popup container - can be anything you want */
.popup {
  position: relative;
  margin-left: 285px;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 10px grey;
}
.popup .popuptext-modal {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 10px grey;
}

/* Popup arrow */
.popup .popuptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
}

.popup .show2 {
  visibility: visible;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bg-guest-min {
  background: white;
  color: #f36603;
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 25px 1px 1px 25px;
  border: 1px solid #cfcfcf;
}
.bg-guest-min-hilang {
  background: white;
  color: #fbd1a7;
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 25px 1px 1px 25px;
  border: 1px solid #cfcfcf;
}
.bg-guest-min-hilang:hover {
  color: #fbd1a7;
}
.bg-done {
  font-size: 16px;
  margin-top: -20px;
  font-weight: bold;
  cursor: pointer;
  color: #f36603;
}
.bg-guest-min:hover {
  color: #f36603;
}
.bg-guest-plus:hover {
  color: #f36603;
}
.bg-guest-plus {
  background: white;
  color: #f36603;
  height: 35px;
  font-weight: bold;
  width: 50%;
  font-size: 20px;
  border-radius: 1px 25px 25px 1px;
  border: 1px solid #cfcfcf;
}
.bg-guest-plus-hilang {
  background: white;
  color: #fbd1a7;
  height: 35px;
  font-weight: bold;
  width: 50%;
  font-size: 20px;
  border-radius: 1px 25px 25px 1px;
  border: 1px solid #cfcfcf;
}
.bg-guest-plus-hilang:hover {
  color: #fbd1a7;
}
.txtGuest {
  height: 35px;
}
.border-guest {
  border: 1px solid #cfcfcf;
  border-radius: 25px;
}
.bg-primary {
  background: #f36603 !important;
  color: white;
}

.bg-primary:hover {
  background: #f36603;
  color: white;
}
.bg-green {
  background-color: #f0fff0;
}

/*--------------- React slick ------------*/

.slide-item {
  width: 100%;
}
.slide-item img {
  width: 100%;
  height: 100%;
}
.slide-item-view img {
  width: 100%;
  height: 100%;
}

.slick-next {
  right: 10px;
}
.slick-next:before,
.slick-prev:before {
  font-size: 25px;
  color: #ffff;
}
.slick-prev {
  z-index: 999;
  left: 10px;
}
.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: white;
  font-size: 10px;
}
.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}
.boxSlide .slick-next:before,
.boxSlide .slick-prev:before {
  font-size: 25px;
  color: black;
}
.boxSlide .slick-disabled:before {
  color: transparent !important;
  cursor: default;
}
.boxSlide .slick-prev {
  left: -30px;
}
.boxSlide .slick-next {
  right: -30px;
}
.gambarSimilar {
  height: 180px;
  cursor: pointer;
}
.boxShadow {
  border: solid 1px #ccc !important;
  background-color: white;
  margin: 30px;

  box-shadow: 7px 7px 10px 15px;
  margin-left: -5px;
}
.boxSimilar .gambarAja {
  width: 100%;
}

.desktops {
  margin-left: -20px;
  width: 101.5% !important;
}
.desktopsTeks {
  margin-top: -27px;
}
.boxSimilar .box {
  padding: 1px;
  width: 105% !important;
}
.boxSlide .gambarAja .slick-next:before,
.boxSlide .gambarAja .slick-prev:before {
  font-size: 25px;
  color: #ffff;
}
.boxSlide .slick-prev {
  z-index: 1;
}
.boxSlide .gambarAja .slick-prev {
  z-index: 999;
  left: 10px;
}
.boxSlide .gambarAja .slick-dots {
  bottom: 10px;
}

.boxSlide .gambarAja .slick-next {
  right: 10px;
}

/*--------- end of react slick ------------*/

.icon-facility {
  padding: 3px 10px !important;
}

/*--------------- Modal ------------*/
.modal {
  overflow: auto;
  background-color: rgba(10, 10, 10, 0.86);
  z-index: 9999;
}

.modal-content {
  overflow: visible;
}

.modal-background {
  opacity: 0;
}
.modal-background-image {
  overflow: visible;
  background: transparent;
}
.box-image {
  box-shadow: none !important;
  background: transparent;
  margin-top: -40px !important;
}
.box-image2 {
  box-shadow: none !important;
  background: white;
  margin-top: 0px !important;
}
/*--------- end of react modal ------------*/

.m-top-50 {
  margin-top: 50px;
}

table th {
  padding-bottom: 5px;
}

.loader {
  /*border: 10px solid #f3f3f3;*/
  border-radius: 100%;
  border-top: 3px solid #fff;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1023px) {
  /*.navbar-menu {
    background-color: #fff;
  }*/
  .navbar-dropdown .navbar-item {
    color: #fff !important;
  }
}

.modal-trans {
  overflow: auto;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
}

.images-galery {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  margin-top: 12px;
}

/*-------- paging -------------*/
.bulet li a {
  margin-left: 10px;
  border-radius: 60%;
  border: 1px solid #f36603;
  color: #f36603;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}
li.btn-numbered-page {
  /*margin: 0px;*/
  float: left;
}
.bulet li.active a {
  margin-left: 10px;
  border-radius: 60%;
  border: 1px solid #f36603;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f36603;
}
.bulet li.active a:hover {
  background-color: #f7c25e;
  border: 1px solid #f7c25e;
  color: #fff;
}
.bulet li a:hover {
  background-color: #f7c25e;
  border: 1px solid #f7c25e;
  color: #fff;
}
.bulet li.disabled a {
  display: none;
}
.btn-first-page,
.btn-last-page {
  display: none !important;
}
li.btn-prev-page a,
li.btn-next-page a {
  font-size: 19px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 9px;
  padding-right: 9px;
}
.pagination-sm {
  width: auto;
}
a {
  text-decoration: none !important;
}

/* message box*/
.box {
  box-shadow: none !important;
}
.modal-load {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}
.modal-head {
  width: 100%;
  height: 100%;
  background-color: #000 !important;
  overflow: hidden;
  z-index: 99999;
}
/*select guest*/
.hidden-select {
  appearance: none !important;
}
.width-select {
  width: 100% !important;
}
/*reset password*/
.reset-center {
  width: 80% !important;
}

/* about */
.aboutpage {
  padding-bottom: 50px;
}
/* about */
/* calender */
.flatpickr-calendar {
  width: auto !important;
  margin-top: 20px !important;
  content: '' !important;
  font-size: 12px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 20px !important;
  border-radius: 0px !important;
}
.flatpickr-month,
.flatpickr-weekdays,
.flatpickr-weekdaycontainer,
.flatpickr-weekday,
.flatpickr-calendar {
  background-color: white !important;
  color: #636262 !important;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background-color: #ffb865 !important;
  box-shadow: none !important;
  color: white !important;
  font-size: 12px !important;
}
.flatpickr-day {
  border-radius: 5px;
}
.flatpickr-day:hover {
  background-color: #f3693a !important;
  border-radius: 5px;
}
.flatpickr-day.disabled:hover {
  background-color: #ccc !important;
  border-radius: 5px;
  color: #fff !important;
}
span.flatpickr-day,
span.flatpickr-day.prevMonthDay,
span.flatpickr-day.nextMonthDay {
  border: none !important;
  max-width: 50px !important;
}
span.startRange:focus {
  background-color: #f3693a !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: #f3693a !important;
  border-radius: 5px !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: none !important;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  color: #ccc !important;
}
.flatpickr-day.inRange {
  border-radius: 0px !important;
}
span.flatpickr-next-month svg,
span.flatpickr-prev-month svg {
  /*display: none;*/
  opacity: 0;
}
span.flatpickr-next-month {
  background-image: url('/icon/kanan.png') !important;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-top: 20px;
}
span.flatpickr-prev-month {
  background-image: url('/icon/kiri.png') !important;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  margin-left: 15px;
  margin-top: 20px;
}
.flatpickr-weekdaycontainer {
  padding-top: 10px !important;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  border: none !important;
}
.flatpickr-months {
  margin-top: 10px;
}
.flatpickr-prev-month:focus,
.flatpickr-next-month:focus {
  background-color: transparent !important;
}
.flatpickr-prev-month svg:focus,
.flatpickr-next-month svg:focus {
  background-color: transparent !important;
}
.dayContainer {
  min-width: 25px !important;
}

@media screen and (max-width: 640px) {
  .flatpickr-calendar {
    width: auto !important;
    margin-top: 5px !important;
    content: '' !important;
    overflow: scroll !important;
    width: 280px !important;
    font-size: 10px !important;
    border-radius: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
  }
  .flatpickr-weekdays,
  .flatpickr-days,
  .dayContainer {
    width: 260px !important;
  }
  .flatpickr-day {
    border-radius: 0px !important;
    width: 5px !important;
    padding: 0px 0px;
  }
  .dayContainer {
    min-width: 20px !important;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    background-color: #ffb865 !important;
    box-shadow: none !important;
    color: white !important;
    font-size: 10px;
  }
}

/* airbnb*/
div.DateRangePicker {
  display: none;
}
.DateInput_input {
  font-size: 14px !important;
  font-weight: 440 !important;
  font-family: 'Libre Franklin' !important;
  padding-top: 9px !important;
  padding-bottom: 5px !important;
  color: black !important;
  text-align: center !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #ffb865 !important;
}
.DateInput {
  width: 40% !important;
  padding: 0px 0px !important;
  border-radius: 15px !important;
}
.DateRangePickerInput svg {
  width: 15px !important;
  /*float: left;*/
  margin-left: 5px;
}
.DateRangePickerInput {
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
  overflow: hidden;
  width: 100% !important;
}
.DateRangePicker {
  border-radius: 15px !important;
  padding-left: 2px !important;
  width: 100% !important;
}
.CalendarDay__selected_span {
  background-color: #ffb865 !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #f3693a !important;
  border-radius: 5px !important;
}
button.DayPickerKeyboardShortcuts_buttonReset {
  display: none !important;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  color: white !important;
  background: #ffb865 !important;
  border: none !important;
}
.CalendarDay__default {
  border: none !important;
  padding: 10px 10px !important;
  margin-bottom: 2px !important;
  border-bottom: 2px solid white;
}
.DateRangePicker_picker {
  margin-right: 10px !important;
  left: -30px !important;
}
.hilang {
  display: none !important;
}
.DayPickerNavigation_button__default {
  border: none !important;
}
.CalendarMonth {
  margin-bottom: 50px;
}
input#startDate1 {
  margin-left: -10px !important;
  width: 60px;
}
input#endDate1 {
  width: 60px;
}
input#startDate1_B {
  /*margin-left: 5px !important;*/
  margin-right: 15px !important;
  /*width: 60px !important;*/
  /*padding-left: 15px !important;*/
}
#endDate1_B {
  width: 70px !important;
  margin-left: 20px !important;
}
.DateInput_input {
  padding: 0px !important;
  padding-top: 9px !important;
  padding-bottom: 6px !important;
}
.hilang {
  display: none !important;
}
.labelDates {
  width: 100%;
  text-align: center;
  padding: 9.5px 0px;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.labelGuest {
  width: 100%;
  text-align: center;
  padding: 9.5px 0px;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.labelGuestList {
  width: 100%;
  text-align: center;
  padding: 9.5px 0px;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.guestHilang {
  width: 60%;
  padding: 9.5px 0px;
  color: black;
  margin-left: -50px;
  height: 150px;
  margin-top: 24px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 9999 !important;
}
.guestHilangList {
  width: 200%;
  padding: 9.5px 0px;
  color: black;
  margin-left: -60px;
  margin-top: 24px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 9999 !important;
}
.guestHilangListAll {
  width: 200%;
  padding: 9.5px 0px;
  color: black;
  margin-left: -70px;
  margin-top: 0px;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 9999 !important;
}
.guestHilangDetail {
  width: 100%;
  padding: 9.5px 0px;
  color: black;
  background-color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  z-index: 999;
}
@media screen and (max-width: 769px) {
  /* airbnb*/
  .DateRangePickerInput {
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
    overflow: hidden;
  }
  .DateRangePicker {
    border-radius: 15px !important;
    width: 90% !important;
  }
  .DateRangePickerInput svg {
    width: 10px !important;
    float: left;
  }
  .DateInput {
    width: 60px;
    padding: 0px 0px !important;
    border-radius: 15px !important;
  }
  .DateRangePicker_picker {
    margin-right: 10px !important;
    left: 0px !important;
    margin-top: -10px !important;
  }
  input#startDate1 {
    margin-left: -10px !important;
    width: 60px;
  }
  input#endDate1 {
    width: 60px;
  }
  input#startDate1_B {
    /*width: 60px;*/
    padding-left: 0px !important;
  }
  .DateInput_input {
    padding: 0px !important;
    padding-top: 9px !important;
    padding-bottom: 6px !important;
  }
  .labelDates {
    width: 90%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}

/**/
/* calender */

/* kode phone */
/*.react-phone-number-input{
    width: 45px !important;
    border: solid 1px #ccc;
    padding: 0px !important;
    border-right: none !important;
    height: 50px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  .react-phone-number-input,.rrui__input-element{
    height: 37px !important;
    padding-bottom: 5px !important;
    padding-left: 2px !important;
  }
  .rrui__input-element{
    border-bottom: none;
    border: none !important;
  }
  .react-phone-number-input__icon,img.react-phone-number-input__icon-image{
    width: 30px !important;
    height: 20px !important;
  }
  .rrui__select__selected-label{
    margin-top: -5px !important;
    width: 30px !important;
  }
  .rrui__select__arrow{
    display: none !important;
  }
  .labelCodePhone{

  }
  .labelCode{
    margin: 0px !important;
    padding: 0px !important;
    padding-top: 7px !important;
    width: 50px !important;
    padding-right: 5px !important;
    border: 1px solid #ccc;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 5px !important;
  }
  .rrui__select,.react-phone-number-input__row,.rrui__input-element,.rrui__select__selected-content{
    width: 30px !important;

  }
  .rrui__select__options:not(.rrui__select__options--menu){
    width: 200px !important;
  }

  .rrui__select__autocomplete{
    z-index: 99999 !important;
    position: sticky;
    margin-bottom: 5px !important;
    width: 202px !important;
    border: 1px solid #ccc !important;
    margin-top: 40px;
    margin-left: -20px;
    border-radius: 4px !important;
    padding-left: 20px !important;
  }
  .react-phone-number-input__icon svg{
    width: 30px !important;
    height: 20px !important;
  }*/

/* version 2 */
.intl-tel-input {
  width: 40px !important;
  background-color: white !important;
  height: 37px !important;
  border: solid 1px #ccc;
  padding-right: 5px !important;
  border-right: none !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.intl-tel-input {
  width: 40px !important;
}
input[type='tel'] {
  width: 40px !important;
  padding: 0px !important;
  display: none !important;
}
.country-list {
  width: 250px !important;
  margin-top: 10px !important;
  max-height: 250px !important;
}
.highlight:not(:last-child) {
  margin-bottom: 0px !important;
}
.iti-arrow {
  display: none !important;
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent !important;
  width: 81px !important;
  box-shadow: none !important;
  border: none !important;
}
.intl-tel-input.allow-dropdown .selected-flag {
  width: 81px !important;
  box-shadow: none !important;
  border: none !important;
}
.labelCodePhone {
  width: 40px !important;
  text-align: left !important;
}
.labelCode {
  border: solid 1px #ccc;
  height: 37px !important;
  padding-top: 6px !important;
  width: auto !important;
  padding-right: 5px !important;
  margin-right: 5px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-left: none !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .iti-container {
    position: fixed;
    z-index: 999991 !important;
    background-color: transparent !important;
    border: none !important;
    width: 83% !important;
    margin-top: 50px !important;
    text-align: center !important;
    height: 90% !important;
  }

  .country-list {
    width: 100% !important;
    position: absolute !important;
    max-height: 90% !important;
  }
  .column-conf {
    float: left;
    width: 50%;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .iti-container {
    position: fixed;
    z-index: 999991 !important;
    background-color: transparent !important;
    border: none !important;
    width: 90% !important;
    text-align: center !important;
    height: 90% !important;
  }
  .country-list {
    width: 100% !important;
    position: absolute !important;
    max-height: 90% !important;
  }
  .column-conf {
    float: left;
    width: 50%;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

/* Medium devicese (landscape tablets, 768px and up) */
@media only screen and (width: 768px) {
  .labelGuest {
    width: 100%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-left: 25px;
  }
  .gambarSimilar {
    height: 220px;
    cursor: pointer;
  }
  .labelGuestList {
    width: 100%;
    text-align: center;
    padding: 9.5px 0px;
    border-radius: 10px;
    color: black;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    margin-left: -1px !important;
    cursor: pointer;
  }
  .bg-done {
    font-size: 16px;
    margin-top: -20px;
    font-weight: bold;
    cursor: pointer;
    color: #f36603;
    margin-left: 55% !important;
  }
  .guestHilang {
    width: 50% !important;
    padding: 9.5px 0px !important;
    color: black !important;
    margin-left: 7px !important;
    height: 150px !important;
    margin-top: 10px !important;
    background-color: white !important;
    font-size: 14px !important;
    border: 1px solid #ccc !important;
    cursor: pointer !important;
    position: absolute !important;
  }
  .guestHilangListAll {
    width: 100% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: 0px !important;
    margin-top: -15px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999 !important;
  }
  .guestHilangList {
    width: 100% !important;
    padding: 9.5px 0px;
    color: black;
    margin-left: 0px !important;
    margin-top: 5px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: absolute;
    z-index: 9999 !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1024px) {
  .country-list {
    width: 250px !important;
    position: absolute !important;
    max-height: 300px !important;
  }
  .column-conf {
    float: left;
    width: 25%;
  }
}
/* kode phone */

.tabs .is-active {
  border-bottom: 1px solid #f16726;
}
.tabs li.is-active a {
  border-bottom-color: #f16726;
  color: #f16726;
}

.bg-pulsa:hover {
  background-color: rgba(241, 103, 38, 0.4);
}
.input-pulsa {
  border: unset;
  padding: 10px;
  flex: 1;
  font-size: 16px;
}
.input-pulsa:focus {
  border: unset;
}
.input-pulsa:first-child {
  width: 30px;
}
.input-pulsa-container {
  display: flex;
  align-items: center;
  padding: 4px 20px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid rgb(116, 116, 116);
}
