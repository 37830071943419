.input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #aaa !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #aaa !important;
  opacity: 1;
}

.input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #aaa !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aaa !important;
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aaa !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #aaa !important;
}
